import { Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CostOverview, User } from '../../../../api/types';
import { DataTable } from '../../../../components/Table/DataTable';
import dateTransformer from '../../../../helpers/dateTransformer';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import useWorkshopAxiosContextQuery from '../../../../hooks/queries/workshop/useWorkshopAxiosContextQuery';

export interface IUnbilledChargesProps {
  unbilledCharges: Array<CostOverview>;
  user?: User;
}

export const UnbilledChargesTable = (props: IUnbilledChargesProps) => {
  const { t } = useTranslation();
  const { data: workshop } = useWorkshopAxiosContextQuery();
  const currency = workshop?.currency;
  const { unbilledCharges = [], user } = props;
  const columnsCost = React.useMemo(
    () => [
      {
        Header: t('forms:date.label'),
        accessor: (row: { created_at: string }) =>
          dateTransformer(row.created_at, user?.timezone),
      },
      {
        Header: t('forms:description.label'),
        accessor: 'description',
      },
      {
        Header: t('forms:quantity.label'),
        accessor: 'quantity',
      },
      {
        Header: t('forms:amount.label'),
        accessor: (row: { amount: number }) =>
          formatMultiCurrencyIntl(row.amount, currency),
      },
    ],
    [],
  );
  return (
    <Stack direction={['column', 'row']} w={'100%'} py={4}>
      <Stack direction="column" w={'100%'}>
        <Text fontSize="sm">{t('pages:subscription.cost.table.description')}</Text>
        {unbilledCharges.length === 0 ? (
          <Text fontSize="sm" data-test-id="cost-table-no-content">
            {t('pages:subscription.cost.no_content')}
          </Text>
        ) : (
          <DataTable
            data-test-id="cost-table"
            hasFilters={false}
            columns={columnsCost}
            data={unbilledCharges}
            isMoreThenOnePage={false}
          />
        )}
      </Stack>
    </Stack>
  );
};
