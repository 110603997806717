import { Box, Button, Divider, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { addYears, format, getMonth, isBefore } from 'date-fns';
import { useChargeBeePortal } from 'hooks/queries/useChargeBee';
import useCostOverviewQuery from 'hooks/queries/useCostOverviewQuery';
import PageMetatags from 'layout/PageMetatags';
import { get } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  MdArrowDownward,
  MdCreditCard,
  MdCreditScore,
  MdWarningAmber,
} from 'react-icons/md';

import { BILLING_CYCLE, LooseObject, SubscriptionAddon } from '../../../../api/types';
import { MONTH_NAMES_DEFAULT } from '../../../../components/DatePicker';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import getDisableCostControlData from '../../../../components/modals/GetDisableCostControlModalData';
import { DataTable } from '../../../../components/Table/DataTable';
import { useUserContext } from '../../../../context/UserContextProvider';
import { FONT_WEIGHTS } from '../../../../global/Fonts';
import dateTransformer from '../../../../helpers/dateTransformer';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import { displayRow, useIsWorkshopBlocked } from '../../../../helpers/general';
import useAddonsQuery from '../../../../hooks/queries/useAddonsQuery';
import useInvoicesQuery from '../../../../hooks/queries/useInvoicesQuery';
import { Subscription } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { ContentCard } from '../../../../layout/ContentCard';
import { UnbilledChargesTable } from './UnbilledChargesTable';

export const CostOverviewPage: React.FC<{}> = () => {
  const userContext = useUserContext();
  const { triggerPortal } = useChargeBeePortal();
  const currency = userContext.workshop?.currency;
  const addonsQuery = useAddonsQuery();
  const addons = addonsQuery.data || [];
  const { t } = useTranslation();
  const costOverviewQuery = useCostOverviewQuery();
  const costOverviewData = costOverviewQuery.data || {};
  const today = new Date();
  const endOfYear = new Date(new Date().getFullYear(), 11, 31);
  const isWorkshopBlocked = useIsWorkshopBlocked();

  const invoicesQuery = useInvoicesQuery();

  function calculateTotal(
    subscription: Subscription | LooseObject,
    addons: { [key: string]: SubscriptionAddon },
    unbilledCharges: number,
    promotionalCredits: number,
  ) {
    const subscriptionMonth = getMonth(
      new Date(userContext.workshop?.subscription?.created_at),
    );

    const monthBeforeSubscriptionRenewal =
      subscriptionMonth === 0 ? 11 : subscriptionMonth - 1;

    const displayMonth = monthBeforeSubscriptionRenewal === getMonth(new Date());

    const showCost =
      userContext.workshop?.subscription?.billing_cycle !== 'year' ||
      (userContext.workshop?.subscription?.billing_cycle === 'year' && displayMonth);

    const planPrice = showCost
      ? subscription?.plan?.price[subscription?.billing_cycle]?.amount
      : 0;

    const activeAddons: LooseObject = get(subscription, 'active_addons', {});
    let addonPrices = 0;

    Object.values(addons).forEach((addon: SubscriptionAddon) => {
      if (activeAddons[addon?.id] && !activeAddons[addon?.id]?.trial) {
        const currentAddonPrice = get(addon?.price, subscription?.billing_cycle, {});
        addonPrices = addonPrices + get(currentAddonPrice, 'amount', 0);
      }
    });

    const totalUnbilledAmount = planPrice + addonPrices + unbilledCharges;

    const amountWithPromotionalCredits = totalUnbilledAmount - promotionalCredits;

    return amountWithPromotionalCredits < 0 ? 0 : amountWithPromotionalCredits;
  }

  const columnsInvoices = React.useMemo(
    () => [
      {
        Header: t('forms:id.label'),
        accessor: 'id',
      },
      {
        Header: t('forms:amount.label'),
        accessor: (row: { total: number }) =>
          formatMultiCurrencyIntl(row.total, currency),
      },
      {
        Header: t('forms:date.label'),
        accessor: (row: { date: string }) =>
          dateTransformer(row.date, userContext.user?.timezone),
      },
      {
        Header: t('pages:subscription.invoices.status.label'),
        accessor: 'status',
        // @ts-ignore
        Cell: (cellProps) => {
          const { row } = cellProps;
          return row.original.status === 'paid'
            ? t('pages:subscription.invoices.status.paid')
            : row.original.status === 'payment_due'
              ? t('pages:subscription.invoices.status.payment_due')
              : t('pages:subscription.invoices.status.not_paid');
        },
      },
      {
        id: 'button',
        accessor: 'url',

        Cell: ({ value }: { value: string }) => {
          return (
            <Flex gap={4}>
              <Button
                ml={'auto'}
                leftIcon={<MdArrowDownward />}
                variant="primary"
                as={'a'}
                href={value}
              >
                {t('common:download')}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [],
  );

  const total = calculateTotal(
    userContext.workshop?.subscription || {},
    addons,
    parseInt(get(costOverviewData, 'total', 0)),
    parseInt(userContext.workshop?.promotional_credits),
  );

  const costDisableModalData = getDisableCostControlData();
  if (userContext.workshop?.disable_cost_control) {
    NiceModal.show(costDisableModalData?.modalComponent, costDisableModalData);
    return null;
  }

  const promotionalCreditsAsNumber = parseInt(userContext.workshop?.promotional_credits);
  if (addonsQuery.isLoading || costOverviewQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const prepaidLicenceAmountMonthly =
    userContext.workshop?.distributor?.prepaid_plan_amount_monthly;
  const distributorName = userContext.workshop?.distributor?.name;

  const isPartnerPromotionValid = isBefore(
    new Date(),
    addYears(new Date(userContext.workshop?.subscription?.created_at ?? ''), 1),
  );

  const isPartnerWorkshop =
    !!userContext.workshop?.distributor?.prepaid_plan && isPartnerPromotionValid;

  return (
    <>
      <PageMetatags
        title={`${t('pages:subscription.cost.meta.title')} -
            ${MONTH_NAMES_DEFAULT[today.getMonth()]} ${today.getFullYear()}`}
      >
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </PageMetatags>

      {(isWorkshopBlocked || userContext.workshop?.has_failed_payments) && (
        <ContentCard
          icon={<Icon as={MdWarningAmber} boxSize="12" m={1} />}
          header={
            isWorkshopBlocked
              ? t('pages:subscription.cost.blocked_workshop')
              : t('workshop:failed_payment.label')
          }
          backgroundColor={'red.200'}
          noDivider
        >
          <Flex justifyContent={'center'}>
            <Button onClick={triggerPortal} variant="primary">
              {t('workshop:card.payment.action')}
            </Button>
          </Flex>
        </ContentCard>
      )}

      <ContentCard
        data-test-id="cost-overview-card"
        isLoading={!costOverviewQuery.isFetched || addonsQuery.isFetching}
        icon={<Icon as={MdCreditCard} boxSize="12" m={1} />}
        header={`${t('pages:subscription.cost.header')} -
            ${MONTH_NAMES_DEFAULT[today.getMonth()]} ${today.getFullYear()}`}
        contentDescription={t('pages:subscription.cost.description')}
      >
        <Box w={'50%'} py={4}>
          <Stack spacing={1} py={2}>
            {userContext.workshop?.subscription?.billing_cycle ===
              BILLING_CYCLE.MONTHLY &&
              displayRow({
                label: (
                  <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'md'} as="div">{`${t(
                    'forms:plan.label',
                  )} "${t(
                    `plans:${get(userContext.workshop?.subscription, 'plan.slug')}.label`,
                  )}" ${t('common:per')} ${t(
                    `pages:subscription.payment_interval.${get(
                      userContext.workshop?.subscription,
                      'billing_cycle',
                    )}`,
                  )}`}</Text>
                ),

                content: (
                  <Text
                    as="div"
                    fontWeight={FONT_WEIGHTS.bold}
                    fontSize={'md'}
                    color={'accent'}
                    textDecoration={
                      isPartnerWorkshop &&
                      userContext.workshop?.subscription?.plan?.id === 3
                        ? 'line-through'
                        : 'inherit'
                    }
                  >
                    {formatMultiCurrencyIntl(
                      userContext.workshop?.subscription?.plan?.price[
                        userContext.workshop?.subscription?.billing_cycle
                      ]?.amount,
                      currency,
                    )}
                  </Text>
                ),
                isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
                isForOverview: true,
              })}

            {isPartnerWorkshop &&
              userContext.workshop?.subscription?.plan?.id !==
                userContext.workshop?.distributor?.prepaid_plan_id &&
              displayRow({
                label: (
                  <Text
                    fontWeight={FONT_WEIGHTS.normal}
                    fontSize={'sm'}
                    mt={-2}
                    mb={1}
                    as="div"
                  >
                    {t(
                      'pages:subscription.partner.licenseExplanation.billingInfo.planUpgraded',
                      {
                        expirationDate: format(
                          addYears(
                            new Date(
                              userContext.workshop?.subscription?.created_at ?? '',
                            ),
                            1,
                          ),
                          'MM.dd.yyyy',
                        ),
                        partnerName: distributorName,
                        moneyValue: formatMultiCurrencyIntl(
                          prepaidLicenceAmountMonthly ?? 0,
                          currency,
                        ),
                      },
                    )}
                  </Text>
                ),
                isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
                isForOverview: true,
              })}

            {isPartnerWorkshop &&
              userContext.workshop?.subscription?.plan?.id ===
                userContext.workshop?.distributor?.prepaid_plan_id &&
              displayRow({
                label: (
                  <Text
                    fontWeight={FONT_WEIGHTS.normal}
                    fontSize={'sm'}
                    mt={-2}
                    mb={1}
                    as="div"
                  >
                    {t(
                      'pages:subscription.partner.licenseExplanation.billingInfo.planIncluded',
                      {
                        expirationDate: format(
                          addYears(
                            new Date(
                              userContext.workshop?.subscription?.created_at ?? '',
                            ),
                            1,
                          ),
                          'MM.dd.yyyy',
                        ),
                        partnerName: distributorName,
                        moneyValue: formatMultiCurrencyIntl(
                          prepaidLicenceAmountMonthly ?? 0,
                          currency,
                        ),
                      },
                    )}
                  </Text>
                ),
                isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
                isForOverview: true,
              })}

            {userContext.workshop?.subscription?.billing_cycle ===
              BILLING_CYCLE.MONTHLY && //@ts-ignore
              Object.values(addons).map((addon: SubscriptionAddon, index) => {
                if (!userContext.workshop?.subscription?.active_addons[addon?.id]) {
                  return null;
                }
                return (
                  <React.Fragment key={`addon-${index}`}>
                    {displayRow({
                      label: (
                        <Text
                          fontWeight={FONT_WEIGHTS.bold}
                          textDecoration={
                            get(
                              userContext.workshop?.subscription?.active_addons[
                                addon?.id
                              ],
                              'trial',
                              false,
                            )
                              ? 'line-through'
                              : ''
                          }
                          fontSize={'md'}
                        >{`${t(`addons:label`)} "${t(`addons:${addon.id}.name`)}" ${t(
                          'common:per',
                        )} ${t('pages:subscription.payment_interval.month')}`}</Text>
                      ),

                      content: (
                        <Text
                          fontWeight={FONT_WEIGHTS.bold}
                          fontSize={'md'}
                          color={'accent'}
                          textDecoration={
                            get(
                              userContext.workshop?.subscription?.active_addons[
                                addon?.id
                              ],
                              'trial',
                              false,
                            )
                              ? 'line-through'
                              : ''
                          }
                        >
                          {formatMultiCurrencyIntl(
                            addon?.price[
                              userContext.workshop?.subscription?.billing_cycle
                            ]?.amount,
                            currency,
                          )}
                        </Text>
                      ),
                      isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
                      isForOverview: true,
                    })}
                  </React.Fragment>
                );
              })}
            {displayRow({
              label: (
                <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'md'}>
                  {t('forms:additional_charges.label')}(
                  {t('forms:additional_charges.subtext')})
                </Text>
              ),
              content: (
                <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'md'} color={'accent'}>
                  {formatMultiCurrencyIntl(
                    parseInt(get(costOverviewData, 'total', 0)),
                    currency,
                  )}
                </Text>
              ),
              isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
              isForOverview: true,
            })}
          </Stack>
          <Stack py={2} spacing={2}>
            <Divider />

            {displayRow({
              label: (
                <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'2xl'}>
                  {t('forms:total_recurring_charge.label')}
                </Text>
              ),
              content: (
                <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'2xl'} color={'accent'}>
                  {formatMultiCurrencyIntl(total, currency)}
                </Text>
              ),
              isLoading: !costOverviewQuery.isFetched || addonsQuery.isFetching,
              isForOverview: true,
            })}
          </Stack>
        </Box>
        <Divider />

        <UnbilledChargesTable
          unbilledCharges={get(costOverviewData, 'unbilled_charges', [])}
          user={userContext.user}
        />
      </ContentCard>
      {userContext.workshop?.is_legacy &&
        !isNaN(promotionalCreditsAsNumber) &&
        promotionalCreditsAsNumber > 0 &&
        today < endOfYear && (
          <Box w="full" borderRadius="lg" p={4} bgColor="green.100">
            {t('pages:subscription.promotional_credit.text', {
              credit: formatMultiCurrencyIntl(
                userContext.workshop?.wp_wallet_balance,
                currency,
              ),
              promotional_credit: formatMultiCurrencyIntl(
                parseInt(userContext.workshop?.promotional_credits),
                currency,
              ),
            })}
          </Box>
        )}
      <ContentCard
        data-test-id="invoices-overview-card"
        isLoading={!invoicesQuery.isFetched}
        icon={<Icon as={MdCreditScore} boxSize="12" m={1} />}
        header={t('pages:subscription.invoices.header')}
        contentDescription={t('pages:subscription.invoices.description')}
      >
        <Stack direction={['column', 'row']} w={'100%'}>
          <Stack direction="column" w={'100%'}>
            {/*@ts-ignore*/}
            {costOverviewData.length === 0 ? (
              <Text>{t('pages:subscription.invoices.no_content')}</Text>
            ) : (
              <DataTable
                data-test-id="invoices-table"
                hasFilters={false}
                columns={columnsInvoices}
                data={invoicesQuery.data || []}
                isMoreThenOnePage={false}
              />
            )}
          </Stack>
        </Stack>
        <Text mt={3}>{t('pages:subscription.invoices.note')}</Text>
      </ContentCard>
    </>
  );
};

export default CostOverviewPage;
