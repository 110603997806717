import './VerticalTimelineElement.css';

import { Box, Flex, Spacer, useToken } from '@chakra-ui/react';
import { highlightServiceEntry } from 'animations/specials';
import * as React from 'react';
import { useEffect, useId } from 'react';
import { useLocation } from 'react-router-dom';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

import { getLightStatusColor } from '../../helpers/getColourSchemeBasedOnStatus';

type BaseServiceTypeTimelineElementProps = {
  children?: React.ReactNode;
  status?: any;
  badge?: any;
  icon: any;
  iconBgColor: string;
  iconColor?: string;
  description?: string;
  title: string;
  date: string;
  iconClassName?: string;
  dataTestId: string;
  id?: string;
  statusReason: any;
};

const BaseServiceTypeTimelineElement: React.FC<BaseServiceTypeTimelineElementProps> = ({
  status,
  statusReason,
  badge,
  icon,
  id = useId(),
  iconColor,
  iconBgColor,
  iconClassName,
  title,
  description,
  date,
  dataTestId,
  children,
}) => {
  const locationWithState: any = useLocation();
  const createdServiceRecordId = locationWithState.state?.id;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (createdServiceRecordId) {
        highlightServiceEntry(createdServiceRecordId).catch((error) =>
          console.log(error),
        );
        document.getElementById(createdServiceRecordId)?.removeAttribute('id');
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <VerticalTimelineElement
      id={id}
      className={`vertical-timeline-element--work ${dataTestId}`}
      iconStyle={{
        background: useToken('colors', `${iconBgColor}.500`) ?? 'gray',
        color: iconColor ?? 'white',
      }}
      iconClassName={iconClassName}
      icon={icon}
      contentStyle={{
        backgroundColor:
          iconBgColor === 'orange'
            ? useToken('colors', getLightStatusColor('clarification'))
            : useToken('colors', 'white'),
      }}
    >
      <div className="vertical-timeline-element__date">{date}</div>
      <Box>
        <div className="vertical-timeline-element__date">{date}</div>
        <Flex>
          <Box w={'full'}>
            <h3 className="vertical-timeline-element-title">
              <strong>{title}</strong>
            </h3>
          </Box>
          <Spacer />
          <Box>{status}</Box>
        </Flex>

        <Box right={2} top={2}>
          {badge}
        </Box>
        <Box>{statusReason}</Box>
        {description && (
          <h4 className="vertical-timeline-element-subtitle">{description}</h4>
        )}
      </Box>

      <div>{children}</div>
    </VerticalTimelineElement>
  );
};

export default BaseServiceTypeTimelineElement;
