import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useWorkshopQuery from '../../queries/workshop/useWorkshopQuery';

async function update(axios: AxiosInstance, billing_email: string): Promise<object> {
  const { data } = await axios.put(`${config.apiBaseUrl}/settings`, { billing_email });
  return data;
}

export default function useUpdateBillingEmailAddressMutation() {
  const queryClient = useQueryClient();
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();
  return useMutation((billing_email: string) => update(axios, billing_email), {
    onSuccess: () => {
      queryClient.invalidateQueries(useWorkshopQuery.getKey());
    },
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error,
        t,
      });
    },
  });
}
