import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function update(
  axios: AxiosInstance,
  serviceHistoryRequestData: any,
): Promise<object> {
  return await axios.post(
    `${config.apiBaseUrl}/service-history-request`,
    serviceHistoryRequestData,
    { timeout: 3000 },
  );
}

export default function useAccountUpdateFormMutation() {
  const axios = useAxios();
  const t = useTranslation();
  const toast = useToast();
  return useMutation(
    (serviceHistoryRequestData: any) => update(axios, serviceHistoryRequestData),
    {
      onError: (err: any) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      },
    },
  );
}
