import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  PinInput,
  PinInputField,
  Select,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { Skeleton } from 'components/Skeleton';
import { useUserContext } from 'context/UserContextProvider';
import { WorkshopData } from 'hooks/queries/workshop/useWorkshopQuery';
import { forEach, get, isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { getI18n, Trans } from 'react-i18next';
import { reach } from 'yup';

import { LooseObject } from '../api/types';
import { SingleDateInput, SingleDatepicker } from '../components/DatePicker';
import { DropzoneInput } from '../components/Dropzone';
import IconTooltip from '../components/IconTooltip';
import { DropdownOption } from '../components/Table/Filter';
import { getArrayForGroupedDropdown, getCustomMethodFormFormField } from './general';

export const isFunctionalityAvailable = (
  workshop: WorkshopData,
  portal: { verification_needed: string },
) => {
  if (workshop?.is_verified) {
    return true;
  }
  if (workshop?.is_verification_pending && portal?.verification_needed === 'pending') {
    return true;
  }
  if (portal?.verification_needed === 'none') {
    return true;
  }
  return false;
};
export const isRequired = ({ field = '', schema = null } = {}): boolean =>
  schema ? get(reach(schema, field), `exclusiveTests.required`, false) : false;

export const createDropdownOptionsNumbers = (records: Array<any>, t: any) => {
  let returnArray = [{ label: t('common:choose'), value: '' }];

  forEach(records, (value) => {
    returnArray.push({
      label: value,
      value: value,
    });
  });
  return returnArray;
};

export const RequiredAsterisk: React.FC<{}> = () => (
  <span>
    <sup>*</sup>
  </span>
);

export const simpleCheckBox = (props: {
  name: string;
  label?: string;
  // eslint-disable-next-line no-undef
  description?: string | JSX.Element | undefined;
  showAsTooltip?: boolean;
  register: any;
  errors: any;
  defaultChecked?: boolean;
  schema?: any;
  disabled?: boolean;
  size?: string;
  required?: boolean;
  isChecked?: boolean;
  boxShadow?: string;
  borderColor?: string;
  borderRadius?: string;
}) => {
  const {
    name,
    label,
    description,
    register,
    errors,
    defaultChecked = false,
    showAsTooltip = false,
    disabled = false,
    required = false,
    isChecked,
    size = 'xl',
    schema,
    borderColor,
    borderRadius,
  } = props;
  return (
    <FormControl id={name} key={'checkbox-' + name}>
      <Stack textAlign="left">
        <HStack alignItems={'flex-start'}>
          <Checkbox
            {...register(name, {
              shouldUnregister: true,
            })}
            defaultChecked={defaultChecked}
            isChecked={isChecked}
            borderColor={!isNil(errors[name]) ? 'error' : borderColor}
            size={size}
            p={2}
            pl={0}
            pb={0}
            disabled={disabled}
            borderRadius={borderRadius}
          />
          <Stack alignSelf={'center'}>
            {label || description ? (
              <FormLabel m={0} paddingBlockStart={1} htmlFor={name}>
                <Text color={disabled ? 'disabled' : 'inherit'}>
                  {Boolean(label) && label}
                  {isRequired({ field: name, schema }) || required ? (
                    <RequiredAsterisk />
                  ) : null}
                  {Boolean(description) && !disabled && showAsTooltip && (
                    <IconTooltip text={description}></IconTooltip>
                  )}
                </Text>
                {Boolean(description) && !showAsTooltip && (
                  <Text color={disabled ? 'disabled' : 'inherit'} fontSize="xs">
                    {description}
                  </Text>
                )}
              </FormLabel>
            ) : null}
          </Stack>
        </HStack>
        {!isNil(errors) && !isEmpty(errors) ? (
          <Box pl={10}>
            <Text fontSize="sm" color={'error'} data-test-id={name + '_error'}>
              <ErrorMessage errors={errors} name={name} />
            </Text>
          </Box>
        ) : null}
      </Stack>
    </FormControl>
  );
};

export const dropdown = (props: {
  hideDropdownNoValueOption?: boolean;
  name: string;
  label?: string;
  description?: string | undefined;
  control: Control<any> | undefined;
  register: any;
  showAsTooltip?: boolean;
  disabled?: boolean;
  errors: any;
  options: any[];
  deps?: any[];
  schema?: any;
  required?: boolean;
  optionGetter?: LooseObject;
  customClass?: string;
  w?: string;
  display?: object;
  workshop?: WorkshopData;
  boxShadow?: string;
  bgColor?: string;
  placeholder?: string;
  placeHolderColor?: string;
  borderRadius?: string;
}) => {
  const {
    hideDropdownNoValueOption,
    name,
    label,
    description,
    control,
    register = false,
    errors,
    required,
    deps = [],
    optionGetter = {},
    options = [],
    schema,
    showAsTooltip = false,
    disabled = false,
    customClass = '',
    display,
    w = '100%',
    boxShadow,
    bgColor,
    placeholder = undefined,
    placeHolderColor = 'black',
    borderRadius,
  } = props;

  const dropdownOptions = isEmpty(optionGetter)
    ? options
    : getCustomMethodFormFormField(optionGetter?.method)(deps);

  const workshop = useUserContext().workshop;

  const country = workshop?.country;
  const units = workshop?.units;
  const language = workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;

  return (
    <FormControl id={name} w={w} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg" htmlFor={name}>
          {label && (
            <>
              {label}{' '}
              {isRequired({ field: name, schema }) || required || deps ? (
                <RequiredAsterisk />
              ) : null}
            </>
          )}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}

      <Controller
        name={name}
        control={control}
        key={'input-' + name + '-controller'}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            boxShadow={boxShadow}
            bgColor={bgColor}
            key={'input-' + name + '-select'}
            disabled={disabled}
            className={customClass}
            {...register(name, {
              shouldUnregister: true,
            })}
            defaultValue={value}
            aria-label={label}
            value={dropdownOptions.find((c: DropdownOption) => c.value === value)?.value}
            onChange={onChange}
            errorBorderColor={useColorModeValue('status.error', 'status.error')}
            focusBorderColor={useColorModeValue('accent', 'accent')}
            placeholder={placeholder}
            color={value ? 'black' : placeHolderColor}
            borderRadius={borderRadius}
          >
            {hideDropdownNoValueOption ? null : (
              <option key={'no-value' + '-' + name} value={''}>
                <Trans key={'common:choose'} />
              </option>
            )}

            {dropdownOptions.map((option: DropdownOption, index: number) => {
              //@ts-ignore
              if (display?.format === 'year-distance') {
                const formattedData = option?.value?.split('/');
                const year = `${formattedData[0]} ${getI18n().t('forms:year.label')}`;
                const distance = `${parseInt(formattedData[1]).toLocaleString(
                  localeParams,
                )} ${units === 'metric' ? 'km' : 'mi'}`;
                const formattedYearDistance = `${year} / ${distance}`;
                return (
                  <option key={index + '-' + name} value={option.value}>
                    {formattedYearDistance}
                  </option>
                );
                //@ts-ignore
              } else if (display?.format === 'distance') {
                return (
                  <option key={index + '-' + name} value={option.value}>
                    {`${option.value.toLocaleString(localeParams)} ${
                      units === 'metric' ? 'km' : 'mi'
                    }`}
                  </option>
                );
              } else {
                return (
                  <option key={index + '-' + name} value={option.value}>
                    {option.label}
                  </option>
                );
              }
            })}
          </Select>
        )}
      />
      {name !== 'telephone_prefix' && !isNil(errors) && !isEmpty(errors) ? (
        <Text fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
      {/* rendering not visble text for tel prefix alignment  */}
      {name === 'telephone_prefix' &&
      !isNil(errors) &&
      !isEmpty(errors) &&
      errors['telephone'] ? (
        <Text fontSize="sm" color={'transparent'}>
          Inv
        </Text>
      ) : null}
    </FormControl>
  );
};

export const dropdownGrouped = (props: {
  name: string;
  label: string;
  description?: string | undefined;
  control: Control<any> | undefined;
  showAsTooltip?: boolean;
  disabled?: boolean;
  register: any;
  errors: any;
  options: any[];
  schema?: any;
}) => {
  const {
    name,
    label,
    description,
    control,
    errors,
    options,
    register,
    schema,
    showAsTooltip = false,
    disabled = false,
  } = props;

  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg" htmlFor={name}>
          {label} {isRequired({ field: name, schema }) && <RequiredAsterisk />}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}

      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={disabled}
            {...register(name, {
              shouldUnregister: true,
            })}
            defaultValue={''}
            aria-label={label}
            value={
              getArrayForGroupedDropdown(options).find(
                (c: DropdownOption) => c.value === value,
              )?.value
            }
            onChange={onChange}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          >
            {options.map((option, index) => (
              <optgroup label={option.label} key={index}>
                {get(option, 'options', []).map(
                  (subOption: DropdownOption, subIndex: number) => (
                    <option value={subOption.value} key={subIndex}>
                      {subOption.label}
                    </option>
                  ),
                )}
              </optgroup>
            ))}
          </Select>
        )}
      />
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};

export interface ISimpleInputProps {
  name: string;
  label?: string;
  description?: string | undefined;
  placeholder?: string;
  register: any;
  errors: any;
  disabled?: boolean;
  type?: string;
  schema?: any;
  showAsTooltip?: boolean;
  leftAddon?: string;
  rightAddon?: string;
  customHook?: (val: any) => any;
  customKeyDownHook?: (val: any) => any;
  customClass?: string;
  required?: boolean;
  boxShadow?: string;
  width?: string;
}

export const simpleInput: React.FC<ISimpleInputProps> = (props) => {
  const {
    name,
    placeholder,
    label = false,
    description,
    register,
    disabled,
    errors,
    type,
    schema,
    showAsTooltip = false,
    customClass = '',
    leftAddon,
    rightAddon,
    required = false,
    customHook = (val: any) => val,
    customKeyDownHook = (val: any) => val,
    boxShadow,
    width,
  } = props;
  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg">
          {label}
          {label && (isRequired({ field: name, schema }) || required) ? (
            <RequiredAsterisk />
          ) : null}
          {Boolean(description) && !showAsTooltip && (
            <Text fontSize="xs">{description}</Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}
      <InputGroup>
        {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
        <Input
          className={customClass}
          key={'input-' + name + '-string'}
          type={type ?? 'text'}
          {...register(name)}
          errorBorderColor="status.error"
          disabled={disabled}
          //@ts-ignore
          onInput={(e) => customHook(e.target.value)}
          onKeyDown={(e) => customKeyDownHook(e)}
          placeholder={placeholder}
          _placeholder={{ opacity: 0.5 }}
          boxShadow={boxShadow}
          width={width}
        />
        {rightAddon && <InputRightAddon>{rightAddon} </InputRightAddon>}
      </InputGroup>
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text
          mt={1}
          pl="1"
          fontSize="sm"
          textAlign="left"
          color={'error'}
          data-test-id={name + '_error'}
        >
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};

export const pinInput = (props: {
  name: string;
  label?: string;
  description?: string | undefined;
  placeholder?: string;
  register: any;
  errors: any;
  disabled?: boolean;
  schema?: any;
  showAsTooltip?: boolean;
  pinAmount?: number;
  control: any;
  size?: string;
  borderColor?: string;
  borderWidth?: string;
  borderRadius?: string;
}) => {
  const {
    name,
    placeholder,
    label = false,
    description,
    register,
    disabled,
    errors,
    schema,
    showAsTooltip = false,
    control,
    size = 'lg',
    pinAmount = 4,
    borderColor,
    borderWidth,
  } = props;

  const renderedPinInputFields = Array(pinAmount)
    .fill(null)
    .map((_noop, i) => (
      <PinInputField
        _placeholder={{ opacity: 0.5 }}
        key={i}
        style={{
          borderWidth: borderWidth,
          borderColor: borderColor,
          backgroundColor: 'white',
        }}
      />
    ));

  return (
    <Controller
      {...register(name, {
        shouldUnregister: true,
      })}
      control={control}
      isInvalid={!!get(errors, name, {})?.message}
      render={({ field }) => (
        <Stack spacing={2} alignItems={'center'}>
          {label || description ? (
            <FormLabel size="lg">
              {label}{' '}
              {label && isRequired({ field: name, schema }) && <RequiredAsterisk />}
              {Boolean(description) && !showAsTooltip && (
                <Text fontSize="xs">{description}</Text>
              )}
              {Boolean(description) && showAsTooltip && (
                <IconTooltip text={description}></IconTooltip>
              )}
            </FormLabel>
          ) : null}
          <HStack>
            <PinInput
              size={size}
              id={name}
              placeholder={placeholder}
              isDisabled={disabled}
              {...field}
              errorBorderColor="status.error"
            >
              {renderedPinInputFields}
            </PinInput>
          </HStack>

          {!isNil(errors) && !isEmpty(errors) ? (
            <Text
              mt={1}
              pl="1"
              fontSize="sm"
              textAlign="left"
              color={'error'}
              data-test-id={name + '_error'}
            >
              <ErrorMessage errors={errors} name={name} />
            </Text>
          ) : null}
        </Stack>
      )}
    />
  );
};

export const numberInput = (props: {
  name: string;
  label: string;
  description?: string | undefined;
  placeholder?: string;
  register: any;
  errors: any;
  disabled?: boolean;
  min?: number;
  max?: number;
  defaultValue?: number;
  schema?: any;
  required?: boolean;
  showAsTooltip?: boolean;
  onChange?: (e: any) => void;
}) => {
  const {
    name,
    label,
    description,
    register,
    placeholder,
    disabled,
    errors,
    min,
    max,
    required = false,
    defaultValue = min,
    schema,
    showAsTooltip = false,
  } = props;

  document.addEventListener('wheel', function () {
    //@ts-ignore
    if (document?.activeElement?.type === 'number') {
      //@ts-ignore
      document.activeElement?.blur();
    }
  });

  return (
    <FormControl
      id={name}
      isInvalid={!!get(errors, name, {})?.message}
      onChange={props.onChange}
    >
      {label || description ? (
        <FormLabel size="lg">
          {label}{' '}
          {isRequired({ field: name, schema }) || required ? <RequiredAsterisk /> : null}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}
      <Input
        type={'number'}
        min={min}
        max={max}
        defaultValue={defaultValue}
        errorBorderColor="status.error"
        placeholder={placeholder}
        _placeholder={{ opacity: 0.5 }}
        {...register(name, {
          valueAsNumber: true,
          shouldUnregister: true,
        })}
        disabled={disabled}
      />
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};

export const textareaInput = (props: {
  onChange?: void;
  value?: string;
  name: string;
  label?: string;
  description?: string | undefined;
  placeholder?: string | undefined;
  register: any;
  style?: any;
  errors: any;
  disabled?: boolean;
  schema?: any;
  showAsTooltip?: boolean;
  resize?: boolean;
  required?: boolean;
  onFocus?: () => void;
}) => {
  const {
    onChange,
    value,
    name,
    label,
    description,
    register,
    disabled,
    errors,
    placeholder,
    schema,
    showAsTooltip = false,
    required = false,
    resize = 'none',
    style,
    onFocus,
  } = props;
  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg">
          {label}{' '}
          {isRequired({ field: name, schema }) || required ? <RequiredAsterisk /> : null}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}
      <Textarea
        data-test-id={name}
        minH="10rem"
        {...register(name, {
          shouldUnregister: true,
        })}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        _placeholder={{ opacity: 0.5 }}
        errorBorderColor="status.error"
        resize={resize}
        onFocus={onFocus}
        border={'1px solid white'}
        style={style}
      />
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};
export const dateInput = (props: {
  name: string;
  label: string;
  description?: string | undefined;
  control: any;
  errors: any;
  disabled?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  schema?: any;
  showAsTooltip?: boolean;
  register: any;
  required?: boolean;
}) => {
  const {
    name,
    label,
    description,
    register,
    control,
    disabled,
    errors,
    minDate,
    maxDate,
    schema,
    required = false,
    showAsTooltip = false,
  } = props;

  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg">
          {label}{' '}
          {isRequired({ field: name, schema }) || required ? <RequiredAsterisk /> : null}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <SingleDatepicker
            //@ts-ignore
            date={field.value ? new Date(field.value) : undefined}
            name={'date_' + name}
            {...register(name, {
              shouldUnregister: true,
            })}
            disabled={disabled}
            onDateChange={field.onChange}
            minDate={
              minDate instanceof Date || isNil(minDate) ? minDate : new Date(minDate)
            }
            maxDate={
              maxDate instanceof Date || isNil(maxDate) ? maxDate : new Date(maxDate)
            }
            configs={{ dateFormat: 'dd.MM.yyyy' }}
          />
        )}
      />
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};

export const typingDateInput = (props: {
  name: string;
  label: string;
  description?: string | undefined;
  placeholder?: string | undefined;
  control: any;
  errors: any;
  disabled?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  schema?: any;
  showAsTooltip?: boolean;
  register: any;
  required?: boolean;
}) => {
  const {
    name,
    label,
    description,
    control,
    disabled,
    register,
    errors,
    minDate,
    maxDate,
    schema,
    placeholder,
    required = false,
    showAsTooltip = false,
  } = props;
  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label || description ? (
        <FormLabel size="lg">
          {label}{' '}
          {isRequired({ field: name, schema }) || required ? <RequiredAsterisk /> : null}
          {Boolean(description) && !showAsTooltip && (
            <Text color="muted" fontSize="xs">
              {description}
            </Text>
          )}
          {Boolean(description) && showAsTooltip && (
            <IconTooltip text={description}></IconTooltip>
          )}
        </FormLabel>
      ) : null}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <SingleDateInput
            //@ts-ignore
            date={field.value ? new Date(field.value) : undefined}
            name={'date_' + name}
            {...register(name, {
              shouldUnregister: true,
            })}
            placeholder={placeholder}
            _placeholder={{ opacity: 0.5 }}
            // style={{ input: { '::placeholder': { opacity: 0.1, color: 'red' } } }}
            disabled={disabled}
            onDateChange={field.onChange}
            minDate={
              minDate instanceof Date || isNil(minDate) ? minDate : new Date(minDate)
            }
            maxDate={
              maxDate instanceof Date || isNil(maxDate) ? maxDate : new Date(maxDate)
            }
            configs={{ dateFormat: 'dd.MM.yyyy' }}
          />
        )}
      />
      {!isNil(errors) && !isEmpty(errors) ? (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      ) : null}
    </FormControl>
  );
};
export const dropzone = (props: {
  fontSize?: string;
  name: string;
  label?: string;
  // eslint-disable-next-line no-undef
  description?: string | JSX.Element | undefined;
  control: Control<any> | undefined;
  errors: any;
  register: any;
  isLoading?: boolean;
  schema?: any;
  maxFiles?: number;
  required?: boolean;
}) => {
  const {
    fontSize,
    name,
    label,
    description,
    required,
    // eslint-disable-next-line
    register,
    control,
    errors,
    isLoading = false,
    maxFiles = 1,
  } = props;
  if (isLoading) {
    return <Skeleton height="200px" />;
  }
  return (
    <FormControl id={name} isInvalid={!!get(errors, name, {})?.message}>
      {label ? (
        <FormLabel size="lg" htmlFor={name} fontSize={fontSize}>
          {label}{' '}
          {/* {(isRequired({ field: name, schema }) || name === 'invoice') && (
            <RequiredAsterisk />
          )} */}
          {required && <RequiredAsterisk />}
        </FormLabel>
      ) : null}
      <Text fontSize="sm">{description}</Text>
      <Controller
        name={name}
        data-test-id={name}
        control={control}
        rules={{ required: true }}
        {...register(name)}
        render={({ field }) => (
          <DropzoneInput
            identifier={`${name}_zone ${name.split('.').pop()}`}
            onChange={field.onChange}
            maxFiles={maxFiles}
          />
        )}
      />{' '}
      {errors?.[name]?.message === 'invoice is a required field' ? (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <Trans i18nKey="errors:dropzone.invoice" />
        </Text>
      ) : (
        <Text mt={1} pl="1" fontSize="sm" color={'error'} data-test-id={name + '_error'}>
          <ErrorMessage errors={errors} name={name} />
        </Text>
      )}
    </FormControl>
  );
};
