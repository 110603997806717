import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';

async function update(axios: AxiosInstance, data: any): Promise<object> {
  return await axios.post(`${config.apiBaseUrl}/workshop-update-request`, data);
}

export default function useAccountUpdateFormMutation() {
  const axios = useAxios();
  return useMutation(
    (data: any) => update(axios, data),
    //  {
    //   onError: (err: any) => {
    //     if (err.response.data.message === 'no changes detected') {
    //       manageErrorResponse({
    //         toastInstance: toast,
    //         error: err.response,
    //         // @ts-ignore
    //         customMessage: t('errors:no_changes'),
    //         t,
    //       });
    //     } else {
    //       manageErrorResponse({ toastInstance: toast, error: err.response, t });
    //     }
    //     return Promise.reject(err);
    //   },
    // }
  );
}
