import { Box, Button } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import FileUploadModal from 'components/modals/FileUploadModal';
import { find, get } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDON_KEYS, SubscriptionAddon } from '../../api/types';
import GetAddonModalData from '../../components/modals/GetAddonModalData';
import { useUserContext } from '../../context/UserContextProvider';

const FileForm = (props: {
  sendRequest: (data: FormData) => void;
  costControlDisabled?: boolean;
  hasOneDocument: boolean;
  addons: SubscriptionAddon[];
  subscription?: any;
}) => {
  const { t } = useTranslation();
  const {
    sendRequest,
    subscription,
    costControlDisabled = false,
    hasOneDocument,
    addons,
  } = props;

  const userContext = useUserContext();

  const activeAddons = userContext.workshop?.subscription?.active_addons || {};

  const hasDocsPackage = get(activeAddons, ADDON_KEYS.OE_DOCS, false);

  const addonModalData = GetAddonModalData({
    addon: find(addons, { id: ADDON_KEYS.OE_DOCS }),
    activeAddon: get(subscription?.active_addons, ADDON_KEYS.OE_DOCS, {}),
    subscription,
    costControlDisabled,
    isTrial: true,
    displaySpecific: true,
  });

  const [openFileModal, setOpenFileModal] = useState(false);

  return (
    <>
      <Box alignSelf={'flex-start'}>
        <Button
          variant="primary"
          data-test-id="upload-button"
          onClick={() =>
            hasDocsPackage
              ? setOpenFileModal((prevState) => !prevState)
              : NiceModal.show(addonModalData.modalComponent, addonModalData)
          }
        >
          {t(`documents:${hasOneDocument ? 'upload_another' : 'upload'}`)}
        </Button>
      </Box>
      <FileUploadModal
        openFileModal={openFileModal}
        sendRequest={sendRequest}
        setOpenFileModal={setOpenFileModal}
      />
    </>
  );
};
export default FileForm;
