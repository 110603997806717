import {
  Box,
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import { PortalButton } from '../../../components/buttons/PortalButton';
import { PasswordField } from '../../../components/PasswordField';
import RequirementsChecklist from '../../../components/RequirementsChecklist';
import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';

YupPassword(yup);

export interface IPasswordConfirmForm {
  password: string;
  password_confirmation: string;
  registration_form_key: string | undefined;
}

export const SetPasswordStep = (props: {
  data: Partial<WorkshopData>;
  workshopId: number;
}) => {
  const posthog = usePostHog();

  const toast = useToast();
  const { t } = useTranslation();
  const { data, workshopId } = props;

  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' ||
    posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  const boxWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });
  const schema = yup
    .object({
      password: yup
        .string()
        .min(8, t('forms:password.length'))
        .minNumbers(1, t('forms:password.number'))
        .minUppercase(1, t('forms:password.uppercase'))
        .minLowercase(1, t('forms:password.lowercase'))
        .minSymbols(1, t('forms:password.symbols'))
        .required(),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], t('forms:password.match'))
        .required(t('forms:password.length')),
    })
    .required();

  // key of the Map Entry has to be the name of the yup validation, the value is the referencing Caption
  const createValidationMap = (): Map<string, string> => {
    const validationConfig = {
      min: t('forms:password.criteria.length'),
      minNumber: t('forms:password.criteria.number'),
      minSymbol: t('forms:password.criteria.symbols'),
      minUppercase: t('forms:password.criteria.uppercase'),
      minLowercase: t('forms:password.criteria.lowercase'),
    };
    return new Map(Object.entries(validationConfig));
  };

  const validationMap: Map<string, string> = createValidationMap();

  const {
    register,
    trigger,
    formState: { errors, isValid, submitCount },
    handleSubmit,
    setError,
  } = useForm<IPasswordConfirmForm>({
    defaultValues: {
      registration_form_key: data.registration_form_key,
    },
    resolver: yupResolver(schema),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const navigate = useNavigate();
  let { registration_form_key } = data;

  const appendRegistrationFormKey = (obj: object = {}): object => {
    return {
      ...obj,
      registration_form_key,
    };
  };

  const { mutate, isLoading } = useMutation<any, Error, IPasswordConfirmForm>(
    async (data) => {
      // someday get axios calls to own API Service
      return await axios
        .put(
          `${config.apiBaseUrl}/workshop/${workshopId}/complete-signup`,
          appendRegistrationFormKey(data),
        )
        .catch((err) => {
          manageErrorResponse({ toastInstance: toast, error: err.response, t });
          return Promise.reject(err);
        });
    },
  );
  const onSubmit: SubmitHandler<IPasswordConfirmForm> = (data) => {
    mutate(data, {
      onSuccess: () => {
        posthog.capture('select_subscription_page_pageview');
        navigate('/login');
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        (Object.keys(errors) as Array<keyof IPasswordConfirmForm>).forEach((key) => {
          setError(key, {
            type: 'server',
            message: errors[key]!.join('. '),
          });
        });
      },
    });
  };

  // Initial Password Validation for Requirements info
  useEffectOnce(() => {
    trigger('password');
  });

  return (
    <Container
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-test-id="set-password-form"
      py={abTestNewDesign ? 0 : { base: '0', sm: '8' }}
      px={abTestNewDesign ? 0 : { base: '4', sm: '6' }}
      // @ts-ignore
      autocomplete="new-password"
      width={abTestNewDesign ? boxWidth : 'full'}
    >
      {abTestNewDesign && (
        <Box>
          <Heading size="xs" mb="5">
            {t('workshop:registration.stepper.three.set_password')}
          </Heading>
          <Text color="muted" fontSize="sm" mb="10">
            {t('workshop:registration.stepper.three.description')}
          </Text>
        </Box>
      )}

      <Stack spacing="8" alignItems={'center'}>
        <Stack spacing="8" w={{ base: 'full', lg: '30rem' }}>
          <PasswordField
            label={t('forms:password.label')}
            register={register('password')}
            boxShadow={abTestNewDesign ? 'md' : undefined}
          />
          <PasswordField
            label={t('forms:password_confirmation.label')}
            register={register('password_confirmation')}
            error={errors.password_confirmation}
            boxShadow={abTestNewDesign ? 'md' : undefined}
          />
          <Stack spacing={3} textAlign={'left'}>
            <Heading as="h2" size={useBreakpointValue({ base: 'xxs', md: 'xxs' })}>
              {t('forms:password.criteria.label')}
            </Heading>
            <RequirementsChecklist
              validationMap={validationMap}
              error={errors.password}
            />
          </Stack>
        </Stack>
      </Stack>
      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <PortalButton
          type="submit"
          data-test-id="set-password-next-button"
          disabled={(submitCount! > 0 && !isValid) || isLoading}
          isLoading={isLoading}
        >
          {t('workshop:registration.stepper.three.submit')}
        </PortalButton>
      </Flex>
    </Container>
  );
};

export default SetPasswordStep;
