import {
  Box,
  Button,
  Collapse,
  Divider,
  Heading,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { find, get, isEmpty, isNil, merge } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsCardChecklist } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IoIosWarning } from 'react-icons/io';
import { MdManageSearch } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { highlightInput, highlightVinInput } from '../../../animations/specials';
import {
  ADDON_KEYS,
  LooseObject,
  Service,
  StatusCodes,
  SubscriptionAddon,
} from '../../../api/types';
import {
  Documents,
  ServiceHistoryRequestIcon,
  ServiceRecordIcon,
} from '../../../components/Icons';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import AlertModal from '../../../components/modals/AlertModal';
import GetAddonModalData from '../../../components/modals/GetAddonModalData';
import { Skeleton } from '../../../components/Skeleton';
import { CardHeaderSkeleton } from '../../../components/SkeletonTemplates';
import { awaitAnimation } from '../../../configs/animations';
import { useUserContext } from '../../../context/UserContextProvider';
import { eventTrack } from '../../../helpers/analytics';
import dateTransformer from '../../../helpers/dateTransformer';
import { downloadFile } from '../../../helpers/downloadHelper';
import UnlockFeature from '../../../helpers/unlockFeature';
import useCompleteServiceMutation from '../../../hooks/private/mutations/useCompleteServiceMutation';
import useDeleteDocumentMutation from '../../../hooks/private/mutations/useDeleteDocumentMutation';
import useSendDocumentsMutation from '../../../hooks/private/mutations/useSendDocumentsMutation';
import useUploadDocumentMutation from '../../../hooks/private/mutations/useUploadDocumentMutation';
import useServiceHistoryQuery from '../../../hooks/queries/service/useServiceHistoryQuery';
import useAccountsQuery from '../../../hooks/queries/useAccountsQuery';
import useFileQuery from '../../../hooks/queries/useFileQuery';
import { SERVICE_STATE } from '../../../hooks/queries/useVehicleQuery';
import useVehicleQuery from '../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';
import { Card } from '../../../layout/Card';
import { transformManufacturerAccounts } from '../../serviceRecord/AccountRegistrationStatusPage';
import { getCardColor, getInteractionContentBasedOnState, tool } from './displayHelper';
import DocumentsSection from './DocumentsSection';
import { ServiceStep } from './ServiceStep';
import { ServiceActivityTimeline } from './timeline/ServiceActivityTimeline';

const defaultStepState = {
  vehicle_identification: 'available',
  service_history_request: 'unavailable',
  inspection_plan: 'unavailable',
  service_record: 'unavailable',
  uploaded_documents: 'unavailable',
  sent_documents: 'unavailable',
};

type TransformedAccounts = {
  account: {
    status: number;
    can_access_dsb: true;
  };
  account_id: number;
  estimate: string;
  make_name: string;
};

const ServiceProcessCard = (props: {
  make: string | undefined;
  isLoading: boolean;
  state?: SERVICE_STATE;
  form?: string;
  hasCollapsableContent?: boolean;
  vin?: string;
  service?: Service | LooseObject;
  workshop: WorkshopData;
  addons: SubscriptionAddon[];
  statusCodes?: StatusCodes;
  features?: object;
  timezone: string;
  vehicleTypesExist?: boolean;
  sendRequest: (data: any) => Promise<any>;
}) => {
  const {
    make,
    isLoading,
    state = SERVICE_STATE.OPEN,
    hasCollapsableContent = false,
    form = false,
    vin = '',
    service = {},
    workshop,
    statusCodes,
    addons = [],
    features,
    timezone,
    vehicleTypesExist = false,
    sendRequest,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [processStepsState, setProcessStepsState] =
    useState<SetStateAction<any>>(defaultStepState);
  const [isContentVisible, setIsContentVisible] = useBoolean(!hasCollapsableContent);
  const unlockFeatureRef = useRef(null);
  useEffect(() => {
    //override features based on API response
    const activeState = service?.features || features || defaultStepState;
    setProcessStepsState({ ...activeState });
  }, [features, service?.features]);

  //for file download functionality
  const [url, setUrl] = useState<SetStateAction<any>>(undefined);
  const [click, setClick] = useState(false);
  const fileQuery = useFileQuery(url);

  const [downloadDocument, setDownloadDocument] =
    useState<SetStateAction<any>>(undefined);
  useEffect(() => {
    if (!click) {
      downloadFile(downloadDocument, fileQuery.data);
    }
    // downloadFile(downloadDocument, fileQuery.data);
    setClick(false);
  }, [fileQuery.data, click]);
  const queryClient = useQueryClient();
  const uploadDocumentMutation = useUploadDocumentMutation();
  const sendDocumentsMutation = useSendDocumentsMutation(service?.id);
  const completeServiceMutation = useCompleteServiceMutation(service?.id);
  const deleteDocumentMutation = useDeleteDocumentMutation();

  const accountsQuery = useAccountsQuery();
  const accounts = accountsQuery.data || [];
  const transformedAccounts = transformManufacturerAccounts(
    accounts,
  ) as unknown as Array<TransformedAccounts>;

  const account = transformedAccounts.find((account) => {
    if (account?.make_name == make) {
      return account;
    } else {
      return;
    }
  });
  const accountStatusEqualsNotAvailable = account?.account.status === 50;
  const workshopHasVat = workshop?.vat_number;
  const currency = workshop?.currency;
  const identifiedVehicle = Boolean(vin);
  const requiresIdentification = isEmpty(service) && !identifiedVehicle;
  const activeAddons = get(workshop?.subscription, 'active_addons', {});
  const hasInspectionPlanAddOn = get(
    activeAddons,
    ADDON_KEYS.INSPECTION_PLANS_ADDON,
    false,
  );
  const [searchParams] = useSearchParams();
  const createdServiceRecordId = searchParams.get('entryId');

  const serviceActivities = get(service, 'service_activities', []);
  const vehicleQuery = useVehicleQuery(vin);

  const accountBlockedDeletedInProgress =
    vehicleQuery.data?.account?.status === 100 ||
    vehicleQuery.data?.account?.status === 700 ||
    vehicleQuery.data?.account?.status === 900;

  const accountRejected = vehicleQuery.data?.account?.status === 800;
  const vehicleServiceActivities = get(
    vehicleQuery.data?.active_service,
    'service_activities',
    [],
  );

  const serviceRecordIdArray = vehicleServiceActivities?.filter(
    (serviceActivity: any) => serviceActivity?.service_record_id !== null,
  );
  const serviceRecordId = serviceRecordIdArray[0]?.service_record_id;
  const serviceRecordStatus = serviceRecordIdArray[0]?.service_record?.status;

  const serviceHistoryRecordIdArray = vehicleServiceActivities?.filter(
    (serviceActivity: any) => serviceActivity?.service_history_request_id !== null,
  );
  const serviceHistoryRecordId =
    serviceHistoryRecordIdArray[0]?.service_history_request_id;
  const serviceHistoryRecordStatus =
    serviceHistoryRecordIdArray[0]?.service_history_request?.status;

  const serviceHistoryQuery = useServiceHistoryQuery(serviceHistoryRecordId);

  const serviceRecordCompleted = !isNil(serviceRecordId) && serviceRecordStatus === 700;
  const serviceRecordDeleted = !isNil(serviceRecordId) && serviceRecordStatus === 850;
  const serviceRecordCanceled = !isNil(serviceRecordId) && serviceRecordStatus === 900;
  const serviceHistoryRecordCompleted =
    !isNil(serviceHistoryRecordId) && serviceHistoryRecordStatus === 700;

  const serviceRecordInProcess =
    !isNil(serviceRecordId) &&
    serviceRecordStatus > 0 &&
    serviceRecordStatus !== 700 &&
    serviceRecordStatus !== 850 &&
    serviceRecordStatus !== 900;

  const serviceHistoryInProcess =
    !isNil(serviceHistoryRecordId) &&
    serviceHistoryQuery.data?.status > 0 &&
    serviceHistoryQuery.data?.status !== 700 &&
    serviceHistoryQuery.data?.status !== 900;

  const isWorkshopVerified = workshop.is_verified;
  const isWorkshopPendingVerification = workshop.is_verification_pending;

  const documents = get(service, 'documents', []);
  const userContext = useUserContext();

  const supportPhoneNumber = userContext?.config?.support_telephone;
  const supportEmail = userContext?.config?.support_email;
  const isMazdaAndCountryDe = make === 'Mazda' && workshop?.country === 'DE';
  const modalData = GetAddonModalData({
    isMazdaAndCountryDe: isMazdaAndCountryDe,
    addon: find(addons, { id: ADDON_KEYS.PLUS_DSB }),
    activeAddons: activeAddons,
    activeAddon: get(workshop?.subscription?.active_addons, ADDON_KEYS.PLUS_DSB, {}),
    // @ts-ignore
    subscription: workshop?.subscription,
    costControlDisabled: workshop?.disable_cost_control,
    isTrial: true,
    displaySpecific: true,
    onClose: () => navigate('/app/w/vehicle?vin=' + vehicleQuery.data?.vin),
  });
  const stepsConfig = {
    vehicle_identification: {
      step: 1,
      isActive: processStepsState.vehicle_identification === 'available',
      isCompleted: processStepsState.vehicle_identification === 'completed',
      isUnique: processStepsState.vehicle_identification === 'completed',
      requiresVehicleType: false,
    },
    service_history_request: {
      step: 2,
      isActive: processStepsState.service_history_request === 'available',
      isCompleted: processStepsState.service_history_request === 'completed',
      isCanceled: processStepsState.service_history_request === 'canceled',
      isProcessing:
        processStepsState.service_history_request === SERVICE_STATE.PROCESSING,
      isDisabled: processStepsState.service_history_request === 'unavailable',
      isUnique: ['unavailable', SERVICE_STATE.PROCESSING].includes(
        processStepsState.service_history_request,
      ),
      requiresVehicleType: false,
      requiresIdentification,
    },
    inspection_plan: {
      step: 3,
      isActive: processStepsState.inspection_plan === 'available',
      isCompleted: processStepsState.inspection_plan === 'completed_and_available',
      isProcessing: processStepsState.inspection_plan === SERVICE_STATE.PROCESSING,
      isDisabled: processStepsState.inspection_plan === 'unavailable',
      requiresVehicleType: true,
      requiresIdentification,
    },
    service_record: {
      step: 4,
      isActive: processStepsState.service_record === 'available',
      isCompleted: processStepsState.service_record === 'completed',
      isCanceled: processStepsState.service_record === 'canceled',
      isProcessing: processStepsState.service_record === SERVICE_STATE.PROCESSING,
      isInClarification: processStepsState.service_record === 'clarification',
      isRequestedDeletion: processStepsState.service_record === 'requested_deletion',
      isDisabled:
        processStepsState.service_record === 'unavailable' ||
        processStepsState.service_record === 'requested_deletion',
      isUnique: ['unavailable', SERVICE_STATE.PROCESSING, 'clarification'].includes(
        processStepsState.service_record,
      ),
      requiresVehicleType: false,
      requiresIdentification,
    },
    uploaded_documents: {
      step: 5,
      isActive: processStepsState.uploaded_documents === 'available',
      isCompleted: processStepsState.uploaded_documents === 'completed_and_available',
      isProcessing: processStepsState.uploaded_documents === SERVICE_STATE.PROCESSING,
      isDisabled: processStepsState.uploaded_documents === 'unavailable',
      requiresVehicleType: false,
      requiresIdentification,
    },
    sent_documents: {
      step: 6,
      isActive:
        processStepsState.sent_documents === 'available' ||
        processStepsState.sent_documents === 'completed_and_available',
      isCompleted: processStepsState.sent_documents === 'completed_and_available',
      isProcessing: processStepsState.sent_documents === SERVICE_STATE.PROCESSING,
      isDisabled: processStepsState.sent_documents === 'unavailable',
      requiresVehicleType: false,
      requiresIdentification,
    },
  };
  const stepSubContentConfig = {
    vehicle_identification: {
      subContent: tool({
        icon: (
          <Icon
            as={MdManageSearch}
            fill={getCardColor({ ...stepsConfig.vehicle_identification })}
            boxSize="10"
            alignSelf={'center'}
          />
        ),
        color: getCardColor({ ...stepsConfig.vehicle_identification }),
        label: t('components:service_process_card.vehicle_identification'),
        isLoading,
        ...stepsConfig.vehicle_identification,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          ...stepsConfig.vehicle_identification,
          handler: async () => {
            // @ts-ignore
            document.getElementById('vehicle-identification-anchor')?.scrollIntoView({
              behavior: 'smooth',
            });

            setTimeout(
              async () => await highlightVinInput().catch((error) => console.log(error)),
              200,
            );
          },
        }),
        testId: 'vehicle-identification-button',
      }),
    },
    service_history_request: {
      subContent: tool({
        icon: (
          <ServiceHistoryRequestIcon
            stroke={getCardColor({ ...stepsConfig.service_history_request })}
            boxSize="10"
            alignSelf={'center'}
          />
        ),
        color: getCardColor({ ...stepsConfig.service_history_request }),
        label: t('components:service_process_card.service_history_request.label'),
        isLoading,
        ...stepsConfig.service_history_request,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.service_history_request,
          ...stepsConfig.service_history_request,
          handler: async () => {
            const { isCompleted } = stepsConfig.service_history_request;

            if (!workshopHasVat && accountStatusEqualsNotAvailable) {
              return NiceModal.show(AlertModal, {
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} as={IoIosWarning} boxSize="10" />
                    </Square>
                  ),
                  header: t(
                    'workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.title',
                  ),

                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                childrenTransI18n: (
                  <Trans
                    i18nKey={`workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.body`}
                    values={{
                      supportPhoneNumber: supportPhoneNumber,
                      supportEmail: supportEmail,
                    }}
                  />
                ),
              });
            }

            if (isCompleted) {
              if (hasCollapsableContent) {
                setIsContentVisible.on();
                await awaitAnimation();
              }
              // TODO: Get ID of Item somehow to get to proper selector as built in ServiceActivityTimeline
              //@ts-ignore
              document
                .querySelector(`service_record_${createdServiceRecordId}`)
                ?.scrollIntoView({
                  behavior: 'smooth',
                });
            } else {
              //if the workshop is NOT verified, show a modal which tells the user that he needs to verify his workshop first, before he can request service histories
              if (!isWorkshopVerified) {
                NiceModal.show(AlertModal, {
                  onSubmit: () => navigate('/app/w/account/verification'),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: isWorkshopPendingVerification
                      ? t('components:verification_pending.header')
                      : t('components:verification_not_started.header'),
                    footer: {
                      buttons: {
                        hide: isWorkshopPendingVerification,
                        actionCaption: t('components:verification_not_started.cta'),
                      },
                    },
                  },
                  children: isWorkshopPendingVerification
                    ? t('components:verification_pending.text')
                    : t('components:verification_not_started.text'),
                });
                //if the workshop is verified, the vehicle has a DSB, an account for the DSB account exists, but the portal can't be accessed yet, show a modal which tells the user that the account registration is still taking place
              } else if (
                vehicleQuery.data?.has_dsb &&
                vehicleQuery.data?.account &&
                vehicleQuery.data?.account?.can_access_dsb === false &&
                workshopHasVat
              ) {
                NiceModal.show(AlertModal, {
                  accountRegistrationNotification: true,
                  accountRejected: accountRejected,
                  accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                  notifyWhenReady: vehicleQuery.data?.account?.notify_when_ready,
                  accountId: vehicleQuery.data?.account?.id,
                  onSubmit: () => navigate('/app/w/account-registration-status'),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: t('components:manufacturer_not_registered.header'),
                    footer: {
                      buttons: {
                        hide: true,
                        actionCaption: t('components:manufacturer_not_registered.cta'),
                      },
                    },
                  },
                  children: t('components:manufacturer_not_registered.text'),
                });
                //if the workshop is verified, and the vehicle has no DSB, or it has a DSB which either doesn't require an account or the account is already active, show the user the SHR form
              } else {
                navigate(`/app/w/request-service-record-history?vin=${vin}`);
              }
            }
          },
          messageCompleted:
            processStepsState.service_history_request &&
            t(
              `components:service_process_card.service_history_request.${processStepsState.service_history_request}.tooltip`,
            ),
          messageProcessing: t(
            `components:service_process_card.service_history_request.processing.tooltip`,
          ),
          messageOpen: t(
            `components:service_process_card.service_history_request.open.tooltip`,
          ),
        }),
        testId: 'service-history-button',
        tourId: 'service-history-request',
      }),
    },
    inspection_plan: {
      subContent: tool({
        icon:
          !hasInspectionPlanAddOn &&
          state !== SERVICE_STATE.OPEN &&
          !stepsConfig.inspection_plan?.isDisabled ? (
            <UnlockFeature
              currency={currency}
              ref={unlockFeatureRef}
              workshop={workshop}
              addons={addons}
              addonId={ADDON_KEYS.INSPECTION_PLANS_ADDON}
              label={t('forms:ip.label')}
              color={'#3f7dc1'}
              onSuccess={() => navigate(`/app/w/request-inspection-plan?vin=${vin}`)}
              icon={
                <Icon
                  as={BsCardChecklist}
                  boxSize="10"
                  fill={getCardColor({
                    ...stepsConfig.inspection_plan,
                    isDisabled: stepsConfig.inspection_plan?.isDisabled,
                  })}
                  alignSelf={'center'}
                />
              }
            />
          ) : (
            <Icon
              as={BsCardChecklist}
              boxSize="10"
              fill={getCardColor({
                ...stepsConfig.inspection_plan,
                isDisabled: stepsConfig.inspection_plan?.isDisabled,
              })}
              alignSelf={'center'}
            />
          ),
        color: getCardColor({
          ...stepsConfig.inspection_plan,
          isDisabled: stepsConfig.inspection_plan?.isDisabled,
        }),
        label:
          !hasInspectionPlanAddOn &&
          state !== SERVICE_STATE.OPEN &&
          !stepsConfig.inspection_plan?.isDisabled
            ? null
            : t('forms:ip.label'),
        isLoading,
        ...stepsConfig.inspection_plan,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.inspection_plan,
          ...stepsConfig.inspection_plan,
          handler: async () => {
            const { isCompleted } = stepsConfig.inspection_plan;
            if (hasCollapsableContent || (isCompleted && !SERVICE_STATE.COMPLETED)) {
              setIsContentVisible.on();
              await awaitAnimation();
            } else if (
              !(
                !hasInspectionPlanAddOn &&
                state !== SERVICE_STATE.OPEN &&
                !stepsConfig.inspection_plan?.isDisabled
              )
            ) {
              navigate(`/app/w/request-inspection-plan?vin=${vin}`);
            }
          },
          isDisabled: stepsConfig.inspection_plan?.isDisabled,

          messageCompleted:
            processStepsState.inspection_plan &&
            t(
              `components:service_process_card.inspection_plan.${processStepsState.inspection_plan}.tooltip`,
            ),
          messageOpen: t(`components:service_process_card.inspection_plan.open.tooltip`),
        }),
        unlockFeatureRef,
        needsIdsbAddon: !hasInspectionPlanAddOn,
        testId: 'inspection-plan-button',
        tourId: 'inspection-plan',
      }),
    },
    service_record: {
      subContent: tool({
        icon: (
          <ServiceRecordIcon
            fill={getCardColor(stepsConfig.service_record)}
            boxSize="10"
            alignSelf={'center'}
          />
        ),
        color: getCardColor(stepsConfig.service_record),
        label: t('components:service_process_card.service_record.label'),
        isLoading,
        ...stepsConfig.service_record,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.service_record,
          ...stepsConfig.service_record,
          handler: async () => {
            const { isCompleted } = stepsConfig.service_record;

            //NiceModals for Workshops Without VAT
            if (!workshopHasVat && accountStatusEqualsNotAvailable) {
              return NiceModal.show(AlertModal, {
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} as={IoIosWarning} boxSize="10" />
                    </Square>
                  ),
                  header: t(
                    'workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.title',
                  ),

                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                childrenTransI18n: (
                  <Trans
                    i18nKey={`workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.body`}
                    values={{
                      supportPhoneNumber: supportPhoneNumber,
                      supportEmail: supportEmail,
                    }}
                  />
                ),
              });
            }

            if (
              (!vehicleQuery.isFetching &&
                !vehicleQuery.data?.has_dsb &&
                !get(workshop, `subscription.active_addons`, {})[ADDON_KEYS.PLUS_DSB]) ||
              (!vehicleQuery.isFetching &&
                isMazdaAndCountryDe &&
                !get(workshop, `subscription.active_addons`, {})[ADDON_KEYS.PLUS_DSB])
            ) {
              NiceModal.show(modalData?.modalComponent, modalData);
              eventTrack('idsb_upsell');
              return null;
            }
            if (isCompleted) {
              if (hasCollapsableContent) {
                setIsContentVisible.on();
                await awaitAnimation();
              }
              // TODO: Get ID of Item somehow to get to proper selector as built in ServiceActivityTimeline
              //@ts-ignore
              document
                .querySelector(`service_record_${createdServiceRecordId}`)
                ?.scrollIntoView({
                  behavior: 'smooth',
                });
            } else {
              //if the workshop is NOT verified, show a modal which tells the user that he needs to verify his workshop first, before he can create service records
              if (!isWorkshopVerified) {
                NiceModal.show(AlertModal, {
                  onSubmit: () => navigate('/app/w/account/verification '),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: isWorkshopPendingVerification
                      ? t('components:verification_pending.header')
                      : t('components:verification_not_started.header'),
                    footer: {
                      buttons: {
                        hide: isWorkshopPendingVerification,
                        actionCaption: t('components:verification_not_started.cta'),
                      },
                    },
                  },
                  children: isWorkshopPendingVerification
                    ? t('components:verification_pending.text')
                    : t('components:verification_not_started.text'),
                });
              }
              //if the workshop is verified, the vehicle has a DSB, an account for the DSB account exists, but the portal can't be accessed yet, show a modal which tells the user that the account registration is still taking place
              else if (
                vehicleQuery.data?.has_dsb &&
                vehicleQuery.data?.account &&
                vehicleQuery.data?.account?.can_access_dsb === false &&
                workshopHasVat
              ) {
                NiceModal.show(AlertModal, {
                  accountRegistrationNotification: true,
                  notifyWhenReady: vehicleQuery.data?.account?.notify_when_ready,
                  accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                  accountRejected: accountRejected,
                  accountId: vehicleQuery.data?.account?.id,
                  onSubmit: () => navigate('/app/w/account-registration-status'),
                  content: {
                    icon: accountRejected ? (
                      <Icon as={IoIosWarning} color={'red'} boxSize="8" />
                    ) : (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: accountRejected
                      ? t('components:manufacturer_account_rejected.header')
                      : t('components:manufacturer_not_registered.header'),
                    footer: {
                      buttons: {
                        hide: true,
                        actionCaption: t('components:manufacturer_not_registered.cta'),
                      },
                    },
                  },
                  children: accountRejected ? (
                    <Box mt={5} mb={5}>
                      <Trans
                        i18nKey="service_record:account-registration-notification.error_message"
                        values={{ phoneNumber: supportPhoneNumber, email: supportEmail }}
                      />
                    </Box>
                  ) : (
                    t('components:manufacturer_not_registered.text')
                  ),
                });
                //if the workshop is verified, and the vehicle has no DSB, or it has a DSB which either doesn't require an account or the account is already active, show the user the SR form
              } else {
                navigate(`/app/w/service-record/create?vin=${vin}`);
              }
            }
          },
          defaultMessage: isNil(form)
            ? t('components:service_process_card.service_record.form_identifier.unknown')
            : undefined,
          isDisabled: isNil(form) || stepsConfig.service_record?.isDisabled,
          messageCompleted:
            processStepsState.service_record &&
            t(
              `components:service_process_card.service_record.${processStepsState.service_record}.tooltip`,
            ),
          messageOpen: t(`components:service_process_card.service_record.open.tooltip`),
          messageProcessing: t(
            `components:service_process_card.service_record.processing.tooltip`,
          ),
          messageInClarification: t(
            `components:service_process_card.service_record.clarification.tooltip`,
          ),
          messageDisabled: t(
            `components:service_process_card.service_record.requested_deletion.tooltip`,
          ),
        }),
        testId: 'service-record-button',
        tourId: 'service-record',
      }),
    },
    uploaded_documents: {
      subContent: tool({
        icon: (
          <Documents
            fill={getCardColor({ ...stepsConfig.uploaded_documents })}
            boxSize="10"
            alignSelf={'center'}
          />
        ),
        color: getCardColor({ ...stepsConfig.uploaded_documents }),
        label: t('components:service_process_card.uploaded_documents.label'),
        ...stepsConfig.uploaded_documents,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.uploaded_documents,
          ...stepsConfig.uploaded_documents,
          handler: async () => {
            setIsContentVisible.on();
            await awaitAnimation();
            //@ts-ignore
            document.getElementById(`documents-anchor-${service?.id}`)?.scrollIntoView({
              behavior: 'smooth',
            });
            setTimeout(
              async () =>
                await highlightInput(`.documents-anchor-${service?.id}`).catch((error) =>
                  console.log(error),
                ),
              200,
            );
          },
          messageCompleted:
            processStepsState.uploaded_documents &&
            t(
              `components:service_process_card.uploaded_documents.${processStepsState.uploaded_documents}.tooltip`,
            ),
          messageOpen: t(
            `components:service_process_card.uploaded_documents.open.tooltip`,
          ),
        }),
        isLoading,
        testId: 'upload-documents-button',
      }),
    },
    sent_documents: {
      subContent: tool({
        icon: (
          <Icon
            as={RiMailSendLine}
            fill={getCardColor({ ...stepsConfig.sent_documents })}
            boxSize="10"
            alignSelf={'center'}
          />
        ),
        color: getCardColor({ ...stepsConfig.sent_documents }),
        label: t('components:service_process_card.sent_documents.label'),
        ...stepsConfig.sent_documents,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.sent_documents,
          ...stepsConfig.sent_documents,
          handler: async () => {
            setIsContentVisible.on();
            await awaitAnimation();
            //@ts-ignore
            document.getElementById(`documents-anchor-${service?.id}`)?.scrollIntoView({
              behavior: 'smooth',
            });
          },
          messageCompleted:
            processStepsState.sent_documents &&
            t(
              `components:service_process_card.sent_documents.${processStepsState.sent_documents}.tooltip`,
            ),
          messageOpen: t(`components:service_process_card.sent_documents.open.tooltip`),
        }),
        isLoading,
        testId: 'send-documents-button',
      }),
    },
  };
  const stepContent = merge(stepsConfig, stepSubContentConfig);
  if (vehicleQuery.isLoading || accountsQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Card
      data-test-id="service-process-card"
      className={state != 'past' ? 'tour-process-card' : 'tour-completed-services'}
    >
      {isLoading ? (
        <CardHeaderSkeleton />
      ) : (
        <HStack justifyContent={'space-between'}>
          <HStack spacing="4" py={4} px={4}>
            <Box width={'full'} ml={2}>
              <Heading size="xs">
                {t(`components:service_process_card.${state}.header`, {
                  date: service?.created_at
                    ? dateTransformer(service?.created_at, timezone)
                    : undefined,

                  start_date: service?.created_at
                    ? dateTransformer(service?.created_at, timezone)
                    : undefined,

                  end_date: service?.created_at
                    ? dateTransformer(service?.completed_at, timezone)
                    : undefined,
                })}
              </Heading>

              <Text as="div" fontSize="sm">
                {!vin
                  ? t('components:service_process_card.new.description')
                  : t(`components:service_process_card.${state}.description`, {
                      date: service?.created_at
                        ? dateTransformer(service?.completed_at, timezone)
                        : undefined,
                    })}
              </Text>
            </Box>
          </HStack>

          {(!isEmpty(service) &&
            isNil(service?.completed_at) &&
            serviceRecordCompleted &&
            !serviceHistoryInProcess) ||
          (!isEmpty(service) && isNil(service?.completed_at) && serviceRecordDeleted) ||
          (!isEmpty(service) && isNil(service?.completed_at) && serviceRecordCanceled) ||
          (serviceRecordCompleted && serviceHistoryRecordCompleted) ? (
            <Stack>
              <Button
                disabled={serviceRecordInProcess || serviceHistoryInProcess}
                mr={2}
                variant="outline"
                data-test-id="complete-button"
                className={'service-process-card-complete-service'}
                onClick={() =>
                  NiceModal.show(AlertModal, {
                    onSubmit: () => {
                      completeServiceMutation.mutate();
                      return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                    },
                    children: t('components:service_process_card.complete.text'),
                    content: {
                      header: t('components:service_process_card.complete.title'),
                      footer: {
                        buttons: {
                          cancelCaption: t('common:cancel'),
                          actionCaption: t(
                            'components:service_process_card.complete.button',
                          ),
                        },
                      },
                    },
                  })
                }
              >
                {t('components:service_process_card.complete.button')}
              </Button>
            </Stack>
          ) : (!isEmpty(service) &&
              isNil(service?.completed_at) &&
              serviceRecordInProcess) ||
            serviceHistoryInProcess ? (
            <Box>
              <Tooltip
                placement={'left'}
                label={t('components:service_process_card.general_process_disabled')}
                shouldWrapChildren
              >
                <Button
                  disabled={serviceRecordInProcess || serviceHistoryInProcess}
                  color="error"
                  borderColor="error"
                  border={'1px'}
                  variant="variant"
                  _hover={{ bgColor: 'error', color: 'white' }}
                  mr={2}
                  data-test-id="complete-button"
                  className={'service-process-card-complete-service'}
                  onClick={() =>
                    NiceModal.show(AlertModal, {
                      onSubmit: () => completeServiceMutation.mutate(),
                      children: t('components:service_process_card.complete.text'),
                      content: {
                        header: t('components:service_process_card.complete.title'),
                        footer: {
                          buttons: {
                            cancelCaption: t('common:cancel'),
                            actionCaption: t(
                              'components:service_process_card.complete.button',
                            ),
                          },
                        },
                      },
                    })
                  }
                >
                  {' '}
                  {t('components:service_process_card.complete.button')}
                </Button>
              </Tooltip>
            </Box>
          ) : !isEmpty(service) &&
            isNil(service?.completed_at) &&
            serviceHistoryRecordCompleted &&
            !serviceRecordInProcess ? (
            <Box>
              <Button
                disabled={serviceRecordInProcess || serviceHistoryInProcess}
                color="error"
                borderColor="error"
                border={'1px'}
                variant="variant"
                _hover={{ bgColor: 'error', color: 'white' }}
                mr={2}
                data-test-id="complete-button"
                className={'service-process-card-complete-service'}
                onClick={() =>
                  NiceModal.show(AlertModal, {
                    onSubmit: () => completeServiceMutation.mutate(),
                    children: t('components:service_process_card.complete.text'),
                    content: {
                      header: t('components:service_process_card.complete.title'),
                      footer: {
                        buttons: {
                          cancelCaption: t('common:cancel'),
                          actionCaption: t(
                            'components:service_process_card.complete.button',
                          ),
                        },
                      },
                    },
                  })
                }
              >
                {' '}
                {t('components:service_process_card.complete.button')}
              </Button>
            </Box>
          ) : null}

          {hasCollapsableContent ? (
            <Button
              variant="ghost"
              onClick={setIsContentVisible.toggle}
              rightIcon={isContentVisible ? <FiChevronUp /> : <FiChevronDown />}
              mr={'2 !important'}
            >
              {isContentVisible ? t('common:less_details') : t('common:more_details')}
            </Button>
          ) : null}
        </HStack>
      )}
      <Stack direction={['row']} id={'tools'}>
        {Object.values(stepContent).map(
          (
            step: {
              isActive: boolean;
              isCompleted: boolean;
              isProcessing?: boolean;
              isInClarification?: boolean;
              isCanceled?: boolean;
              isDisabled?: boolean;
              isRequestedDeletion?: boolean;
            },
            key: number,
          ) => (
            <ServiceStep
              key={key}
              {...step}
              color={getCardColor(step)}
              isFirstStep={key === 0}
              isLastStep={Object.keys(stepContent).length === key + 1}
            />
          ),
        )}
      </Stack>

      <Stack direction={['column']} p={6}>
        {isLoading ? (
          <Skeleton height="10rem" />
        ) : !isEmpty(service) ? (
          <Collapse in={isContentVisible} animateOpacity>
            <Stack spacing={4}>
              <ServiceActivityTimeline
                make={make as string}
                className={'tour-process-card-timeline'}
                isLoading={isLoading}
                addons={addons}
                vin={vin}
                //@ts-ignore
                statusCodes={statusCodes}
                workshop={workshop}
                serviceActivities={serviceActivities}
                sendRequest={sendRequest}
                noActionsAllowed={state === SERVICE_STATE.COMPLETED}
                pl={20}
                t={t}
              />
              {[SERVICE_STATE.OPEN, SERVICE_STATE.PROCESSING].includes(state) ? null : (
                <Divider />
              )}
              {[SERVICE_STATE.OPEN, SERVICE_STATE.PROCESSING].includes(state) ? null : (
                <DocumentsSection
                  costControlDisabled={workshop?.disable_cost_control}
                  subscription={workshop?.subscription}
                  addons={addons}
                  anchorId={`documents-anchor-${service?.id}`}
                  isLoadingParent={isLoading}
                  documents={documents}
                  timezone={timezone}
                  sendRequest={(formData) => {
                    formData.append('subject_type', 'service');
                    //@ts-ignore
                    formData.append('subject_id', service?.id);

                    return uploadDocumentMutation.mutate(formData, {
                      onSuccess: () => {
                        return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                      },
                    });
                  }}
                  download={(document) => {
                    setUrl(`/document/${document.id}/download`);
                    setDownloadDocument(document.file);
                    setClick(true);
                  }}
                  remove={(documentId) =>
                    deleteDocumentMutation.mutate(documentId, {
                      onSuccess: () => {
                        return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                      },
                    })
                  }
                  sendFiles={(data: Array<number>) => sendDocumentsMutation.mutate(data)}
                />
              )}
            </Stack>
          </Collapse>
        ) : null}
      </Stack>
    </Card>
  );
};

export default ServiceProcessCard;
