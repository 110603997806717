import { Button, Center, Grid, Image, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';

const DownloadStep = (props: { sendRequest(): void }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const { sendRequest } = props;
  return (
    <Grid w="full" h="full" placeItems="center" p={4} gap={4}>
      <Stack p={4}>
        <Center>
          <Image src="/images/alex-thumbs-up.svg" alt="Error Gif" w={'70%'} h={'auto'} />
        </Center>

        <Text>{t('forms:ip.step_three.content.description')}</Text>
      </Stack>
      <Button
        data-test-id="download-inspection-plan"
        leftIcon={<MdArrowDownward />}
        colorScheme="blue"
        variant="solid"
        size="lg"
        onClick={sendRequest}
      >
        {t('common:download')}
      </Button>
    </Grid>
  );
};

export default DownloadStep;
