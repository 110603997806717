import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { ServiceRecord } from '../../../api/types';
import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function getServiceRecord(
  axios: AxiosInstance,
  id: number,
): Promise<ServiceRecord> {
  const { data } = await axios.get(`${config.apiBaseUrl}/service-record/` + id);
  return data;
}

export default function useServiceRecordQuery(id: number) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(getKey(id), () => getServiceRecord(axios, id), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        hideRealResponse: true,
        t,
      });
    },
  });
}

function getKey(id: number): QueryKey {
  return ['ServiceRecord', id];
}

useServiceRecordQuery.getKey = getKey;
