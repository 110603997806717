import { Button, Container, Flex, Icon, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { buildForm, buildValidationSchema } from 'helpers/general';
import { get, merge } from 'lodash';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { LooseObject, Vehicle } from '../../api/types';

export type Group = {
  icon: string;
  label: string;
  fields: Field[];
};
export type Field = {
  name: string;
  validations: any;
  params?: object;
  id: number;
  label: string;
  position: number;
  description: string;
  type: string;
  children: Field[];
  display: {
    deps: [];
    type: string;
  };
};
export type FormSchema = {
  id: number;
  version: string;
  slug: string;
  sections: Array<{
    id: number;
    groups: Group[];
  }>;
};

export const MakeSpecificDataStep = (props: {
  formSchema: FormSchema | undefined;
  backendErrors: object;
  lastSectionSeen: number;
  stepTwoMakeCheckboxData: object;
  // eslint-disable-next-line no-unused-vars
  saveInState: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  setLastSectionSeen: SetStateAction<any>;
  vehicle: Vehicle;
}) => {
  const { t } = useTranslation();
  const {
    vehicle,
    backendErrors,
    stepTwoMakeCheckboxData,
    setStep,
    saveInState,
    lastSectionSeen,
    formSchema,
    setLastSectionSeen,
  } = props;
  const [section, setSection] = useState(lastSectionSeen);
  const savedData = { ...stepTwoMakeCheckboxData };
  const [changeSection, setChangeSection] = useState<SetStateAction<any>>();
  const sections = formSchema?.sections || [];
  const totalSections = sections?.length;
  const schema = buildValidationSchema(get(sections?.[section], 'groups', []));

  const {
    handleSubmit,
    formState,
    setValue,
    watch,
    register,
    control,
    reset,
    setFocus,
    getFieldState,
  } = useForm({
    defaultValues: savedData,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const errors = merge(formState.errors, backendErrors);

  useEffect(() => {
    reset(section);
    setChangeSection(null);
  }, [section]);

  useEffect(() => {
    if (changeSection === 'next') {
      const nextSection = section + 1;
      if (nextSection >= totalSections) {
        setStep(2);
      } else {
        setLastSectionSeen(nextSection);
        setSection(nextSection);
      }
      setChangeSection(null);
    }
    if (changeSection === 'prev') {
      const nextSection = section - 1;
      if (nextSection < 0) {
        setLastSectionSeen(0);
        setStep(0);
      } else {
        setSection(nextSection);
      }
      setChangeSection(null);
    }
  }, [changeSection]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field: any, a: any) => {
      // @ts-ignore
      return errors[field] ? field : a;
    }, null);
    if (firstError) {
      try {
        // @ts-ignore
        setFocus(firstError);
      } catch (e) {
        console.log('missing ref for field ' + firstError);
      }
    }
  }, [errors, setFocus]);
  const onSubmit = (formData: LooseObject) => {
    saveInState(formData);
    setChangeSection('next');
  };

  return (
    <Container
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-test-id="service-record-make-specific-form"
      maxWidth={'unset'}
      m={0}
    >
      <Stack direction={['column']} w={{ base: 'full', lg: '50%' }} spacing={4}>
        {buildForm(sections?.[section], {
          control,
          register,
          errors,
          watch,
          vehicle,
          setValue,
          getFieldState,
          reset,
        })}
      </Stack>
      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <Button
          type="submit"
          variant="primary"
          // disabled={formState.submitCount > 0 && !isValid}
          // disabled={Object.keys(formState.errors).length > 0}
          data-test-id="service-record-make-specific-next-button"
        >
          {t('common:next')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
        <Button
          onClick={() => {
            setChangeSection('prev');
          }}
          variant="outline"
          mr={1}
          data-test-id="service-record-make-specific-back-button"
        >
          <Icon as={FiArrowLeft} boxSize="4" />
          {t('common:back')}
        </Button>
      </Flex>
    </Container>
  );
};
