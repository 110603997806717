import { Button, HStack, useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { merge } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { Methods } from '../../api/types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import PageHeading from '../../components/PageHeading';
import config from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import { downloadFile } from '../../helpers/downloadHelper';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import { useStep } from '../../helpers/useStep';
import useFileQuery from '../../hooks/queries/useFileQuery';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import { Stepper } from '../serviceRecord/Stepper';
import BasicDataStep from './BasicDataStep';
import DownloadStep from './DownloadStep';
import SelectInspectionStep from './SelectInspectionStep';

const InspectionPlanPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [currentStep, { setStep }] = useStep({ maxStep: 2, initialStep: 0 });
  const [wizardFormData, setWizardFormData] = useState({});
  const [dataForStepThree, setDataForStepThree] = useState({});
  const [dataForStepTwo, setDataForStepTwo] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const userContext = useUserContext();
  const [url, setUrl] = useState(undefined);
  const [downloadDocument, setDownloadDocument] = useState(undefined);
  const fileQuery = useFileQuery(url);
  const vehicleQuery = useVehicleQuery(vin);
  const [click, setClick] = useState(false);

  const axios = useAxios();

  useEffect(() => {
    setClick((prev) => {
      if (!prev) {
        downloadFile(downloadDocument, fileQuery.data);
      }
      return !prev;
    });

    setClick(false);
  }, [fileQuery.data, click]);

  const sendRequest = async ({
    url,
    method = 'put',
    dataForEndpoint = {},
    params = {},
  }: {
    url: string;
    method?: Methods;
    dataForEndpoint?: object;
    params?: object;
  }) => {
    const axiosCall =
      method !== 'get'
        ? axios[method](`${config.apiBaseUrl}/${url}`, dataForEndpoint)
        : axios[method](`${config.apiBaseUrl}/${url}`, { params });
    return await axiosCall.catch((err: { response: { status: number } }) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
      return Promise.reject(err);
    });
  };

  const steps = [
    {
      title: 1,
      stepHeader: t('forms:ip.step_one.header'),
      stepDescription: t('forms:ip.step_one.description'),
      content: (
        <BasicDataStep
          setStep={setStep}
          timezone={userContext.user?.timezone || 'Europe/Vienna'}
          saveInState={(stateData) => setWizardFormData(merge(wizardFormData, stateData))}
          data={wizardFormData}
          setDataForStepTwo={setDataForStepTwo}
          // @ts-ignore
          vehicle={vehicleQuery.data || {}}
          sendRequestVehicle={(data) =>
            sendRequest({
              url: `vehicle/${vehicleQuery.data?.id}`,
              dataForEndpoint: data,
            })
          }
          sendRequestInspectionPlan={(data: {
            mileage: number;
            initial_registration_date: string;
          }) =>
            sendRequest({
              url: `vehicle/${vehicleQuery.data?.id}/inspections`,
              method: 'get',
              params: {
                mileage: data?.mileage,
                initial_registration_date: data?.initial_registration_date,
              },
            })
          }
        />
      ),
    },
    {
      title: 2,
      stepHeader: t('forms:ip.step_two.header'),
      stepDescription: t('forms:ip.step_two.description'),
      content: (
        <SelectInspectionStep
          setDataForStepThree={setDataForStepThree}
          sendRequest={(data) =>
            sendRequest({
              method: 'post',
              url: 'inspection-plan',
              dataForEndpoint: data,
            })
          }
          setStep={setStep}
          saveInState={(stateData) => setWizardFormData(merge(wizardFormData, stateData))}
          data={{ ...wizardFormData, vehicle_id: vehicleQuery.data?.id }}
          displayData={dataForStepTwo}
        />
      ),
    },
    {
      title: 3,
      stepHeader: t('forms:ip.step_three.header'),
      stepDescription: t('forms:ip.step_three.description'),

      content: (
        <DownloadStep
          sendRequest={() => {
            // @ts-ignore
            setUrl(`/inspection-plan/${dataForStepThree?.id}/download`);
            // @ts-ignore
            setDownloadDocument(dataForStepThree?.file);
            setClick(true);
          }}
        />
      ),
    },
  ];
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (vehicleQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags title={t('forms:ip.header')} />

      <HStack justify="space-between">
        <PageHeading title={t('forms:ip.header')} />
        <Button
          p={2}
          onClick={() => navigate(`/app/w/vehicle?vin=${vin}`)}
          variant={'outline'}
          data-test-id="vehicle-details-back-button"
          flexShrink={0}
        >
          {t('pages:service_record.detail.back')}
        </Button>
      </HStack>
      <Stepper
        isLoading={vehicleQuery.isFetching}
        steps={steps}
        currentStep={currentStep}
        content={steps[currentStep].content}
      />
    </>
  );
};

export default InspectionPlanPage;
