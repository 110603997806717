import { get } from 'lodash';

type Status =
  | 'created'
  | 'submitted'
  | 'processing'
  | 'completed'
  | 'clarification'
  | 'canceled'
  | 'deleted'
  | 'requested_deletion';
type StatusColors =
  | 'gray'
  | 'green'
  | 'white'
  | 'orange'
  | 'blue'
  | 'accent'
  | 'black'
  | 'red'
  | 'yellow';

const statusColorsMapping: Record<
  Status | 'default',
  {
    base: StatusColors;
    light: string;
    medium: string;
    dark: string;
    text: string;
  }
> = {
  created: {
    base: 'blue',
    light: 'blue.100',
    medium: 'blue.200',
    dark: 'blue.500',
    text: 'white',
  },

  submitted: {
    base: 'black',
    light: 'black.100',
    medium: 'black.200',
    dark: 'black.900',
    text: 'white',
  },

  clarification: {
    base: 'orange',
    light: 'orange.100',
    medium: 'orange.200',
    dark: 'orange.500',
    text: 'white',
  },

  canceled: {
    base: 'red',
    light: 'red.100',
    medium: 'red.200',
    dark: 'red.500',
    text: 'white',
  },

  completed: {
    base: 'green',
    light: 'green.100',
    medium: 'green.200',
    dark: 'green.500',
    text: 'white',
  },

  processing: {
    base: 'black',
    light: 'black.100',
    medium: 'black.200',
    dark: 'black.900',
    text: 'white',
  },

  deleted: {
    base: 'red',
    light: 'red.100',
    medium: 'red.200',
    dark: 'red.500',
    text: 'white',
  },

  requested_deletion: {
    base: 'black',
    light: 'black.100',
    medium: 'black.200',
    dark: 'black.900',
    text: 'white',
  },

  default: {
    base: 'gray',
    light: 'gray.100',
    medium: 'gray.200',
    dark: 'gray.500',
    text: 'white',
  },
};

export default (status: string) =>
  // @ts-ignore
  get(statusColorsMapping, `${status}.base`, statusColorsMapping.default.base);

export const getLightStatusColor = (status: string) =>
  // @ts-ignore
  get(statusColorsMapping, `${status}.light`, statusColorsMapping.default.light);

export const getMediumStatusColor = (status: string) =>
  // @ts-ignore
  get(statusColorsMapping, `${status}.medium`, statusColorsMapping.default.medium);

export const getDarkStatusColor = (status: string) =>
  // @ts-ignore
  get(statusColorsMapping, `${status}.dark`, statusColorsMapping.default.dark);

export const getTextStatusColor = (status: string) =>
  // @ts-ignore
  get(statusColorsMapping, `${status}.text`, statusColorsMapping.default.text);
