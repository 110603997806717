import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Divider, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { AlertButton } from 'components/buttons/AlertButton';
import { Skeleton } from 'components/Skeleton';
import { get, isNil } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoHourglassSharp } from 'react-icons/all';
import { FaShippingFast } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  CHARGE_KEYS,
  ServiceActivity,
  ServiceRecord,
  StatusCodes,
} from '../../../../api/types';
import { ServiceRecordIcon } from '../../../../components/Icons';
import AlertModal from '../../../../components/modals/AlertModal';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { StatusReason } from '../../../../components/StatusReason';
import { StatusTag } from '../../../../components/StatusTag';
import {
  STATUS_COMPLETED,
  STATUS_NEEDS_CLARIFICATION,
} from '../../../../context/UserContextProvider';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import { downloadFile } from '../../../../helpers/downloadHelper';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import { getStatus } from '../../../../helpers/general';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useDeleteServiceRecordMutation from '../../../../hooks/private/mutations/useDeleteServiceRecordMutation';
import useUpdateServiceRecordToFastlaneMutation from '../../../../hooks/private/mutations/useUpdateServiceRecordToFastlaneMutation';
import useFileQuery from '../../../../hooks/queries/useFileQuery';
import useVehicleQuery from '../../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import CancelModal from '../CancelModal';

export const ServiceRecordActivityCard = (props: {
  vin: string;
  activity: ServiceActivity;
  noActionsAllowed: boolean;
  statusCodes: StatusCodes;
  isLoading: boolean;
  workshop: WorkshopData | undefined;
  qrContent: any;
}) => {
  const { vin, activity, statusCodes, qrContent, isLoading, workshop, noActionsAllowed } =
    props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [url, setUrl] = useState<SetStateAction<any>>(undefined);
  const fileQuery = useFileQuery(url);
  const [downloadDocument, setDownloadDocument] =
    useState<SetStateAction<any>>(undefined);
  const [click, setClick] = useState(false);
  const serviceRecord: ServiceRecord = activity?.service_record;
  const updateServiceRecordToFastlaneMutation = useUpdateServiceRecordToFastlaneMutation(
    serviceRecord?.id,
  );
  const queryClient = useQueryClient();
  useEffect(() => {
    downloadFile(downloadDocument, fileQuery.data);
    setClick(false);
  }, [fileQuery.data, click]);

  const deleteServiceRecordMutation = useDeleteServiceRecordMutation(serviceRecord?.id);
  const currency = workshop?.currency;
  const status = activity?.service_record?.status;
  const handleDownload = () => {
    setUrl(`/service-record/${serviceRecord?.id}/download`);
    setDownloadDocument(serviceRecord.file);
    setClick(true);
  };

  const units = workshop?.units;
  const isCompleted = status === STATUS_COMPLETED;
  const isDeleted = status === 850;
  const isRequestedDeletion = status === 800;
  const needsClarification = status === STATUS_NEEDS_CLARIFICATION;
  const codes = get(statusCodes, 'service_record');

  const showCancel =
    !isNil(status) &&
    [getStatus(codes, 'created'), getStatus(codes, 'submitted')].includes(status);

  const handleCancel = (): any => {
    deleteServiceRecordMutation.mutate(undefined, {
      onSuccess: () => {
        navigate('/app/w/vehicle?vin=' + vin);
      },
    });
  };

  const handleShowDetails = () => {
    navigate('/app/w/service-record/' + serviceRecord.id);
  };
  const isPayPerUse = workshop?.subscription?.plan?.is_pay_per_use;
  const getCharge = () => {
    return parseInt(
      // @ts-ignore
      get(workshop?.subscription?.plan?.charges, `${CHARGE_KEYS.FASTLANE}.price`, 0),
      10,
    );
  };
  const statusForMessage = get(codes, status);

  return (
    <>
      {isLoading ? (
        <Skeleton height="200px" />
      ) : (
        <BaseServiceTypeTimelineElement
          id={`service_record_${serviceRecord.id}`}
          dataTestId={'service_record_timeline_element'}
          date={dateTimeTransformer(activity.date)}
          title={`${t('components:service_process_card.service_record.label')}, ${
            serviceRecord?.user_readable_id
          }`}
          statusReason={
            <StatusReason
              statusCodes={codes}
              reasons={statusCodes['service_record_reasons']}
              resourceKey={'service_record'}
              currentStatus={serviceRecord?.status}
              currentStatusReason={serviceRecord?.status_reason}
            />
          }
          status={
            <StatusTag
              statusCodes={codes}
              reasons={statusCodes['service_record_reasons']}
              hideDescription
              resourceKey={'service_record'}
              currentStatus={serviceRecord?.status}
            />
          }
          iconBgColor={
            needsClarification
              ? 'orange'
              : getColourSchemeBasedOnStatus(get(codes, status))
          }
          iconClassName={needsClarification ? 'active' : ''}
          icon={
            <Icon
              as={
                needsClarification
                  ? IoIosWarning
                  : isRequestedDeletion
                    ? IoHourglassSharp
                    : isDeleted
                      ? DeleteIcon
                      : ServiceRecordIcon
              }
            />
          }
          badge={
            serviceRecord?.fastlane ? (
              <Tooltip
                label={t(
                  'components:service_process_card.service_record.fastlane.tooltip',
                )}
                className={'tour-process-card-service-record-fastlane'}
                shouldWrapChildren
              >
                <Icon as={FaShippingFast} boxSize={10} color={'orange.500'} />
              </Tooltip>
            ) : !isPayPerUse && showCancel ? (
              <>
                <Tooltip
                  label={t(
                    'components:service_process_card.service_record.fastlane.upsell.tooltip',
                  )}
                  shouldWrapChildren
                >
                  <Icon
                    as={FaShippingFast}
                    onClick={() =>
                      NiceModal.show(AlertModal, {
                        onSubmit: () => {
                          updateServiceRecordToFastlaneMutation.mutate();
                          return queryClient.invalidateQueries(
                            useVehicleQuery.getKey(vin),
                          );
                        },
                        content: {
                          header: t(
                            'components:service_process_card.service_record.fastlane.upsell.modal.header',
                          ),
                          footer: {
                            buttons: {
                              cancelCaption: t('common:cancel'),
                              actionCaption: t('common:update'),
                            },
                          },
                        },
                        children: (
                          <>
                            {' '}
                            <Text>
                              {t(
                                'components:service_process_card.service_record.fastlane.upsell.modal.description',
                              )}
                            </Text>
                            <Text>
                              {`${t('forms:fastlane.label')} (${formatMultiCurrencyIntl(
                                getCharge(),
                                currency,
                              )} ${t('pages:subscription.extra_charge')})`}
                            </Text>
                          </>
                        ),
                      })
                    }
                    boxSize={10}
                  />
                </Tooltip>
              </>
            ) : (
              ''
            )
          }
        >
          <Text fontSize="sm">
            <strong>{t('forms:mileage.label')}:</strong>{' '}
            {`${serviceRecord?.mileage} ${units === 'metric' ? 'km' : 'mi'}`}
          </Text>

          <Text as="div" fontSize="sm">
            {statusForMessage
              ? t(`status:service_record.${statusForMessage}.timeline.message`)
              : null}
          </Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }} gap={4}>
            {isCompleted && (
              <Button
                leftIcon={<MdArrowDownward />}
                variant="primary"
                ml={2}
                data-test-id={`service_record_${serviceRecord?.id}_timeline_download_button`}
                onClick={() => handleDownload()}
              >
                {t('common:download')}
              </Button>
            )}
            {showCancel && !noActionsAllowed && !isPayPerUse && (
              <CancelModal handleCancel={handleCancel} />
            )}
            {needsClarification ? (
              <AlertButton
                onClick={() => handleShowDetails()}
                data-test-id={`service_record_${serviceRecord?.id}_timeline_details_button`}
              >
                {t('common:details')}
              </AlertButton>
            ) : (
              <Button
                variant={'outline'}
                color={'accent'}
                bgColor={'primary'}
                className={'tour-process-card-service-record-details'}
                data-test-id={`service_record_${serviceRecord?.id}_timeline_details_button`}
                onClick={() => handleShowDetails()}
              >
                {t('common:details')}
              </Button>
            )}
          </Flex>
          {isCompleted ? <Divider borderColor="grey" /> : null}
          {isCompleted ? qrContent : null}
        </BaseServiceTypeTimelineElement>
      )}
    </>
  );
};
