import { Button, Container, Flex, Grid, Heading } from '@chakra-ui/react';
import GetInTouchCard from 'components/GetInTouchCard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { Promotion } from '../../../../api/types';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import { PromotionBanner } from '../../../../components/PromotionBanner';
import { useUserContext } from '../../../../context/UserContextProvider';
import useAddonsQuery from '../../../../hooks/queries/useAddonsQuery';
import usePromotionsQuery from '../../../../hooks/queries/usePromotionsQuery';
import UpdateAvailableAddons from '../update/UpdateAvailableAddons';

export const GetAddonsPage: React.FC = () => {
  const userContext = useUserContext();
  const currency = userContext.workshop?.currency;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addonsQuery = useAddonsQuery();
  const promotionsQuery = usePromotionsQuery();

  if (addonsQuery.isLoading) {
    return <LoadingSpinner />;
  } else if (addonsQuery.data?.length === 0) {
    navigate('/app/setup/verification');
  }

  return (
    <Container data-test-id="addons-card">
      <Heading size={{ base: 'sm', xl: 'md' }} py={14} textAlign="center">
        {t('addons:get_addons.title')}
      </Heading>

      {promotionsQuery.data?.map((promotion: Promotion, index: number) => {
        if (promotion?.location?.includes('addon_page')) {
          return <PromotionBanner key={index} promotion={promotion} />;
        }
        return null;
      })}
      <Grid gap={{ base: 6, lg: 10 }} paddingTop={8}>
        <UpdateAvailableAddons
          currency={currency}
          addons={addonsQuery.data}
          //@ts-ignore
          subscription={userContext.workshop?.subscription}
          isLoading={addonsQuery.isFetching}
          costControlDisabled={userContext.workshop?.disable_cost_control}
        />
      </Grid>
      <Flex direction="row-reverse" py="16" px={{ base: '4', md: '6' }} mr={8}>
        <Button
          as={NavLink}
          to={'/app/setup/verification'}
          variant="outline"
          type={'button'}
          data-test-id="addons-skip-button"
        >
          {t('common:skip')}
        </Button>
      </Flex>
      <GetInTouchCard />
    </Container>
  );
};

export default GetAddonsPage;
