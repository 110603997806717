import { DownloadIcon } from '@chakra-ui/icons';
import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { get } from 'lodash';
import * as React from 'react';

import { FileObject, PartialStateCode } from '../api/types';
import {
  getDarkStatusColor,
  getTextStatusColor,
} from '../helpers/getColourSchemeBasedOnStatus';

export const ConversationBubble = (props: {
  message: string;
  author: string;
  timestamp?: string;
  isOwnMessage: boolean;
  statusCodes: PartialStateCode<number>;
  currentStatus?: number;
  setFile?: () => void;
  file?: FileObject;
  index: number;
}) => {
  const {
    message,
    author,
    timestamp,
    isOwnMessage,
    statusCodes,
    index,
    file = {
      original_name: undefined,
    },
    currentStatus = 999,
    setFile,
    ...rest
  } = props;

  return (
    <Stack spacing={3}>
      <Stack
        textAlign={isOwnMessage ? 'left' : 'right'}
        sx={isOwnMessage ? { marginLeft: 2 } : { marginRight: 2 }}
      >
        <Text color="muted" fontSize="xs" m={0}>
          {author} <br />
          {timestamp}
        </Text>
      </Stack>
      <Stack
        spacing={6}
        h="100%"
        rounded="2xl"
        shadow="sm"
        py={{ base: '4', md: '4' }}
        px={{ base: '4', md: '4' }}
        bg={
          isOwnMessage
            ? 'gray.100'
            : getDarkStatusColor(get(statusCodes, currentStatus, 'unknown'))
        }
        color={
          isOwnMessage
            ? 'default'
            : getTextStatusColor(get(statusCodes, currentStatus, 'unknown'))
        }
        {...rest}
      >
        <HStack justifyContent={message === null ? 'flex-end' : 'space-between'}>
          {message?.split('<br />').map((line, index) => (
            <Text fontSize="xs" whiteSpace={'pre-wrap'} key={index}>
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            </Text>
          ))}
          {file?.original_name !== undefined && index !== 0 ? (
            <HStack>
              <Text>{file?.original_name}</Text>
              <IconButton
                onClick={setFile}
                cursor="pointer"
                icon={<DownloadIcon />}
                boxSize="4"
                aria-label="download"
              />
            </HStack>
          ) : null}
        </HStack>
      </Stack>
    </Stack>
  );
};
