import { Icon, Stack, Tag } from '@chakra-ui/react';
import { useIsWorkshopBlocked } from 'helpers/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCar } from 'react-icons/ai';
import { Navigate } from 'react-router-dom';

import { Accounts } from '../../api/types';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { DataTable } from '../../components/Table/DataTable';
import useAccountsQuery from '../../hooks/queries/useAccountsQuery';
import { ContentCard } from '../../layout/ContentCard';

interface AccountData {
  make_name: string;
  account_id: number;
  account_status: string;
  can_access_dsb: boolean;
}

export function transformManufacturerAccounts(accounts: Accounts) {
  const transformedAccountsData = accounts.reduce(
    (account: {}[], current: any) => {
      current.makes.map((make: any) => {
        account.push({
          account_id: current.id,
          account: {
            status: current.status,
            can_access_dsb: current.can_access_dsb,
          },
          make_name: make?.name,
          estimate: current.estimate,
        });
      });
      return account;
    },
    [] as {}[],
  );
  transformedAccountsData;

  return transformedAccountsData;
}
export function sortTransformedManufactureAccounts(transformedAccountsData: any) {
  return transformedAccountsData.sort(function (a: AccountData, b: AccountData) {
    if (a.make_name < b.make_name) {
      return -1;
    }
    if (a.make_name > b.make_name) {
      return 1;
    }
    return 0;
  });
}

const AccountRegistrationStatusPage = () => {
  const { t } = useTranslation();
  const accountsQuery = useAccountsQuery();
  const accounts = accountsQuery.data || [];
  const transformedAccounts = transformManufacturerAccounts(accounts);
  const sortedAccounts = sortTransformedManufactureAccounts(transformedAccounts);

  const enum ACCOUNTSTATUSES {
    NOT_REGISTERED = 0,
    NOT_AVAILABLE = 50,
    REGISTRATION_PENDING = 100,
    REGISTRATION_ON_HOLD = 150,
    REGISTERED = 200,
    BLOCKED = 700,
    REGISTRATION_REJECTED = 800,
    DELETED = 900,
  }

  const enum ACCOUNTSTATUSCOLORS {
    NOT_REGISTERED = 0,
    NOT_AVAILABLE = 50,
    REGISTRATION_PENDING = 100,
    REGISTRATION_ON_HOLD = 150,
    REGISTERED = 200,
    BLOCKED = 700,
    REGISTRATION_REJECTED = 800,
    DELETED = 900,
  }
  const enum ACCOUNTESTIMATIONS {
    NEXT_BUSINESS_DAY = 'next_business_day',
    TODAY = 'today',
    VAT_REQUIRED = 'vat_required',
    THIS_WEEK = 'this_week',
    NEXT_WEEK = 'next_week',
    WITHIN_TWO_WEEKS = 'within_next_two_weeks',
    WITHIN_THREE_WEEKS = 'within_next_three_weeks',
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('components:makes_status.makes'),
        accessor: 'make_name',
        width: '1000px',
      },
      {
        Header: t('components:makes_status.status'),
        accessor: 'account',
        width: '1200px',
        // @ts-ignore
        Cell: (cellProps: { row: any }) => {
          const { row } = cellProps;
          const accountStatusTranKey =
            row.original.account.can_access_dsb === true
              ? 'accounts:status.completed.label'
              : row.original.account.status === ACCOUNTSTATUSES.NOT_REGISTERED
                ? 'accounts:status.not_registered.label'
                : row.original.account.status === ACCOUNTSTATUSES.NOT_AVAILABLE
                  ? 'accounts:status.not_available.label'
                  : row.original.account.status === ACCOUNTSTATUSES.REGISTRATION_PENDING
                    ? 'accounts:status.pending.label'
                    : row.original.account.status === ACCOUNTSTATUSES.REGISTRATION_ON_HOLD
                      ? 'accounts:status.registration_on_hold.label'
                      : row.original.account.status === ACCOUNTSTATUSES.REGISTERED
                        ? 'accounts:status.registered.label'
                        : row.original.account.status === ACCOUNTSTATUSES.BLOCKED
                          ? 'accounts:status.blocked.label'
                          : row.original.account.status ===
                              ACCOUNTSTATUSES.REGISTRATION_REJECTED
                            ? 'accounts:status.rejected.label'
                            : row.original.account.status === ACCOUNTSTATUSES.DELETED
                              ? 'accounts:status.deleted.label'
                              : '';

          if (accountsQuery.isLoading) {
            return <LoadingSpinner />;
          }
          return (
            <Tag
              variant={'solid'}
              size={'md'}
              padding={'8px'}
              pr={'30px'}
              pl={'30px'}
              // @ts-ignore
              bgColor={
                row.original.account.can_access_dsb === true
                  ? 'green.500'
                  : row.original.account.status === ACCOUNTSTATUSCOLORS.NOT_REGISTERED
                    ? 'gray'
                    : row.original.account.status === ACCOUNTSTATUSCOLORS.NOT_AVAILABLE
                      ? 'orange'
                      : row.original.account.status ===
                            ACCOUNTSTATUSCOLORS.REGISTRATION_PENDING ||
                          row.original.account.status ===
                            ACCOUNTSTATUSCOLORS.REGISTRATION_ON_HOLD
                        ? 'black'
                        : row.original.account.status === ACCOUNTSTATUSCOLORS.REGISTERED
                          ? 'green.500'
                          : row.original.account.status === ACCOUNTSTATUSCOLORS.BLOCKED ||
                              row.original.account.status ===
                                ACCOUNTSTATUSCOLORS.REGISTRATION_REJECTED ||
                              row.original.account.status === ACCOUNTSTATUSCOLORS.DELETED
                            ? 'red'
                            : null
              }
            >
              {t(accountStatusTranKey).toUpperCase()}
            </Tag>
          );
        },
      },
      {
        Header: t('accounts:expected_registration.label'),
        accessor: 'estimate',

        Cell: (cellProps: { row: any }) => {
          const { row } = cellProps;
          let duration;

          if (row.original.estimate === ACCOUNTESTIMATIONS.NEXT_BUSINESS_DAY) {
            duration = t('accounts:expected_registration.next_business_day');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.VAT_REQUIRED) {
            duration = t('accounts:expected_registration.vat_required');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.TODAY) {
            duration = t('accounts:expected_registration.today');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.THIS_WEEK) {
            duration = t('accounts:expected_registration.end_of_this_week');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.NEXT_WEEK) {
            duration = t('accounts:expected_registration.end_of_the_week');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.WITHIN_TWO_WEEKS) {
            duration = t('accounts:expected_registration.within_next_two_weeks');
          } else if (row.original.estimate === ACCOUNTESTIMATIONS.WITHIN_THREE_WEEKS) {
            duration = t('accounts:expected_registration.within_next_three_weeks');
          } else {
            duration = null;
          }

          return <Tag variant={'primary'}>{duration}</Tag>;
        },
      },
    ],
    [sortedAccounts],
  );

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (accountsQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <ContentCard
      icon={<Icon as={AiFillCar} boxSize={12} />}
      minH="fit-content"
      header={t('components:account_registration_status.title')}
      contentDescription={t('components:account_registration_status.description')}
      data-test-id="account-registration-status"
      className={'account-registration-status-card'}
      width={'full'}
    >
      <Stack direction={['column', 'row']} w={'100%'}>
        <Stack direction="column" w={'100%'}>
          <DataTable
            data-test-id="latest-vehicles-table"
            hasFilters={false}
            columns={columns}
            data={transformedAccounts}
            isMoreThenOnePage={false}
          />
        </Stack>
      </Stack>
    </ContentCard>
  );
};
export default AccountRegistrationStatusPage;
