import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { simpleRenderValue } from 'helpers/general';
import { omitBy } from 'lodash';
import React, { SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { LooseObject, PartialStateCode, ServiceRecord } from '../../api/types';
import AlertModal from '../../components/modals/AlertModal';
import { useUserContext } from '../../context/UserContextProvider';
import { simpleCheckBox, textareaInput } from '../../helpers/makeFormFields';
import transformer from '../../helpers/transformer';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import { BasicFormKeys } from './BasicDataStep';
import { FormSchema } from './MakeSpecificDataStep';
import ServiceRecordData from './ServiceRecordData';

export interface IReviewDataForm {
  consent_data_and_charge: boolean;
  additional_notes: string;
}

export const ReviewFormKeys = ['additional_notes', 'consent_data_and_charge'];
export const ReviewDataStep = (props: {
  formSchema: FormSchema | undefined;
  additionalNotes: SetStateAction<any>;
  setAdditionalNotes: SetStateAction<any>;
  setStepOneFormData: SetStateAction<any>;
  stepOneFormData: Partial<ServiceRecord>;
  stepTwoMakeCheckboxData: LooseObject;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStep: (step: number) => void;
  formVersionId: number;
}) => {
  const {
    additionalNotes,
    setAdditionalNotes,
    stepOneFormData,
    setStepOneFormData,
    stepTwoMakeCheckboxData,
    formSchema,
    setStep,
    onSubmit,
  } = props;

  const { data: vehicle } = useVehicleQuery(stepOneFormData?.vin);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const userContext = useUserContext();
  const workshop = userContext.workshop;
  const timezone = userContext.user?.timezone || 'Europe/Vienna';
  const country = workshop?.country;
  const units = workshop?.units;
  const language = workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;

  const manufacturerSupportsDsb = vehicle?.has_dsb;

  const schema = yup
    .object({
      additional_notes: yup.string().nullable(),
      consent_data_and_charge: yup
        .boolean()
        .required()
        .oneOf([true], t('common:required')),
    })
    .required();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<IReviewDataForm>({
    defaultValues: {
      consent_data_and_charge: false,
      additional_notes: additionalNotes,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const generalDataFields = transformer(stepOneFormData, BasicFormKeys);

  const recordData: PartialStateCode<string> =
    omitBy(stepTwoMakeCheckboxData, (value) => value === null || value === undefined) ||
    {};

  return (
    <>
      <Container
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        maxWidth={'unset'}
        data-test-id="service-record-review-form"
        m={0}
      >
        <Stack direction={['column']}>
          <Stack>
            {Object.keys(generalDataFields).map((key: string) => {
              return (
                <HStack key={`${key}`}>
                  <Text fontWeight="bold">
                    {t(`forms:${key}.label`) || t(`forms:data.${key}.label`)}:
                  </Text>
                  <Text fontSize="sm" textAlign="left">
                    {key === 'mileage'
                      ? `${generalDataFields[key].toLocaleString(localeParams)} ${
                          units === 'metric' ? 'km' : 'mi'
                        }`
                      : simpleRenderValue(generalDataFields[key], timezone)}
                  </Text>
                </HStack>
              );
            })}
          </Stack>
          <Divider />

          <Grid gap={{ base: 4, lg: 6 }} paddingBlock={2}>
            <ServiceRecordData
              record={recordData}
              schema={formSchema}
              timezone={timezone}
            />
          </Grid>

          {manufacturerSupportsDsb ? (
            <Text fontSize={14}>
              {t(
                `service_record:confirmation_comment.${vehicle?.manufacturer?.make?.slug}.info`,
              )}
            </Text>
          ) : (
            <Text fontSize={14}>
              {t(`service_record:confirmation_comment.idsb.info`)}
            </Text>
          )}

          {textareaInput({
            value: additionalNotes,
            name: 'additional_notes',
            onChange: (e: string) => {
              setAdditionalNotes(e.target.value);
            },
            register,
            errors,
            schema: schema,
          })}
          {simpleCheckBox({
            name: 'consent_data_and_charge',
            label: t('forms:consent_data_and_charge.label'),
            register,
            errors,
            schema,
          })}
        </Stack>
        <Flex
          direction={{ base: 'column', lg: 'row-reverse' }}
          py="4"
          px={{ base: '4', md: '6' }}
        >
          <Button
            type="submit"
            variant="primary"
            data-test-id="service-record-submit-button"
            mb={{ base: 4, lg: 0 }}
          >
            {t('common:proceed')}
            <Icon as={FiArrowRight} boxSize="4" />
          </Button>
          <Button
            onClick={() => {
              setStep(1);
              setStepOneFormData({
                ...stepOneFormData,
                additional_notes: additionalNotes,
              });
            }}
            variant="outline"
            data-test-id="service-record-review-back-button"
            mb={{ base: 4, lg: 0 }}
            mr={{ base: 0, lg: 2 }}
          >
            <Icon as={FiArrowLeft} boxSize="4" />
            {t('common:back')}
          </Button>
          <Button
            onClick={() =>
              NiceModal.show(AlertModal, {
                onSubmit: () => navigate(`/app/w/vehicle/?vin=${stepOneFormData?.vin}`),
                content: {
                  header: t('pages:service_record.modals.abort.title'),
                  footer: {
                    buttons: {
                      cancelCaption: t('pages:service_record.modals.abort.cancel'),
                      actionCaption: t('pages:service_record.modals.abort.cta'),
                    },
                  },
                },
                children: t('pages:service_record.modals.abort.text'),
              })
            }
            color="error"
            borderColor="error"
            variant="outline"
            _hover={{
              bg: 'error',
              color: 'white',
            }}
            _active={{
              bg: 'error',
              color: 'white',
            }}
            mr="auto"
            data-test-id="service-record-review-abort-button"
          >
            {t('common:abort')}
          </Button>
        </Flex>
      </Container>
    </>
  );
};
