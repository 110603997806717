import {
  CloseButton,
  Grid,
  Heading,
  Icon,
  SlideFade,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { AlexAroundCorner } from '../../components/illustrations';
import config from '../../config';
import { useAxios } from '../../context/AxiosContextProvider';
import { useUserContext } from '../../context/UserContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import useWorkshopUserQuery from '../../hooks/queries/workshop/useWorkshopUserQuery';

const WelcomeCard = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const userContext = useUserContext();

  const toast = useToast();
  const queryClient = useQueryClient();
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const { mutate, isLoading: isUserLoading } = useMutation(async () => {
    const updateData = { ...userContext.user, show_welcome_message: false };

    await axios
      .put(`${config.apiBaseUrl}/workshop-user`, updateData)
      .catch((err) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err);
      })
      .then(() => {
        queryClient.invalidateQueries(useWorkshopUserQuery.getKey());
      });
  });

  const alexDimensions = AlexAroundCorner.getAspectRatio();
  const ILLU_SIZE = 10;

  if (!userContext.user?.show_welcome_message) {
    return <></>;
  }

  return isUserLoading ? (
    <Skeleton height="200px" width="200px" />
  ) : (
    <Stack
      direction={'column'}
      w={'100%'}
      bgColor={'gray.200'}
      paddingBlock={{ base: 2, lg: 4 }}
      paddingInline={{ base: 2, lg: 6 }}
      borderRadius="lg"
      pos="relative"
      data-test-id="welcome-card"
      overflow={'hidden'}
      className={'tour-welcome-card'}
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', xl: 'auto 1fr' }}
        gap={{ base: 4, xl: 8 }}
        alignItems={'center'}
        paddingBlock={4}
      >
        {isDesktop ? (
          <SlideFade in={true} offsetX="-10%">
            <Icon
              as={AlexAroundCorner}
              ml={{ base: -2, lg: -6 }}
              w={`${ILLU_SIZE}rem`}
              h={`${ILLU_SIZE * alexDimensions.getRatio()}rem`}
            />
          </SlideFade>
        ) : null}

        <Grid gap={4}>
          <CloseButton onClick={() => mutate()} position="absolute" right="2" top="2" />
          <Heading size="xs" fontWeight="bold">
            {t('workshop:welcome.section.heading')}
          </Heading>
          <Text color="muted" fontSize="md">
            {t('workshop:welcome.section.description')}
          </Text>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default WelcomeCard;
