import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Tour from '../../components/Tour';

const StarterTour = (props: {
  shouldStartTour: boolean | undefined;
  onComplete: () => void;
  onExit: () => void;
}) => {
  const { t } = useTranslation();
  const steps = [
    {
      element: '.tour-welcome-card',
      intro: t('tours:general.steps.welcome_card.text'),
      position: 'right',
    },
    {
      element: '.tour-vehicle-card',
      intro: t('tours:general.steps.vehicle_card.text'),
      position: 'right',
    },
    {
      element: '.tour-latest-vehicles-card',
      intro: t('tours:general.steps.latest_vehicles_card.text'),
    },
    {
      element: '.tour-service-activity',
      intro: t('tours:general.steps.service_activity.text'),
    },
    {
      element: '.tour-manage-subscription',
      intro: t('tours:general.steps.manage_subscription.text'),
    },
    {
      element: '.tour-support',
      intro: t('tours:general.steps.support.text'),
    },
  ];
  return (
    <Tour
      onExit={props.onExit}
      steps={steps}
      shouldStartTour={props.shouldStartTour}
      onComplete={props.onComplete}
    />
  );
};

export default StarterTour;
