import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import cuid from 'cuid';
import { compact, find, get, isNil, omitBy, values } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getI18n } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { FileObject } from '../../api/types';

export interface InspectionPlan {
  id: string;
  maintenance_system_id: number;
  maintenance_period_ids: string;
  mileage: number;
  maintenance_data_strings: string;
  file: FileObject;
}

export interface InspectionType {
  id: string;
  name: string;
  periods: Array<Period>;
}

export interface Period {
  id: string;
  name: string;
  combinable: boolean;
  applicable: boolean;
}

export interface ISelectInspectionForm {
  maintenance_system_id: number;
  maintenance_period_ids_combinable: Array<string>;
  maintenance_period_ids_not_combinable: string;
  vehicle_id: number;
}

const getCombinable = (maintenanceSystem: InspectionType) => {
  return maintenanceSystem.periods.filter((obj) => obj.combinable) || [];
};

const getNotCombinable = (maintenanceSystem: InspectionType) => {
  return maintenanceSystem.periods.filter((obj) => !obj.combinable) || [];
};

const schema = yup
  .object()
  .shape(
    {
      maintenance_system_id: yup
        .number()
        .label(getI18n().t('forms:maintenance_system_id.label'))
        .typeError(getI18n().t('forms:maintenance_system_id.type_error'))
        .required(),
      vehicle_id: yup.number().required(),
      maintenance_period_ids_combinable: yup
        .array()
        .label(getI18n().t('forms:maintenance_period_ids.label'))
        .when('maintenance_period_ids_not_combinable', {
          is: (not_combinable: any) => !not_combinable || not_combinable.length === 0,
          then: yup.array().required(),
          otherwise: yup.array().nullable(),
        }),
      maintenance_period_ids_not_combinable: yup
        .string()
        .label(getI18n().t('forms:maintenance_period_ids.label'))
        .when('maintenance_period_ids_combinable', {
          is: (combinable: any) => !combinable || combinable.length === 0,
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
    },
    [['maintenance_period_ids_combinable', 'maintenance_period_ids_not_combinable']],
  )
  .required();

const SelectInspectionStep = (props: {
  saveInState(x: any): void;
  setStep(y: number): void;
  sendRequest(z: object): void;
  setDataForStepThree(k: object): void;
  data: object;
  displayData: Array<InspectionType>;
}) => {
  const { t } = useTranslation();
  const {
    saveInState,
    sendRequest,
    setStep,
    setDataForStepThree,
    data,
    displayData = [],
  } = props;
  const processedData = values(
    omitBy(
      displayData.map((system) => {
        const combinable = getCombinable(system);
        const notCombinable = getNotCombinable(system);
        if (combinable.length !== 0 || notCombinable.length !== 0) {
          return {
            ...system,
            periods: { combinable: combinable, notCombinable: notCombinable },
          };
        }
        return null;
      }),
      isNil,
    ),
  );

  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, submitCount },
    setError,
  } = useForm<ISelectInspectionForm>({
    defaultValues: {
      ...data,
      maintenance_system_id: parseInt(processedData[0]?.id),
      maintenance_period_ids_not_combinable: '',
      maintenance_period_ids_combinable: [],
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const currentMaintenanceSystem = watch('maintenance_system_id');
  const currentMaintenanceSystemPeriods = // @ts-ignore
    find(processedData, { id: parseInt(currentMaintenanceSystem) })?.periods || {};

  useEffect(() => {
    reset({
      ...data,
      maintenance_system_id: currentMaintenanceSystem || parseInt(processedData[0]?.id),
      maintenance_period_ids_not_combinable:
        find(currentMaintenanceSystemPeriods.notCombinable, { suggested: true })?.id ||
        currentMaintenanceSystemPeriods.notCombinable[0]?.id,
      maintenance_period_ids_combinable:
        [find(currentMaintenanceSystemPeriods.combinable, { suggested: true })?.id] || [],
    });
  }, [currentMaintenanceSystem]);

  const { mutate, isLoading } = useMutation<any, Error, ISelectInspectionForm>(
    async (data) => {
      const combined = get(data, 'maintenance_period_ids_combinable', []);
      const notCombined = get(data, 'maintenance_period_ids_not_combinable', []);
      //@ts-ignore
      const totalPeriodsSelected = compact(combined.concat(notCombined));
      //@ts-ignore
      let periods = [];
      totalPeriodsSelected.forEach((period) => {
        periods.push({
          [period]: {
            name: displayData //@ts-ignore
              .find((c) => parseInt(c.id) === parseInt(data?.maintenance_system_id)) //@ts-ignore
              ?.periods.find((o) => o.id === parseInt(period))?.name,
          },
        });
      });

      return sendRequest({
        vehicle_id: data?.vehicle_id,
        //@ts-ignore
        mileage: data?.mileage,
        maintenance_system_id: data?.maintenance_system_id,
        maintenance_period_ids: totalPeriodsSelected.join(),
        maintenance_data_strings: JSON.stringify({
          [data?.maintenance_system_id]: {
            name: processedData.find((c) => c.id === data?.maintenance_system_id)?.name,
            //@ts-ignore
            periods: periods,
          },
        }),
      });
    },
  );
  const onSubmit: SubmitHandler<ISelectInspectionForm> = (formData) => {
    mutate(formData, {
      onSuccess: ({ data }) => {
        setDataForStepThree(data);
        saveInState({ ...formData });
        setStep(2);
      },
      onError: (error: any) => {
        const { errors } = error.response.data;
        (Object.keys(errors) as Array<keyof ISelectInspectionForm>).forEach((key) => {
          setError(key, {
            type: 'server',
            message: errors[key]!.join('. '),
          });
        });
      },
    });
  };

  return (
    <Container
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-test-id="inspection-select-form"
      maxWidth={'unset'}
      m={0}
    >
      <Stack>
        {processedData.length > 1 && (
          <Controller
            name={'maintenance_system_id'}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                as={Stack}
                paddingBlock={4}
                spacing={3}
                onChange={onChange}
                value={value.toString()}
              >
                {processedData.map((inspectionPlan, index: number) => {
                  return (
                    <Radio size="xl" key={index} value={inspectionPlan?.id.toString()}>
                      <Text
                        key={inspectionPlan.id}
                        data-test-id={'inspection_' + inspectionPlan.id}
                        color="emphasized"
                        fontWeight="medium"
                        fontSize="sm"
                        as={'div'}
                      >
                        {inspectionPlan?.name}
                      </Text>
                    </Radio>
                  );
                })}
              </RadioGroup>
            )}
          />
        )}

        <Text fontSize="sm" color={'error'}>
          {/*@ts-ignore*/}
          {get(errors, 'maintenance_system_id', {})?.message}
        </Text>
        {processedData.length > 1 && <Divider />}
        <HStack alignItems={'start'} spacing={4}>
          {currentMaintenanceSystemPeriods.notCombinable.length > 0 && (
            <Box paddingBlock={4}>
              <Heading size={'xxs'}>
                {t('forms:ip.step_two.not_combinable.label')}
              </Heading>
              <Text mb={4}>{t('forms:ip.step_two.not_combinable.description')}</Text>
              <Text fontSize="sm" color={'error'}>
                {/*@ts-ignore*/}
                {get(errors, 'maintenance_period_ids_not_combinable', {})?.message}
              </Text>
              <Controller
                name={'maintenance_period_ids_not_combinable'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    w={'full'}
                    maxW={'40rem'}
                    value={value?.toString()}
                    data-test-id="select-not-combinable"
                    onChange={onChange}
                  >
                    {get(currentMaintenanceSystemPeriods, 'notCombinable', []).map(
                      (option: Period, optionIndex: number) => (
                        <option
                          key={optionIndex}
                          data-test-id={`not-combinable-${option?.id}`}
                          value={option?.id?.toString()}
                        >
                          {option?.name}
                        </option>
                      ),
                    )}
                  </Select>
                )}
              />
            </Box>
          )}

          {currentMaintenanceSystemPeriods.combinable.length > 0 && (
            <Box paddingBlock={4}>
              <Heading size={'xxs'}>{t('forms:ip.step_two.combinable.label')}</Heading>
              <Text mb={4}>{t('forms:ip.step_two.combinable.description')}</Text>
              <Text fontSize="sm" color={'error'}>
                {/*@ts-ignore*/}
                {get(errors, 'maintenance_period_ids_combinable', {})?.message}
              </Text>
              <Controller
                name={'maintenance_period_ids_combinable'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CheckboxGroup onChange={onChange} value={value}>
                    <Stack paddingBlock={4} spacing={3}>
                      {get(currentMaintenanceSystemPeriods, 'combinable', []).map(
                        (option: Period) => {
                          const checkboxId = cuid();
                          return (
                            <Checkbox
                              size={'xl'}
                              key={checkboxId}
                              data-test-id={`combinable-${option?.id}`}
                              value={`${option?.id}`}
                            >
                              <Text color="emphasized" fontWeight="medium" fontSize="sm">
                                {option?.name}
                              </Text>
                            </Checkbox>
                          );
                        },
                      )}
                    </Stack>
                  </CheckboxGroup>
                )}
              />
            </Box>
          )}
        </HStack>
      </Stack>

      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <Button
          type="submit"
          variant="primary"
          data-test-id="inspection-select-submit-button"
          disabled={(submitCount! > 0 && !isValid) || isLoading}
        >
          {t('common:next')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
      </Flex>
    </Container>
  );
};

export default SelectInspectionStep;
