import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import { get, isNil } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';

import {
  PartialStateCode,
  ServiceActivity,
  ServiceHistory,
  StatusCodes,
} from '../../../../api/types';
import { ServiceHistoryRequestIcon } from '../../../../components/Icons';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { StatusReason } from '../../../../components/StatusReason';
import { StatusTag } from '../../../../components/StatusTag';
import { STATUS_COMPLETED } from '../../../../context/UserContextProvider';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import { downloadFile } from '../../../../helpers/downloadHelper';
import { getStatus } from '../../../../helpers/general';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useDeleteServiceHistoryRequestMutation from '../../../../hooks/private/mutations/useDeleteServiceHistoryRequestMutation';
import useFileQuery from '../../../../hooks/queries/useFileQuery';
import useVehicleQuery from '../../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import CancelModal from '../CancelModal';

export const ServiceHistoryRequestActivityCard = (props: {
  vin: string;
  activity: ServiceActivity;
  noActionsAllowed: boolean;
  statusCodes: StatusCodes;
  isLoading: boolean;
  workshop: WorkshopData | undefined;
}) => {
  const { activity, statusCodes, isLoading, workshop, vin, noActionsAllowed } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [url, setUrl] = useState<SetStateAction<any>>(undefined);
  const fileQuery = useFileQuery(url);
  const [downloadDocument, setDownloadDocument] =
    useState<SetStateAction<any>>(undefined);
  const [click, setClick] = useState(false);

  const serviceHistoryRequest: ServiceHistory = activity?.service_history_request;
  const status = serviceHistoryRequest?.status;
  const deleteServiceHistoryRequestMutation = useDeleteServiceHistoryRequestMutation(
    serviceHistoryRequest?.id,
  );
  useEffect(() => {
    downloadFile(downloadDocument, fileQuery.data);
    setClick(false);
  }, [fileQuery.data, click]);
  const handleDownload = () => {
    setUrl(`/service-history-request/${serviceHistoryRequest?.id}/download`);
    setDownloadDocument(serviceHistoryRequest.file);
    setClick(true);
  };

  const handleCancel = () => {
    deleteServiceHistoryRequestMutation.mutate();
    queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
  };

  const isPayPerUse = workshop?.subscription?.plan?.is_pay_per_use;
  const codes: PartialStateCode<string> = get(statusCodes, 'service_history_request');
  const reasons: PartialStateCode<string> = get(
    statusCodes,
    'service_history_request_reasons',
    {},
  );

  const showCancel =
    !isNil(status) &&
    [getStatus(codes, 'created'), getStatus(codes, 'submitted')].includes(status);

  const statusForMessage = get(codes, serviceHistoryRequest?.status);
  return (
    <>
      {isLoading ? (
        <Skeleton height="200px" />
      ) : (
        <BaseServiceTypeTimelineElement
          id={`service_history_request_${serviceHistoryRequest.id}`}
          dataTestId={'service_history_request_timeline_element'}
          date={dateTimeTransformer(activity?.date)}
          title={`${t(
            'components:service_process_card.service_history_request.label',
          )}, ${serviceHistoryRequest?.user_readable_id}`}
          statusReason={
            <StatusReason
              statusCodes={codes}
              reasons={statusCodes['service_history_request_reasons']}
              resourceKey={'service_history_request'}
              currentStatus={serviceHistoryRequest?.status}
              currentStatusReason={serviceHistoryRequest?.status_reason}
            />
          }
          status={
            <StatusTag
              statusCodes={codes}
              reasons={reasons}
              resourceKey={'service_history_request'}
              currentStatus={serviceHistoryRequest?.status}
              hideDescription={true}
            />
          }
          iconBgColor={getColourSchemeBasedOnStatus(get(codes, status))}
          icon={<Icon as={ServiceHistoryRequestIcon} />}
        >
          <Text fontSize="sm">
            {statusForMessage
              ? t(`status:service_history_request.${statusForMessage}.timeline.message`)
              : null}
          </Text>
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }} gap={4}>
            {status === STATUS_COMPLETED && (
              <Button
                leftIcon={<MdArrowDownward />}
                variant="primary"
                size="sm"
                ml={2}
                data-test-id={`service_history_request_${serviceHistoryRequest?.id}_timeline_download_button`}
                onClick={() => handleDownload()}
              >
                {t('common:download')}
              </Button>
            )}
            {showCancel && !noActionsAllowed && !isPayPerUse && (
              <CancelModal activity={activity} handleCancel={handleCancel} />
            )}
          </Flex>
        </BaseServiceTypeTimelineElement>
      )}
    </>
  );
};
