import { Box, Grid, Stack } from '@chakra-ui/react';
import { get, map, property, uniq } from 'lodash';
import * as React from 'react';
import { Trans } from 'react-i18next';

import { BILLING_CYCLE, SubscriptionAddon } from '../../../../api/types';
import { Subscription } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { Addon } from '../addon/Addon';

export function UpdateAvailableAddons(props: {
  subscription?: Subscription;
  currency: string | undefined;
  isLoading: boolean;
  costControlDisabled?: boolean;
  addons: Array<SubscriptionAddon>;
}) {
  const { addons = [], subscription, currency, costControlDisabled } = props;
  const billingCycle = subscription?.billing_cycle || BILLING_CYCLE.MONTHLY;
  const activeAddons = get(subscription, 'active_addons', {});
  const areTrialAddonActive = uniq(map(activeAddons, property('trial_ends'))).length > 0;

  return (
    <>
      <Stack
        direction={'column'}
        spacing={8}
        height={'full'}
        data-test-id="subscription-addons"
      >
        <Grid
          templateColumns={{
            base: '1fr',
            lg: 'repeat(auto-fit, minmax(10rem, 15rem))',
          }}
          gap={14}
          py={6}
          justifyContent={'center'}
        >
          {addons.map((addon: SubscriptionAddon, index) => (
            <Box width={280} key={`${addon.id}-${index}`}>
              <Addon
                currency={currency}
                addon={addon}
                billingCycle={billingCycle}
                costControlDisabled={costControlDisabled}
                subscription={subscription}
                addonActiveData={get(activeAddons, addon?.id, {})}
              />
            </Box>
          ))}
        </Grid>
        {areTrialAddonActive ? (
          <Box>
            <Trans i18nKey={`addons:trial.warning`} />
          </Box>
        ) : null}
      </Stack>
    </>
  );
}

export default UpdateAvailableAddons;
