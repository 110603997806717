import { AxiosInstance } from 'axios';
import config from 'config';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';

async function update(
  axios: AxiosInstance,
  serviceId: number,
  documents: {},
): Promise<object> {
  const { data } = await axios.post(
    `${config.apiBaseUrl}/service/${serviceId}/send-documents`,
    documents,
  );
  return data;
}

export default function useSendDocumentsMutation(serviceId: number) {
  const axios = useAxios();
  return useMutation((documents: {}) => update(axios, serviceId, documents));
}
