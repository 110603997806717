import { Box, Center, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import ModalVideo from 'components/ModalVideo';
import { Card } from 'layout/Card';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import WelcomeVideoImageFallback from '../../../assets/vimeo-previews/welcome-existing.jpeg';
import WelcomeVideoImage from '../../../assets/vimeo-previews/welcome-existing.webp';
import WelcomeExistingCustomerForm from './WelcomeExistingCustomerForm';

export const WelcomeExistingCustomerPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PublicPageLayout>
      <PageMetatags title={t('workshop:welcome.existing.meta.title')}>
        <script src="https://js.chargebee.com/v2/chargebee.js"></script>
      </PageMetatags>
      <Grid w="full" h="full" placeItems="center">
        <Card p={{ base: 2, lg: 10 }} textAlign="left">
          <Stack spacing={3}>
            <Heading as="h2" size="md" p={2} textAlign="center">
              {t('workshop:welcome.existing.header')}
            </Heading>
            <Text color="muted" fontSize="sm" p={2}>
              {t('workshop:welcome.existing.introduction')}
            </Text>
            <Center>
              <Box
                maxWidth={{ base: 'full', lg: '66%' }}
                paddingBlock={{ base: 4, lg: 8 }}
              >
                <ModalVideo
                  videoId={'744900017'}
                  placeholder={WelcomeVideoImage}
                  fallback={WelcomeVideoImageFallback}
                />
              </Box>
            </Center>
            <Text color="muted" fontSize="sm" p={2} whiteSpace={'pre-line'}>
              {t('workshop:welcome.existing.notification')}
            </Text>
          </Stack>
          <WelcomeExistingCustomerForm />
        </Card>
      </Grid>
    </PublicPageLayout>
  );
};
// @ts-ignore
export default WelcomeExistingCustomerPage;
