import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input as InputComponent,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import cuid from 'cuid';
import {
  Calendar,
  DateObj,
  GetBackForwardPropsOptions,
  RenderProps,
  useDayzed,
} from 'dayzed';
import { isEmpty, isNil, map, merge } from 'lodash';
import React, { Fragment, ReactNode, SetStateAction, useRef, useState } from 'react';

import { dateTransformerNoTimezone } from '../helpers/dateTransformer';

export const MONTH_NAMES_DEFAULT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const DAY_NAMES_DEFAULT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const DATE_FORMAT_DEFAULT = 'yyyy-MM-dd HH:mm:ss';

interface SingleDatepickerBackButtonsProps {
  calendars: Calendar[];
  // eslint-disable-next-line no-unused-vars
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

interface SingleDatepickerForwardButtonsProps {
  calendars: Calendar[];
  // eslint-disable-next-line no-unused-vars
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export interface SingleDatepickerProps {
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onDateChange: SetStateAction<any>;
  id?: string;
  name?: string;
  date?: Date;
  maxDate?: Date;
  minDate?: Date;
  configs?: Partial<SingleDatepickerConfigs>;
  placeholder?: string;
}

export interface SingleDatepickerConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames?: string[];
}

const transformDisplayDate = (value: any) => {
  const dateMatches =
    value
      .toString()
      .match(
        /^((0[1-9]|[12][012345678]|19)\.(0[1-9]|1[012])|29\.(0[13-9]|1[012])|30\.(0[13-9]|1[012])|31\.(0[13578]|1[02]))\.(19|20)\d\d|29\.02\.(19|20)([02468][048]|[13579][26])$/gm,
      ) ?? [];
  if (dateMatches.length > 0) {
    const chunks = value.split('.');
    if (chunks[2].length > 4) {
      return false;
    } else {
      return new Date(chunks[2], chunks[1] - 1, chunks[0], 12, 0, 0);
    }
  }
  return false;
};

const SingleDatepickerBackButtons = (props: SingleDatepickerBackButtonsProps) => {
  const { calendars, getBackProps } = props;
  return (
    <Fragment>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'<<'}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        {'<'}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerForwardButtons = (props: SingleDatepickerForwardButtonsProps) => {
  const { calendars, getForwardProps } = props;
  return (
    <Fragment>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        {'>'}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {'>>'}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerCalendar = (
  props: RenderProps & { configs: SingleDatepickerConfigs },
) => {
  const { calendars, getDateProps, getBackProps, getForwardProps, configs } = props;

  if (isEmpty(calendars)) {
    return null;
  }

  return (
    <HStack className="datepicker-calendar">
      {map(calendars, (calendar) => {
        return (
          <VStack key={`${calendar.month}${calendar.year}`}>
            <HStack>
              <SingleDatepickerBackButtons
                calendars={calendars}
                getBackProps={getBackProps}
              />
              <Heading size="sm" textAlign="center">
                {configs?.monthNames[calendar.month]} {calendar.year}
              </Heading>
              <SingleDatepickerForwardButtons
                calendars={calendars}
                getForwardProps={getForwardProps}
              />
            </HStack>
            <Divider />
            <SimpleGrid columns={7} spacing={2} textAlign="center">
              {map(configs.dayNames, (day) => (
                <Box key={`${calendar.month}${calendar.year}${day}`}>
                  <Text fontSize="sm" fontWeight="semibold">
                    {day}
                  </Text>
                </Box>
              ))}
              {map(calendar.weeks, (week, weekIndex) => {
                return map(week, (dateObj: DateObj, index) => {
                  const {
                    date,
                    today,
                    // prevMonth,
                    // nextMonth,
                    selected,
                  } = dateObj;
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                  const propsForButton = {
                    ...dateObj,
                    date: new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate(),
                      new Date().getHours(),
                      new Date().getMinutes(),
                      new Date().getSeconds(),
                    ),
                  };
                  return (
                    <Button
                      {...getDateProps({ dateObj: propsForButton })}
                      key={key}
                      size="sm"
                      variant="outline"
                      borderColor={today ? 'purple.400' : 'transparent'}
                      bg={selected ? 'purple.200' : undefined}
                    >
                      {date.getDate()}
                    </Button>
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </HStack>
  );
};
const defaultConfigs = {
  dateFormat: DATE_FORMAT_DEFAULT,
  monthNames: MONTH_NAMES_DEFAULT,
  dayNames: DAY_NAMES_DEFAULT,
};

export const SingleDatepicker: React.FC<SingleDatepickerProps> = ({
  configs = defaultConfigs,
  ...props
}) => {
  const { placeholder, date, minDate, maxDate, name, disabled, onDateChange, id } = props;
  //so that you only have to pass some of the config parameters
  const dateConfigs = merge(defaultConfigs, configs);
  const ref = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const icon: ReactNode = <CalendarIcon fontSize="sm" />;

  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const onDateSelected = (options: { selectable?: boolean; date: Date | false }) => {
    const { selectable, date } = options;
    if (!selectable) return;
    if (!isNil(date)) {
      onDateChange(date);
      setPopoverOpen(false);
      return;
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: date,
    maxDate: maxDate,
    minDate: minDate,
  });

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup>
          <InputComponent
            id={id}
            key={'date-' + cuid()}
            autoComplete="off"
            isDisabled={disabled}
            ref={initialFocusRef}
            onClick={() => setPopoverOpen(!popoverOpen)}
            name={name}
            placeholder={placeholder}
            value={
              date ? dateTransformerNoTimezone(date, dateConfigs.dateFormat) : undefined
            }
            onChange={(e) => {
              const value = transformDisplayDate(e.target.value);
              onDateSelected({
                selectable: true,

                date: value,
              });
            }}
          />
          <InputRightElement
            color="gray.500"
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            {icon}
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent ref={ref}>
        <PopoverBody padding={'10px 5px'} borderWidth={1} borderColor="accent">
          <SingleDatepickerCalendar {...dayzedData} configs={dateConfigs} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const SingleDateInput: React.FC<SingleDatepickerProps> = ({
  configs = defaultConfigs,
  ...props
}) => {
  const { placeholder, date, minDate, maxDate, name, disabled, onDateChange, id } = props;
  //so that you only have to pass some of the config parameters
  const dateConfigs = merge(defaultConfigs, configs);
  const initialFocusRef = useRef<HTMLInputElement>(null);

  const onDateSelected = (options: { selectable?: boolean; date: Date | false }) => {
    const { date } = options;
    if (!isNil(date)) {
      onDateChange(date);
      return;
    }
  };
  return (
    <InputComponent
      id={id}
      autoComplete="off"
      isDisabled={disabled}
      ref={initialFocusRef}
      name={name}
      placeholder={placeholder}
      _placeholder={{ opacity: 0.5 }}
      max={maxDate ? dateTransformerNoTimezone(maxDate, DATE_FORMAT_DEFAULT) : undefined}
      min={minDate ? dateTransformerNoTimezone(minDate, DATE_FORMAT_DEFAULT) : undefined}
      value={date ? dateTransformerNoTimezone(date, dateConfigs.dateFormat) : undefined}
      onChange={(e) => {
        const value = transformDisplayDate(e.target.value);
        onDateSelected({
          date: value,
        });
      }}
    />
  );
};
