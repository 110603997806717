import 'intro.js/introjs.css';
import './custom.css';

import { Step, Steps } from 'intro.js-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Tour = (props: {
  shouldStartTour: boolean | undefined;
  onComplete: () => void;
  onExit: () => void;
  steps: Array<Step>;
}) => {
  const { t } = useTranslation();
  const { steps, shouldStartTour, onComplete } = props;
  const [enabled, setEnabled] = useState(shouldStartTour);
  const tourConfig = {
    doneLabel: t('tours:done'),
    nextLabel: t('tours:next'),
    prevLabel: t('tours:prev'),
    scrollToElement: true,
    hidePrev: true,
    hideNext: false,
    showBullets: false,
    exitOnOverlayClick: false,
    exitOnEsc: false,
    disableInteraction: true,
  };
  useEffect(() => {
    setEnabled(shouldStartTour);
  }, [shouldStartTour]);
  const [counter, setCounter] = useState(0);
  return (
    <>
      <Steps
        enabled={enabled}
        steps={steps}
        initialStep={0}
        options={tourConfig}
        //preventing onExit being executed on the initial render
        onExit={() => {
          setEnabled(false);
          onComplete();
        }}
        //onBeforeExit can be used to show hint for support before exiting
        //onStart
        //onChange when step index is changed (onBeforeChange,onAfterChange,onPreventChange if onBeforeChange returns false)
        onComplete={() => {
          setEnabled(false);
          onComplete();
        }}
        //options https://github.com/HiDeoo/intro.js-react#introjs-options
      />
    </>
  );
};

export default Tour;
