import {
  Box,
  Center,
  Grid,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import PriceInCard from 'components/PriceInCard';
import { get, isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/all';
import { IoIosWarning } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import {
  ActivatedAddon,
  ADDON_ICONS,
  ADDON_KEYS,
  LooseObject,
  SubscriptionAddon,
} from '../../api/types';
import { FONT_WEIGHTS } from '../../global/Fonts';
import { eventTrack } from '../../helpers/analytics';
import { formatMultiCurrency } from '../../helpers/formatCurrency';
import {
  Subscription,
  WorkshopData,
} from '../../hooks/queries/workshop/useWorkshopQuery';
import { CornerRibbon } from '../CornerRibbon';
import AlertModal from './AlertModal';
import PollingConsentModal from './PollingConsentModal';

export interface IAddonAlertModal {
  isMazdaAndCountryDe: boolean;
  vin?: string | undefined;
  ppuFastlane?: boolean;
  onSuccess?: () => void;
  currency?: string | undefined;
  costControlDisabled?: boolean;
  onClose?: () => void;
  activeAddons?: object;
  activeAddon: ActivatedAddon | LooseObject;
  addon?: SubscriptionAddon;
  subscription: Subscription;
  isTrial?: boolean;
  displaySpecific?: boolean;
}
export const GetAddonModalData = (props: IAddonAlertModal) => {
  const {
    isMazdaAndCountryDe,
    ppuFastlane,
    onSuccess,
    onClose,
    addon,
    currency,
    activeAddons,
    activeAddon,
    subscription,
    displaySpecific = false,
    costControlDisabled = false,
  } = props;
  const isPayPerUse = subscription?.plan?.is_pay_per_use;
  const addonId = addon?.id;

  const price = addon?.price[subscription?.billing_cycle];
  const isTrialAvailable = addon?.trial_available;
  const hasNoOePlusDsB = isNil(activeAddons?.oe_plus_dsb);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (costControlDisabled) {
    return {
      modalComponent: AlertModal,
      content: {
        header: t('addons:unavailable.header'),
        footer: { buttons: { hide: true } },
      },
      children: t('addons:unavailable.text'),
    };
  }
  if (isPayPerUse && !ppuFastlane) {
    const shouldDisplaySpecificText = addonId === ADDON_KEYS.PLUS_DSB && displaySpecific;
    return {
      preventRedirectionOnSubmit: true,
      modalComponent: AlertModal,
      onSubmit: () => {
        return navigate('/app/w/account/subscription/plan');
      },
      onCancel: shouldDisplaySpecificText ? () => navigate('/app/w/support') : undefined,
      onClose,
      content: {
        header: isMazdaAndCountryDe
          ? t('service_record:not_supported.mazda_title')
          : t(`plans:upgrade.header.${shouldDisplaySpecificText ? 'idsb' : 'default'}`),
        footer: {
          buttons: {
            cancelCaption: shouldDisplaySpecificText
              ? t(`plans:upgrade.cancel.idsb`)
              : t('common:cancel'),
            actionCaption: t(
              `plans:upgrade.action.${shouldDisplaySpecificText ? 'idsb' : 'default'}`,
            ),
          },
        },
      },
      children: (
        <Grid gap={6} w={'full'}>
          {isMazdaAndCountryDe && (
            <HStack>
              <Icon as={IoIosWarning} color={'orange'} boxSize="10" />
              <Text>{t('service_record:not_supported.mazda_oe_plus_dsb')}</Text>
            </HStack>
          )}
          <Text textAlign={'center'} whiteSpace={'pre-wrap'}>
            {shouldDisplaySpecificText
              ? t(`plans:upgrade.description.idsb`)
              : t(`plans:upgrade.description.default`)}
          </Text>

          <Center>
            <Image
              src="/images/alex-computer.svg"
              alt="Alex the mechanic in front of a computer"
              w={'30%'}
              h={'auto'}
            />
          </Center>
        </Grid>
      ),
    };
  }
  if (isPayPerUse && ppuFastlane) {
    return {
      modalComponent: AlertModal,
      onSubmit: () => navigate('/app/w/account/subscription/plan'),
      onClose,
      content: {
        header: isMazdaAndCountryDe
          ? t('service_record:not_supported.mazda_title')
          : t(`plans:upgrade.header.default`),
        footer: {
          buttons: {
            cancelCaption: t(`common:cancel`),
            actionCaption: t(`plans:upgrade.action.default`),
          },
        },
      },
      children: (
        <Grid gap={6} w={'full'}>
          {isMazdaAndCountryDe && (
            <HStack>
              <Icon as={IoIosWarning} color={'orange'} boxSize="10" />
              <Text>{t('service_record:not_supported.mazda_oe_plus_dsb')}</Text>
            </HStack>
          )}
          <Text textAlign={'center'} whiteSpace={'pre-wrap'}>
            {t(`plans:upgrade.ppu_fastlane.top_description`)}
          </Text>
          <Center>
            <Image
              src="/images/alex-computer.svg"
              alt="Alex the mechanic in front of a computer"
              w={'30%'}
              h={'auto'}
            />
          </Center>
          <Text textAlign={'center'} whiteSpace={'pre-wrap'}>
            {t(`plans:upgrade.ppu_fastlane.bottom_description`)}
          </Text>
        </Grid>
      ),
    };
  }

  let perksArray = ['one', 'two', 'three'];

  if (addonId === ADDON_KEYS.INSPECTION_PLANS_ADDON) {
    perksArray.splice(1);
  }
  if (addonId === ADDON_KEYS.OE_DOCS) {
    perksArray.splice(2);
  }

  const isOnPromotion = !isEmpty(price?.discount);

  return {
    modalComponent: PollingConsentModal,
    url: 'subscription/addon/activate',
    data: {
      addon_id: addonId,
      trial: isTrialAvailable,
    },
    eventTrackingString: addonId + '_upsell',
    onClose,
    onSubmit: () => {
      eventTrack(addonId + '_completed');
      if (onSuccess) {
        onSuccess();
      }
    },
    pollingCondition: (data: WorkshopData) => {
      const activeAddons = get(data?.subscription, 'active_addons', {});
      return get(activeAddons, addonId, false);
    },
    pollingCompleted: () => window.location.reload(),
    children: (
      <Grid gap={6} p={4}>
        {isOnPromotion && !isTrialAvailable ? (
          <CornerRibbon>{t('promotions:general.label')}</CornerRibbon>
        ) : null}

        {isMazdaAndCountryDe && hasNoOePlusDsB && (
          <HStack>
            <Icon as={IoIosWarning} color={'orange'} boxSize="10" />
            <Text>{t('service_record:not_supported.mazda_oe_plus_dsb')}</Text>
          </HStack>
        )}

        <Center>
          <Heading size={'md'} color={'accent'}>
            {t(`addons:${addonId}.name`, addon?.name)}
          </Heading>
        </Center>
        <Center>
          <Text>{t(`addons:${addonId}.description_modal`)}</Text>
        </Center>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }} gap={3}>
          <List spacing={1}>
            <Text fontWeight={FONT_WEIGHTS.bold} as="span" color={'accent'}>
              {t('common:your_benefits')}:
            </Text>
            {perksArray.map((index) => (
              <ListItem key={index}>
                <Stack
                  direction={'row'}
                  spacing={2}
                  justifyContent={'flex-start'}
                  textAlign={'left'}
                  flex={0}
                  paddingBlock={3}
                >
                  <ListIcon mt={1} as={MdCheckCircle} boxSize={6} color={'accent'} />
                  <Stack spacing={1}>
                    <Text
                      lineHeight={'1.2'}
                      fontSize={'md'}
                      color={'accent'}
                      fontWeight={FONT_WEIGHTS.bold}
                    >
                      {t(`addons:${addonId}.perks.${index}.label`)}
                    </Text>
                    <Text lineHeight={'1.2'} fontSize={'md'}>
                      {t(`addons:${addonId}.perks.${index}.description`)}
                    </Text>
                  </Stack>
                </Stack>
              </ListItem>
            ))}
            {addonId === ADDON_KEYS.QR_CONNECT && (
              <ListItem>
                <Link isExternal href="https://www.oeservice.eu/fuse/">
                  <Text color={'blue.500'} fontSize={'md'} fontWeight={FONT_WEIGHTS.bold}>
                    {' '}
                    {t('addons:qr_connect.learn_more')}
                  </Text>
                </Link>
              </ListItem>
            )}
          </List>
          <Icon
            as={get(ADDON_ICONS, addonId)}
            boxSize={{ base: '40', lg: '72' }}
            placeSelf={'center'}
          />
        </Grid>
      </Grid>
    ),
    content: {
      header: isMazdaAndCountryDe
        ? t('service_record:not_supported.mazda_title')
        : t('addons:requires'),
      footer: {
        buttons: {
          hide: false,
          actionCaption: t(
            `pages:subscription.manage.update.addons.${
              isTrialAvailable ? 'trial' : 'purchase'
            }`,
          ),
        },
        before: (
          <Box color={'accent'} textAlign={'right'}>
            <PriceInCard
              t={t}
              fontSize={'2xl'}
              billingCycle={subscription?.billing_cycle}
              value={get(price, 'amount', 0)}
              discount={price?.discount}
              isPurchased={false}
              isTrial={activeAddon?.trial_ends}
            />
          </Box>
        ),
        top:
          !isOnPromotion && !isTrialAvailable ? (
            <Text as={'div'} color={'orange.500'}>
              {!price?.discount?.type
                ? null
                : price?.discount?.type === 'forever'
                  ? t(`addons:promotion.warning.${price?.discount?.type}`)
                  : t(
                      `addons:promotion.warning.${price?.discount?.type}${
                        price?.discount?.period == 1 ? '_one' : '_other'
                      }`,
                      {
                        value:
                          price?.discount?.type === 'percentage'
                            ? `${price?.discount?.percentage}%`
                            : formatMultiCurrency(
                                price?.discount?.fixed_amount || 0,
                                currency,
                              ),
                        period:
                          price?.discount?.period == 1
                            ? undefined
                            : price?.discount?.period,
                        period_unit: t(
                          `pages:subscription.payment_interval.${price?.discount?.period_unit}`,
                        ),
                      },
                    )}
              {}
            </Text>
          ) : undefined,
        bottom: isTrialAvailable ? (
          <Text fontSize={'xs'}>{t('addons:trial.warning')}</Text>
        ) : undefined,
      },
    },
  };
};

export default GetAddonModalData;
