import { WarningTwoIcon } from '@chakra-ui/icons';
import { Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import GetInTouchCard from 'components/GetInTouchCard';
import { addYears, format, isBefore } from 'date-fns';
import { formatMultiCurrencyIntl } from 'helpers/formatCurrency';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { BILLING_CYCLE, Promotion, SubscriptionPlan } from '../../../../api/types';
import { ChoiceSwitch } from '../../../../components/ChoiceSwitch';
import { LoadingSpinner } from '../../../../components/LoadingSpinner';
import getDisableCostControlData from '../../../../components/modals/GetDisableCostControlModalData';
import PollingAlertModal from '../../../../components/modals/PollingAlertModal';
import { PromotionBanner } from '../../../../components/PromotionBanner';
import { useUserContext } from '../../../../context/UserContextProvider';
import { FONT_WEIGHTS } from '../../../../global/Fonts';
import { eventTrack } from '../../../../helpers/analytics';
import usePlansQuery from '../../../../hooks/queries/usePlansQuery';
import usePromotionsQuery from '../../../../hooks/queries/usePromotionsQuery';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { ContentCard } from '../../../../layout/ContentCard';
import { Plan } from '../plan/Plan';
import { PlanCard, PlanCardGroup } from '../plan/PlanCardGroup';

export const ChangePlanPage: React.FC = () => {
  const userContext = useUserContext();
  const isPartnerPromotionValid = isBefore(
    new Date(),
    addYears(new Date(userContext.workshop?.subscription?.created_at ?? ''), 1),
  );

  const isPartnerWorkshop =
    !!userContext.workshop?.distributor?.prepaid_plan && isPartnerPromotionValid;
  const prepaidLicenceAmountMonthly =
    userContext.workshop?.distributor?.prepaid_plan_amount_monthly;
  const distributorName = userContext.workshop?.distributor?.name;
  const currency = userContext.workshop?.currency;

  const { t } = useTranslation();
  const plansQuery = usePlansQuery();
  const plans = Object.values(plansQuery.data || []);
  const displayPlans: SubscriptionPlan[] = [...plans];

  const promotionsQuery = usePromotionsQuery();
  const ownPlan = userContext.workshop?.subscription?.plan;

  const isOEStarter = ownPlan?.slug === 'oe_starter';
  const isWorkshopBlocked = useIsWorkshopBlocked();

  const [billingCycle, setBillingCycle] = useState(
    userContext.workshop?.subscription?.billing_cycle || BILLING_CYCLE.MONTHLY,
  );
  const navigate = useNavigate();
  useEffect(() => {
    userContext.workshop?.subscription?.billing_cycle
      ? setBillingCycle(userContext.workshop?.subscription?.billing_cycle)
      : null;
  }, [userContext.user]);
  const getUpdateModalData = (slug: string) => {
    const plan: SubscriptionPlan = displayPlans.find(
      (plan: SubscriptionPlan) => plan.slug === slug,
    );

    return {
      pollingCondition: (data: WorkshopData) => {
        return (
          data?.subscription?.plan?.id === plan?.id &&
          data?.subscription?.billing_cycle === billingCycle
        );
      },
      pollingCompleted: () => {
        eventTrack('plan_upgrade_completed');
        navigate('/app/w/account/subscription');
      },
      showSuccessAnimation: true,
      data: {
        plan_id: plan?.id,
        billing_cycle: billingCycle,
      },
      content: {
        header: t('pages:subscription.manage.switch.plan'),
        description: t('pages:subscription.manage.switch.modal.text', {
          plan: t(`plans:${plan?.slug}.label`),
        }),
        footer: {
          buttons: {
            cancelCaption: t('common:cancel'),
            actionCaption: t('pages:subscription.manage.switch.plan'),
          },
        },
      },
      url: 'subscription/plan',
    };
  };

  const disableBillingCycle =
    userContext.workshop?.subscription?.billing_cycle === BILLING_CYCLE.YEARLY;
  const costDisableModalData = getDisableCostControlData();

  if (userContext.workshop?.disable_cost_control) {
    NiceModal.show(costDisableModalData?.modalComponent, costDisableModalData);
    return null;
  }
  if (ownPlan && displayPlans.length === plans.length) {
    displayPlans.unshift(ownPlan);
  }

  if (plansQuery.isLoading || promotionsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const textPartnerWorkshop = isOEStarter ? (
    <>
      {t('pages:subscription.partner.licenseExplanation.partner', {
        expirationDate: format(
          addYears(new Date(userContext.workshop?.subscription?.created_at ?? ''), 1),
          'MM.dd.yyyy',
        ),
        partnerName: distributorName,
      })}
      {` ${t('pages:subscription.partner.licenseExplanation.upgrade', {
        expirationDate: format(
          addYears(new Date(userContext.workshop?.subscription?.created_at ?? ''), 1),
          'MM.dd.yyyy',
        ),
        // moneyValue: `${prepaidLicenceAmountMonthly} ${currencySymbol}`,
        moneyValue: formatMultiCurrencyIntl(prepaidLicenceAmountMonthly ?? 0, currency),
      })}`}
    </>
  ) : (
    <>
      {t('pages:subscription.partner.licenseExplanation.upgradedPlan', {
        expirationDate: format(
          addYears(new Date(userContext.workshop?.subscription?.created_at ?? ''), 1),
          'MM.dd.yyyy',
        ),
        partnerName: distributorName,
        moneyValue: formatMultiCurrencyIntl(prepaidLicenceAmountMonthly ?? 0, currency),
      })}
    </>
  );

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }

  return (
    <>
      <PageMetatags title={t('pages:subscription.manage.meta.title')} />
      {promotionsQuery.data?.map((promotion: Promotion, index: number) => {
        if (promotion?.location?.includes('update_plan')) {
          return (
            <PromotionBanner
              key={index}
              promotion={promotion}
              url={'/app/w/account/subscription/plan'}
              showCta={false}
            />
          );
        }
        return null;
      })}
      <ContentCard
        minH="unset"
        isLoading={
          !plansQuery.isFetched ||
          isEmpty(userContext.workshop?.subscription) ||
          plansQuery.isFetching
        }
        header={t('pages:subscription.manage.update.plan.header')}
      >
        <Text>{t('pages:subscription.manage.update.plan.description')}</Text>
      </ContentCard>

      <VStack spacing={'6'}>
        <Stack direction={'column'} spacing={2} height={'full'} w={'full'}>
          {!disableBillingCycle && (
            <Flex paddingBlock={{ base: '3', lg: '6' }} justifyContent={'center'}>
              <ChoiceSwitch
                choice1={{
                  label: t('pages:subscription.frequency.monthly'),
                  value: BILLING_CYCLE.MONTHLY,
                }}
                choice2={{
                  label: t('pages:subscription.frequency.annual'),
                  value: BILLING_CYCLE.YEARLY,
                }}
                defaultValue={billingCycle}
                disabled={disableBillingCycle || Boolean(ownPlan?.is_pay_per_use)}
                //@ts-ignore
                onChange={(event) => setBillingCycle(event.target.value)}
              />
            </Flex>
          )}

          {isPartnerWorkshop && (
            <ContentCard
              minH="unset"
              backgroundColor={'green.600'}
              color={'white'}
              my={8}
              display={'flex'}
              flexDir={'row'}
            >
              <HStack>
                <WarningTwoIcon fontSize={55} />
                <VStack alignItems={'flex-start'} pl={4}>
                  <Text fontWeight={FONT_WEIGHTS.bold} mb={1} fontSize={20}>
                    {t('pages:subscription.partner.licenseExplanation.info')}
                  </Text>
                  <Text>{textPartnerWorkshop}</Text>
                </VStack>
              </HStack>
            </ContentCard>
          )}

          <Text fontWeight={FONT_WEIGHTS.bold} pt={5}>
            {t('pages:subscription.manage.update.plan.active')}
          </Text>
          {/*The slug(aka chargebee id) is used because the PlanCardGroup does not accept numbers as value */}
          <PlanCardGroup
            value={ownPlan?.slug}
            defaultValue={ownPlan?.slug}
            templateColumns={{
              base: '1fr',
              lg: 'repeat(auto-fit, minmax(10rem, 15rem))',
            }}
            gap={4}
          >
            {displayPlans.map((plan: SubscriptionPlan) => {
              return (
                <PlanCard key={plan.slug} value={plan.slug}>
                  <Plan
                    onChange={(planSlug: string) =>
                      NiceModal.show(PollingAlertModal, getUpdateModalData(planSlug))
                    }
                    key={plan.slug}
                    plan={
                      userContext.workshop?.subscription?.plan?.slug === 'oe_starter' &&
                      userContext.workshop?.distributor &&
                      plan?.slug === 'oe_starter'
                        ? { ...plan, price: { ...plan.price, year: plan.price.month } }
                        : plan
                    }
                    isOwn={
                      plan?.slug === ownPlan?.slug &&
                      userContext.workshop?.subscription?.billing_cycle === billingCycle
                    }
                    billingCycle={
                      userContext.workshop?.subscription?.plan?.slug === 'oe_starter' &&
                      userContext.workshop?.distributor &&
                      plan?.slug === 'oe_starter'
                        ? BILLING_CYCLE.MONTHLY
                        : billingCycle
                    }
                    hideSetupFee={true}
                    hideSelectButton={
                      (userContext.workshop?.subscription?.plan?.slug === 'oe_starter' &&
                        userContext.workshop?.distributor &&
                        plan?.slug === 'oe_starter') ||
                      (plan?.slug === ownPlan?.slug &&
                        userContext.workshop?.subscription?.billing_cycle ===
                          billingCycle)
                    }
                    customButtonText={`${t('pages:subscription.manage.switch.plan')} 
                    ${t(`plans:${plan?.slug}.label`)}`}
                  />
                </PlanCard>
              );
            })}
          </PlanCardGroup>
        </Stack>
        <GetInTouchCard />
      </VStack>
    </>
  );
};

export default ChangePlanPage;
