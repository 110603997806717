import { Grid } from '@chakra-ui/react';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { WORKSHOP_ACTIVE } from '../../../context/UserContextProvider';
import PollingCard from './PollingCard';

export const CreateSubscriptionPollingPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' ||
    posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  return (
    <PublicPageLayout abTestNewDesign={abTestNewDesign}>
      <PageMetatags title={t('pages:polling.meta.title')} />
      <Grid
        w="full"
        h="full"
        placeItems="center"
        backgroundColor={abTestNewDesign ? 'gray.200' : undefined}
        height={abTestNewDesign ? '100vh' : undefined}
      >
        <PollingCard
          allowRetry={true}
          pollingCondition={(data) => {
            return data?.status === WORKSHOP_ACTIVE;
          }}
          success={() => {
            navigate('/app/setup/completed');
          }}
        />
      </Grid>
    </PublicPageLayout>
  );
};

export default CreateSubscriptionPollingPage;
