import { Box, Button, Flex, Icon, Link, Square, Stack, useToast } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { IoIosWarning } from 'react-icons/io';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import * as yup from 'yup';

import { ButtonSpinner } from '../../../components/LoadingSpinner';
import AlertModal from '../../../components/modals/AlertModal';
import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { useUserContext } from '../../../context/UserContextProvider';
import { validateVat } from '../../../helpers/general';
import { SupportedCountriesVatFormatting } from '../../../helpers/localization';
import { simpleCheckBox, simpleInput } from '../../../helpers/makeFormFields';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export interface IWelcomeExistingCustomerForm {
  consent_termsandconditions: any;
  consent_dataprivacy: any;
  vat_number: string;
}

export const WelcomeExistingCustomerForm = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const userContext = useUserContext();
  const navigate = useNavigate();
  const axios = useAxios();

  const schema = yup
    .object({
      consent_termsandconditions: yup
        .bool()
        .required()
        .oneOf([true], t('common:required')),
      consent_dataprivacy: yup.boolean().required().oneOf([true], t('common:required')),
      vat_number: yup.string().label(t('forms:vat_number.label')).nullable(),
    })
    .required();

  const {
    register,
    reset,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors, isValid, submitCount, isSubmitting },
    setError,
    setFocus,
    clearErrors,
  } = useForm<IWelcomeExistingCustomerForm>({
    defaultValues: { vat_number: userContext.workshop?.vat_number },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  useEffect(() => {
    reset({ vat_number: userContext.workshop?.vat_number });
  }, [userContext.workshop]);

  const { mutate } = useMutation<any, Error, IWelcomeExistingCustomerForm>(
    async (data) => {
      const dataWithVatNumberNullChecked = {
        ...data,
        vat_number: data.vat_number ? data.vat_number : null,
      };
      return await axios
        .put(
          `${config.apiBaseUrl}/workshop/${get(userContext.workshop, 'id')}/transfer`,
          dataWithVatNumberNullChecked,
        )
        .catch((err) => {
          manageErrorResponse({ toastInstance: toast, error: err.response, t });
          return Promise.reject(err);
        });
    },
  );

  const onSubmit: SubmitHandler<IWelcomeExistingCustomerForm> = (data) => {
    mutate(data, {
      onSuccess: () => {
        navigate('/app/setup/subscription');
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        (Object.keys(errors) as Array<keyof IWelcomeExistingCustomerForm>).forEach(
          (key) => {
            setError(key, {
              type: 'server',
              message: errors[key]!.join('. '),
            });
          },
        );
      },
    });
  };
  const onChangeVat = useAsyncDebounce((val) => {
    if (userContext.workshop?.country !== 'CH') {
      validateVat(val, userContext.workshop?.country).then((data: boolean) => {
        if (!data) {
          setError('vat_number', {
            type: 'server',
            message: t('forms:vat_number.invalid'),
          });
          setTimeout(() => {
            setFocus('vat_number');
          }, 100);
        } else {
          clearErrors('vat_number');
        }
      });
    }
  }, 3000);

  return (
    <Stack
      as={'form'}
      spacing="5"
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
      onSubmit={(event) => {
        event.preventDefault();
        trigger();
        if (isValid) {
          NiceModal.show(AlertModal, {
            onSubmit: () => handleSubmit(onSubmit)(),
            content: {
              icon: (
                <Square size="10" borderRadius="md" display={'inline'}>
                  <Icon color={'orange'} as={IoIosWarning} boxSize="8" />
                </Square>
              ),
              header: t('workshop:welcome.existing.modal.header'),
              footer: {
                buttons: {
                  actionCaption: t('workshop:welcome.existing.modal.action'),
                },
              },
            },
            children: t('workshop:welcome.existing.modal.warning'),
          });
        }
      }}
      data-test-id="welcome-existing-customer-form"
    >
      <Stack w={'50%'}>
        {t('workshop:welcome.existing.vat')}
        {simpleInput({
          name: 'vat_number',
          label: t('forms:vat_number.label'),
          placeholder: t('forms:vat_number.placeholder'),
          register,
          errors,
          schema,
          customClass: 'pii',
          leftAddon:
            userContext.workshop?.country !== 'CH'
              ? userContext.workshop?.country
              : 'CHE',
          customHook: (val) => {
            // @ts-ignore
            const formatter = get(
              SupportedCountriesVatFormatting,
              userContext.workshop?.country,
            );
            if (formatter) {
              setValue('vat_number', formatter(val));
            }
            onChangeVat(val);
          },
        })}
      </Stack>

      <Box>
        {simpleCheckBox({
          name: 'consent_termsandconditions',
          label: t('forms:consent_termsandconditions.label'),
          description: (
            <Trans i18nKey="forms:consent_termsandconditions.info">
              I’ve read and accept the{' '}
              <Link
                color="accent"
                href="https://oeservice-static.s3.eu-central-1.amazonaws.com/documents/AGB_03_2023.pdf"
                isExternal
              >
                terms and conditions
              </Link>
            </Trans>
          ),
          register,
          errors,
        })}

        {simpleCheckBox({
          name: 'consent_dataprivacy',
          label: t('forms:consent_dataprivacy.label'),
          description: (
            <Trans i18nKey="forms:consent_dataprivacy.info">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              I've read and accept the{' '}
              <Link color="accent" href="https://www.oeservice.eu/datenschutz" isExternal>
                data privacy statement
              </Link>
            </Trans>
          ),
          register,
          errors,
        })}
      </Box>

      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <Button
          type="submit"
          variant="primary"
          disabled={(submitCount! > 0 && !isValid) || isSubmitting}
          data-test-id="welcome-existing-customer-submit-button"
        >
          {t('common:continue')} {isSubmitting && <ButtonSpinner />}
        </Button>
      </Flex>
    </Stack>
  );
};

export default WelcomeExistingCustomerForm;
