import {
  Button,
  Grid,
  HStack,
  Image,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import { get } from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import { VehicleType } from '../../api/types';
import { InfoCard } from '../../components/InfoCard';
import { DropdownOption, DropdownOptionGrouped } from '../../components/Table/Filter';
import {
  displayRow,
  getArrayForGroupedDropdown,
  getVehicleTypeOptions,
} from '../../helpers/general';

const VehicleInformation = (props: {
  vin: string;
  make: string | undefined;
  model: string | undefined;
  fuelType: string | undefined;
  vehicleTypes: Array<VehicleType>;
  image: string | undefined;
  isLoading: boolean;
  showDropdown: boolean;
  currentVehicleType: VehicleType | undefined;
  updateModelHandler(): void;
  vehicleTypesExist?: boolean;
  t: any;
}) => {
  const {
    vin,
    make,
    fuelType,
    image,
    vehicleTypes,
    isLoading,
    currentVehicleType,
    showDropdown,
    updateModelHandler,
    vehicleTypesExist,
    t,
  } = props;

  const vehicleTypesOptions: Array<DropdownOptionGrouped> = getVehicleTypeOptions({
    vehicleTypes,
    t,
  });
  const [canEdit, setCanEdit] = useState(false);

  return (
    <>
      <Stack data-test-id="vehicle-information">
        {(showDropdown || canEdit) && vehicleTypes.length > 0 && (
          <InfoCard>
            <Text fontSize="sm" color="muted">
              {t('forms:vehicle_type_id.description')}
            </Text>
            <Select
              w={'40%'}
              defaultValue={''}
              className={'tour-model-selection'}
              aria-label={'vehicle-types'}
              data-test-id="vehicle-type-dropdown"
              value={
                getArrayForGroupedDropdown(vehicleTypesOptions).find(
                  (c: DropdownOption) => c.value == currentVehicleType?.id,
                )?.value
              }
              // @ts-ignore
              onChange={(event) => updateModelHandler(event.target.value)}
              focusBorderColor={useColorModeValue('accent', 'accent')}
            >
              {vehicleTypesOptions.map((option, index) => (
                <optgroup label={option.label} key={index}>
                  {get(option, 'options', []).map(
                    (subOption: DropdownOption, subIndex: number) => (
                      <option value={subOption.value} key={subIndex}>
                        {subOption.label}
                      </option>
                    ),
                  )}
                </optgroup>
              ))}
            </Select>
          </InfoCard>
        )}
      </Stack>
      <HStack w={'100%'} data-test-id="vehicle-information">
        <Stack w="20%" p={4} position={'relative'}>
          {isLoading ? (
            <Skeleton height="200px" width="200px" />
          ) : (
            <>
              {image && vehicleTypesExist ? (
                <Image
                  width="full"
                  height="auto"
                  src={image}
                  alt="car image"
                  /* sx={{ filter: hasAddOn ? '' : 'blur(15px)' }}*/
                  sx={'blur(15px)'}
                />
              ) : (
                <Image
                  width="full"
                  height="auto"
                  src="/images/no-car-image.jpg"
                  alt="car image default"
                />
              )}
            </>
          )}
        </Stack>
        <Stack w="full" className={'tour-model-selection-upsell'}>
          {displayRow({
            label: t('forms:vin.label'),
            content: vin,
            isLoading,
            customClass: 'pii',
          })}
          {displayRow({
            label: t('forms:make.label'),
            content: make ?? 'Unknown',
            isLoading,
          })}
          <HStack>
            {displayRow({
              label: t('forms:model.label'),
              content: vehicleTypesExist
                ? currentVehicleType
                  ? `${currentVehicleType?.fullname}, ${currentVehicleType?.engine_code}`
                  : t('pages:vehicle_identification.choose.note')
                : t('pages:vehicle_identification.no_vehicle_types'),
              isLoading,
            })}
            {!canEdit && vehicleTypes.length > 1 && currentVehicleType && (
              <Button
                data-test-id="vehicle-type-update-button"
                variant="link"
                size="xs"
                onClick={() => setCanEdit(true)}
              >
                {t('common:update')}
              </Button>
            )}
          </HStack>
          {fuelType &&
            displayRow({
              label: t('forms:fuel.label'),
              content: vehicleTypesExist
                ? fuelType
                : t('pages:vehicle_identification.no_vehicle_types'),
              isLoading,
            })}
        </Stack>
      </HStack>
    </>
  );
};

export default VehicleInformation;
