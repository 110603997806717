import { Button, Flex, Icon, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { ServiceRecordIcon } from '../../components/Icons';
import { DataTable } from '../../components/Table/DataTable';
import { dateTimeTransformer } from '../../helpers/dateTransformer';
import useServiceRecordByStatusQuery from '../../hooks/queries/service/useServiceRecordByStatusQuery';
import { ContentCard } from '../../layout/ContentCard';

const SrInClarificationCard = () => {
  const { t } = useTranslation();
  const [tablePage, setTablePage] = useState(1);
  const [itemsPerTablePage, setItemsPerTablePage] = useState(10);

  useEffect(() => {
    setTablePage(1);
  }, [itemsPerTablePage]);

  const serviceRecordByStatusQuery = useServiceRecordByStatusQuery(
    500,
    'submitted_at',
    'asc',
    itemsPerTablePage,
    tablePage,
  );
  const srInStatusClarificationObject = serviceRecordByStatusQuery.data;
  // @ts-ignore
  const srInStatusClarification = srInStatusClarificationObject?.data;
  // @ts-ignore
  const currentPage = (srInStatusClarificationObject?.meta?.current_page as string)
    ? srInStatusClarificationObject?.meta?.current_page - 1
    : 1;

  const columns = React.useMemo(
    () => [
      {
        accessor: 'highlighted',
        // @ts-ignore
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return row?.original?.highlighted ? (
            <Icon as={BsExclamationCircleFill} boxSize={6} mr={-50} color="orange.400" />
          ) : null;
        },
        width: '10px',
      },
      {
        Header: t('forms:id.label'),
        accessor: 'user_readable_id',
      },
      {
        Header: t('forms:vin.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.vin ?? '-'}</>;
        },
      },
      {
        Header: t('forms:make.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.make ?? '-'}</>;
        },
      },
      {
        Header: t('forms:vehicle_type_id.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.vehicle_type ?? '-'}</>;
        },
      },
      {
        Header: t('forms:date.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{dateTimeTransformer(row?.original?.date) ?? '-'}</>;
        },
      },
      {
        id: 'button',
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return (
            <Flex>
              <Button
                ml={'auto'}
                colorScheme="blue"
                as={'a'}
                href={`/app/w/service-record/${row?.original?.id}`}
                variant="solid"
                size="sm"
                data-test-id={'service-record-' + row?.original?.id}
              >
                {t('common:details')}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [srInStatusClarification],
  );

  return (
    <ContentCard
      icon={<ServiceRecordIcon fill={'orange.500'} boxSize="10" alignSelf={'center'} />}
      minH="fit-content"
      header={t('service_record:status.clarification.sr_in_clarification.title')}
      contentDescription={t(
        'service_record:status.clarification.sr_in_clarification.description',
      )}
      data-test-id="sr-in-clarification-card"
    >
      <Stack direction={['column', 'row']} w={'100%'}>
        <Stack direction="column" w={'100%'}>
          <DataTable
            data-test-id="sr-in-clarification-table"
            hasFilters={false}
            columns={columns}
            loading={serviceRecordByStatusQuery.isLoading}
            data={srInStatusClarification || []}
            // @ts-ignore
            total={srInStatusClarificationObject?.meta?.last_page || 1}
            currentPage={currentPage}
            setPage={setTablePage}
            perPage={itemsPerTablePage}
            setPerPage={setItemsPerTablePage}
            isMoreThenOnePage={true}
          />
        </Stack>
      </Stack>
    </ContentCard>
  );
};

export default SrInClarificationCard;
