import { useUserContext } from 'context/UserContextProvider';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { isNil } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import useServiceRecordQuery from '../../hooks/queries/service/useServiceRecordQuery';
import ServiceRecordForm from './ServiceRecordForm';

const UpdateServiceRecordPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const serviceRecordId = Number(id);
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const serviceRecordQuery = useServiceRecordQuery(serviceRecordId);
  // @ts-ignore

  const navigate = useNavigate();
  if (isNaN(serviceRecordId)) {
    navigate('/404', { replace: true });
    return null;
  }

  const initialValues = {
    ...serviceRecordQuery.data,
    vehicle_id: serviceRecordQuery.data?.vehicle?.id,
    vin: serviceRecordQuery.data?.vehicle?.vin,
    registration_date: serviceRecordQuery.data?.vehicle?.initial_registration_date,
    max_age_in_days: serviceRecordQuery.data?.vehicle?.max_age_in_days,
    data: Array.isArray(serviceRecordQuery.data?.data)
      ? {}
      : serviceRecordQuery.data?.data,
  };

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (serviceRecordQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags
        title={`${t('pages:service_record.form.update')} ${t('common:nr')}: ${id}'`}
      />
      <ServiceRecordForm
        title={t('pages:service_record.form.update')}
        isLoadingParent={isNil(serviceRecordQuery.data) || serviceRecordQuery.isFetching}
        initialValues={{ ...initialValues }}
        apiEndpointUrl={'/service-record/' + id}
        formVersionId={serviceRecordQuery.data?.dsb_form_version_id as number}
        isUpdate
        // @ts-ignore
        vehicle={serviceRecordQuery.data?.vehicle}
        navigateUrl={'/app/w/vehicle?vin='}
      />
    </>
  );
};

export default UpdateServiceRecordPage;
