import { Button, Flex, HStack, Text } from '@chakra-ui/react';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { ServiceHistoryRequestIcon } from '../../components/Icons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import PageHeading from '../../components/PageHeading';
import { useUserContext } from '../../context/UserContextProvider';
import useAccountUpdateFormMutation from '../../hooks/private/mutations/useCreateServiceHistoryRequestMutation';
import useServiceHistoryQuery from '../../hooks/queries/service/useServiceHistoryQuery';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import { ContentCard } from '../../layout/ContentCard';
import NeedsVerificationModal from '../serviceRecord/NeedsVerificationModal';

const ServiceHistoryPage: React.FC<{}> = () => {
  const userContext = useUserContext();
  const { id } = useParams();
  const serviceHistoryId = Number(id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const accountUpdateFormMutation = useAccountUpdateFormMutation();

  const requestServiceHistory = () => {
    const historyBackTimes = 50;
    if (!accountUpdateFormMutation.isLoading) {
      accountUpdateFormMutation.mutate(serviceHistoryRequestData);

      for (let i = 0; i < historyBackTimes; i++) {
        history.pushState({}, '', '/app/w/vehicle?vin=' + vehicleQuery.data?.vin);
      }

      return navigate('/app/w/vehicle?vin=' + vehicleQuery.data?.vin, { replace: true });
    }
  };
  useEffect(() => {
    let validUrl = false;
    if ((isNil(id) || id === 'null') && (isNil(vin) || vin === '')) {
      throw new Error(t('errors:invalid_url'));
    }
    if (id && (isNil(vin) || vin === '')) {
      validUrl = true;
    }
    if ((isNil(id) || id === 'null') && vin) {
      validUrl = true;
    }
    if (!validUrl) {
      throw new Error(t('errors:invalid_url'));
    }
  }, [id, vin]);

  const serviceHistoryQuery = useServiceHistoryQuery(serviceHistoryId);
  const vehicleQuery = useVehicleQuery(vin);

  if (!vin) {
    vehicleQuery.data = serviceHistoryQuery.data?.vehicle;
  }

  const serviceHistoryRequestData = {
    ...serviceHistoryQuery.data,
    vehicle_id: vehicleQuery.data?.id,
  };

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (vehicleQuery.isLoading || serviceHistoryQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags
        title={`${t('pages:service_history_request.create.label')} ${
          id ? `ID: ${id}` : `VIN: ${vin}`
        }`}
      />
      <NeedsVerificationModal
        vehicle={vehicleQuery.data}
        workshop={userContext.workshop}
      />

      <HStack justify="space-between">
        <PageHeading title={t('pages:service_history_request.create.label')} />
        <Button
          p={2}
          onClick={() => navigate(`/app/w/vehicle?vin=${vin}`)}
          variant={'outline'}
          data-test-id="service-history-page-back-button"
          flexShrink={0}
        >
          {t('pages:service_record.detail.back')}
        </Button>
      </HStack>
      <ContentCard
        isLoading={isEmpty(vehicleQuery.data)}
        icon={<ServiceHistoryRequestIcon boxSize="10" m={1} />}
        header={
          t('pages:service_history_request.create.header') +
          (serviceHistoryQuery.data?.vehicle?.vin || vin)
        }
        data-test-id="service-history"
      >
        <Text color="muted" fontSize="sm" textAlign="left">
          {t('pages:service_history_request.create.description')}
        </Text>
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          <Button
            ml={3}
            type="submit"
            onClick={requestServiceHistory}
            variant="primary"
            data-test-id="service-history-submit-button"
            disabled={accountUpdateFormMutation.isLoading}
          >
            {t('pages:service_history_request.create.label')}
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            mr={1}
            data-test-id="service-history-review-back-button"
          >
            {t('common:abort')}
          </Button>
        </Flex>
      </ContentCard>
    </>
  );
};

export default ServiceHistoryPage;
