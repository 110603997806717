import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { useAxios } from 'context/AxiosContextProvider';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import { get, isNil } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AiFillWarning, BiCar } from 'react-icons/all';
import { BiQrScan } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import { useMutation, useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';

import { DataForEndpoint, Methods } from '../../api/types';
import { Banner } from '../../components/Banner';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import AlertModal from '../../components/modals/AlertModal';
import config from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import dateTransformer from '../../helpers/dateTransformer';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import useAddonsQuery from '../../hooks/queries/useAddonsQuery';
import useVehicleQuery, { SERVICE_STATE } from '../../hooks/queries/useVehicleQuery';
import { ContentCard } from '../../layout/ContentCard';
import ServiceProcessCard from './ServiceProcessCard';
import VehicleInformation from './VehicleInformation';

const VehicleIdentification: React.FC<{}> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';

  useEffect(() => {
    if (vin === 'undefined' || vin === '') {
      // displayErrorToast(toast, { status: 400 });
      navigate('/app/w/dashboard');
    }
  }, [vin]);

  const vehicleQuery = useVehicleQuery(vin);
  const addonsQuery = useAddonsQuery();
  const make = vehicleQuery.data?.manufacturer?.name;
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const completedServices = vehicleQuery.data?.completed_services || [];

  const axios = useAxios();

  const { mutate } = useMutation(async (vehicle_type_id) => {
    const data = { vehicle_type_id: vehicle_type_id };
    await axios
      .put(`${config.apiBaseUrl}/vehicle/${vehicleQuery.data?.id}`, data)
      .catch((err) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err);
      });
    await queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
  });

  const serviceCreated = dateTransformer(vehicleQuery.data?.active_service?.created_at);
  const serviceCreatedThirtyDaysAgo =
    new Date(vehicleQuery.data?.active_service?.created_at).getTime() +
    3 * 24 * 60 * 60 * 1000;
  const currentDate = new Date().getTime();

  const service = vehicleQuery.data?.active_service;
  const serviceActivities = get(service, 'service_activities', []);

  const serviceRecordIdArray = serviceActivities?.filter(
    (serviceActivity: any) => serviceActivity?.service_record_id !== null,
  );
  const serviceRecordStatus = serviceRecordIdArray[0]?.service_record?.status;
  const serviceRecordCompleted = serviceRecordStatus === 700;
  const qrCodeIsAlphanumeric = /^[0-9A-Z]+$/;
  const refresh = () => window.location.reload();
  const sendRequest = async ({
    url,
    method = 'post',
    dataForEndpoint = {},
    params = {},
  }: {
    url: string;
    method?: Methods;
    dataForEndpoint?: object | DataForEndpoint;
    params?: object;
  }) => {
    if (
      'qr_code_identifier' in dataForEndpoint &&
      typeof dataForEndpoint['qr_code_identifier'] === 'string' &&
      !qrCodeIsAlphanumeric.test(dataForEndpoint['qr_code_identifier'])
    ) {
      return NiceModal.show(AlertModal, {
        closeOnOverlayClick: false,
        children: (
          <Box>
            <Box height={330} width={'full'}>
              <Center>
                <VStack>
                  <Box>
                    <Icon as={IoIosWarning} boxSize="20" color={'red.500'} mt={10} />
                  </Box>

                  <Text align={'center'} mb={10}>
                    <Trans i18nKey="components:scanner_modal_form.input_field.error_message.only_alphanumeric" />
                  </Text>
                  <Button mt={10} variant={'primary'} onClick={refresh}>
                    Close
                  </Button>
                </VStack>
              </Center>
            </Box>
          </Box>
        ),
        content: {
          header: (
            <Box>
              <Center>
                <Box>
                  <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />
                </Box>
                <Box>
                  <Text mb={2}>
                    <Trans i18nKey="components:scanner_modal_form.title" />
                  </Text>
                </Box>
              </Center>
            </Box>
          ),
          footer: {
            buttons: {
              hide: true,
              cancelCaption: t('common:ok'),
            },
          },
        },
      });
    }

    const axiosCall =
      method !== 'delete'
        ? axios[method](`${config.apiBaseUrl}/${url}`, dataForEndpoint)
        : axios[method](`${config.apiBaseUrl}/${url}`);
    return await axiosCall
      .catch((err: { response: { status: number } }) => {
        err.response.data.message !== 'this service record already has a qr assigned';
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err.response);
      })
      .then(() => queryClient.invalidateQueries(useVehicleQuery.getKey(vin)));
  };

  const boxShadow = useColorModeValue('lg', 'lg-dark');
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (addonsQuery.isLoading || vehicleQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags
        title={`${t('pages:vehicle_identification.card.header')} - VIN: ${
          vehicleQuery.data?.vin
        }`}
      />

      <ContentCard
        isLoading={vehicleQuery.isFetching}
        icon={<Icon as={BiCar} boxSize={12} m={1} />}
        header={`${t('pages:vehicle_identification.header')} ${vin}`}
        contentDescription={t('pages:vehicle_identification.description')}
        data-test-id="vehicle-main-card"
        className={'tour-vehicle-general'}
      >
        <VehicleInformation
          isLoading={vehicleQuery.isFetching}
          updateModelHandler={mutate}
          vehicleTypesExist={vehicleQuery.data?.vehicle_types_available}
          showDropdown={isNil(vehicleQuery.data?.vehicle_type)}
          currentVehicleType={vehicleQuery.data?.vehicle_type}
          vehicleTypes={vehicleQuery.data?.vehicle_types || []}
          t={t}
          make={vehicleQuery.data?.manufacturer?.make.name}
          model={vehicleQuery.data?.vehicle_type?.model}
          image={vehicleQuery.data?.vehicle_type?.haynespro_image}
          fuelType={vehicleQuery.data?.vehicle_type?.fuel_type}
          vin={vin}
        />
      </ContentCard>

      {serviceRecordCompleted && currentDate > serviceCreatedThirtyDaysAgo ? (
        <Banner
          bgColor={'orange.100'}
          hideActionButton={true}
          fontWeight={'bold'}
          bannerTextHeadline={t('pages:service_record.complete_service', {
            serviceCreatedDate: serviceCreated,
          })}
          hasIcon={true}
          icon={AiFillWarning}
          iconColor={'orange.500'}
          boxShadow={boxShadow}
          borderRadius="lg"
        />
      ) : null}
      <ServiceProcessCard
        make={make}
        isLoading={vehicleQuery.isFetching}
        vin={vin}
        addons={addonsQuery.data}
        timezone={userContext.user?.timezone || 'Europe/Vienna'}
        state={
          isNil(vehicleQuery.data?.active_service)
            ? SERVICE_STATE.PROCESSING
            : SERVICE_STATE.ACTIVE
        }
        vehicleTypesExist={vehicleQuery.data?.vehicle_types_available}
        sendRequest={sendRequest}
        workshop={userContext.workshop}
        statusCodes={userContext.statusCodes}
        service={service}
        form={vehicleQuery.data?.form_version?.version}
        features={vehicleQuery.data?.features}
      />

      {completedServices.length > 0 && (
        <>
          <Heading size="sm">
            {t('pages:vehicle_identification.completed_services.label')}
          </Heading>
          {completedServices.map((service, index) => (
            <ServiceProcessCard
              make={make}
              key={`serviceprocess-${index}`}
              isLoading={vehicleQuery.isFetching || addonsQuery.isFetching}
              vin={vin}
              addons={addonsQuery.data}
              vehicleTypesExist={vehicleQuery.data?.vehicle_types_available}
              service={service}
              workshop={userContext.workshop}
              timezone={userContext.user?.timezone || 'Europe/Vienna'}
              state={SERVICE_STATE.COMPLETED}
              sendRequest={sendRequest}
              statusCodes={userContext.statusCodes}
              hasCollapsableContent
              form={vehicleQuery.data?.form_version?.version}
              features={vehicleQuery.data?.features}
            />
          ))}
        </>
      )}
    </>
  );
};

export default VehicleIdentification;
