import { AxiosError } from 'axios';
import { AxiosInstance } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { WorkshopData } from './useWorkshopQuery';

async function getWorkshop(axios: AxiosInstance): Promise<WorkshopData> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop`, {});

  return data;
}

export default function useWorkshopAxiosContextQuery<TData = WorkshopData>(
  options?: UseQueryOptions<WorkshopData, AxiosError, TData>,
) {
  const axios = useAxios();
  return useQuery('Workshop' as QueryKey, () => getWorkshop(axios), options);
}
