import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import * as React from 'react';

import { Card } from './Card';

type StepFormCardProps = {
  stepHeader: string;
  stepDescription: string;
  children?: React.ReactNode;
  isLoading: boolean;
  skeletonHeight?: string;
  abDesignTest?: boolean;
  currentStep?: number;
  width?: string;
};
export const StepFormCard: React.FC<StepFormCardProps> = ({
  abDesignTest,
  stepHeader,
  stepDescription,
  isLoading,
  children,
  skeletonHeight = '400px',
  currentStep,
  width,
}) => (
  <Card
    backgroundColor={abDesignTest && currentStep === 0 ? 'gray.200' : undefined}
    boxShadow={abDesignTest ? 'none' : undefined}
  >
    <Stack spacing="4" p={abDesignTest ? 0 : 8} width={width}>
      {isLoading ? (
        <Skeleton height="40px" />
      ) : (
        <>
          {' '}
          <Heading size="xs">{stepHeader}</Heading>
          <Text color="muted" fontSize="sm">
            {stepDescription}
          </Text>
        </>
      )}
    </Stack>
    <Divider />
    <Stack direction={['column']} paddingBlock={4}>
      {isLoading ? (
        <Box paddingInline={8}>
          <Skeleton height={skeletonHeight} />
        </Box>
      ) : (
        children
      )}
    </Stack>
  </Card>
);
