import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Announcement } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getAnnouncement(axios: AxiosInstance): Promise<Array<Announcement>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/announcement`);
  return data;
}

export default function useAnnouncementQuery() {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(getKey(), () => getAnnouncement(axios), {
    staleTime: DURATION.Minute * 5,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(): QueryKey {
  return ['Announcements'];
}

useAnnouncementQuery.getKey = getKey;
