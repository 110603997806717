import { Button, Container, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FileDownload from 'js-file-download';
import { merge } from 'lodash';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import * as yup from 'yup';

import { PortalButton } from '../../../components/buttons/PortalButton';
import { dropzone } from '../../../helpers/makeFormFields';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';
import { IAccountVerificationForm } from '../verification/VerificationForm';

export const VerificationStep = (props: {
  backendErrors: object;
  workshop?: Partial<WorkshopData>;
  isLoading: boolean;
  file: any;
  onSubmit: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const verificationSchema = yup
    .object({
      business_licence: yup
        .mixed()
        .required(t('workshop_verification_request:business_licence.error_message')),
      identification: yup
        .mixed()
        .required(t('workshop_verification_request:identification.error_message')),
      power_of_attorney: yup
        .mixed()
        .required(t('workshop_verification_request:power_of_attorney.error_message')),
    })
    .required();
  const { workshop, onSubmit, isLoading, file, backendErrors } = props;
  const { register, control, formState, handleSubmit } =
    useForm<IAccountVerificationForm>({
      resolver: yupResolver(verificationSchema),
      criteriaMode: 'all',
      mode: 'onChange',
    });
  const { isValid, submitCount } = formState;
  const errors = merge(formState.errors, backendErrors);

  return (
    <Container
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      opacity={isLoading ? 0.5 : 1}
      py={{ base: '0', sm: '8' }}
      px={{ base: '4', sm: '6' }}
    >
      <Stack px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
        <Stack direction={['column']}>
          {dropzone({
            name: 'business_licence',
            label: (
              <Trans whiteSpace={'pre-wrap'} i18nKey="forms:business_licence.label" />
            ),
            description: t('forms:business_licence.description'),
            control,
            register,
            errors,
          })}

          {dropzone({
            name: 'identification',
            label: <Trans whiteSpace={'pre-wrap'} i18nKey="forms:identification.label" />,
            description: t('forms:identification.description'),
            control,
            register,
            errors,
          })}

          {dropzone({
            name: 'power_of_attorney',
            label: (
              <Trans whiteSpace={'pre-wrap'} i18nKey="forms:power_of_attorney.label" />
            ),
            register,
            description: (
              <Grid
                gap={4}
                templateColumns={{ base: '1fr', xl: '1fr 10rem' }}
                alignItems={'start'}
              >
                <Text fontSize="sm" textAlign="left">
                  {t('forms:power_of_attorney.description.one')}
                </Text>
                <Button
                  data-test-id="download-power-of-attorney-pdf"
                  leftIcon={<MdArrowDownward />}
                  colorScheme="blue"
                  variant="solid"
                  float={'left'}
                  size="sm"
                  my="2"
                  onClick={() => {
                    FileDownload(
                      file.data,
                      'Power of Attorney - ' + workshop?.name + '.pdf',
                    );
                  }}
                >
                  {t('common:download')}
                </Button>

                <Text fontSize="sm" textAlign="left">
                  {t('forms:power_of_attorney.description.two')}
                </Text>
              </Grid>
            ),
            control,
            errors,
          })}
        </Stack>
      </Stack>
      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <PortalButton
          type="submit"
          data-test-id="submit-preregistration"
          disabled={(submitCount! > 0 && !isValid) || isLoading}
          isLoading={isLoading}
        >
          {t('common:submit')}
        </PortalButton>
      </Flex>
    </Container>
  );
};

export default VerificationStep;
