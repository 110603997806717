import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BILLING_CYCLE } from '../../api/types';
import config from '../../config';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

declare var Chargebee: any;

export function useChargeBeeVatValidation(country: string, vat_number: string) {
  if (vat_number.length > 20) {
    return false;
  }

  let instance = Chargebee.init({
    site: config.chargeBeeSiteId,
  });

  return instance.load('functions').then(() => {
    return instance.vat
      .validateVat({ country, vat_number })
      .then((data: { status: any }) => {
        return !(data?.status === 'INVALID');
      })
      .catch((err: any) => {
        captureException(err);
        return true;
      });
  });
}

export default function useChargeBee() {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axios = useAxios();
  async function triggerPayment(form: {
    plan_id: number;
    billing_cycle: string | BILLING_CYCLE;
    coupon_id?: string;
  }) {
    let instance = Chargebee.init({
      site: config.chargeBeeSiteId,
    });

    instance.openCheckout({
      hostedPage: async function () {
        try {
          const data = await axios
            .post(config.apiBaseUrl + '/checkout', form, {
              headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
              },
            })
            .catch((err) => {
              return Promise.reject(err);
            });
          return data.data;
        } catch (err) {
          manageErrorResponse({
            toastInstance: toast,
            showValidation: true,
            t,
            error: err?.response,
            customMessage: err?.response?.data?.message || err?.response?.message,
          });
          captureException(err);
        }
      },
      loaded: function () {
        // Optional
        // will be called once checkout page is loaded
      },
      error: function (error: any) {
        // Optional
        captureException(error);
        // will be called if the promise passed causes an error
      },
      // eslint-disable-next-line
      step: function (step: any) {
        // Optional
        // will be called for each step involved in the checkout process
      },
      success: function () {
        instance.closeAll();
        navigate('/app/setup/polling');
      },
      close: function () {
        // Optional
        // will be called when the user closes the checkout modal box
      },
    });
  }

  return { triggerPayment };
}

export function useChargeBeePortal() {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axios = useAxios();

  function triggerPortal() {
    let instance = Chargebee.init({
      site: config.chargeBeeSiteId,
    });

    instance.openCheckout({
      hostedPage: async function () {
        try {
          const response = await axios
            .get(`${config.apiBaseUrl}/subscription/manage-payment-sources`)
            .catch((err) => Promise.reject(err));

          const { data: paymentSource } = response;

          return new Promise(function (resolve, reject) {
            // This sampe response should be fetched as a result of an ajax call
            var sampleResponse = {
              id: paymentSource?.id,
              type: paymentSource?.type,
              url: paymentSource?.url,
              state: paymentSource?.state,
              embed: paymentSource?.embed,
              created_at: paymentSource?.created_at,
              expires_at: paymentSource?.expires_at,
            };
            resolve(sampleResponse);
          });
        } catch (err) {
          manageErrorResponse({
            toastInstance: toast,
            showValidation: true,
            t,
            error: err?.response,
            customMessage: err?.response?.data?.message || err?.response?.message,
          });
          captureException(err);
        }
      },
      loaded: function () {
        // Optional
        // will be called once checkout page is loaded
      },
      error: function (error: any) {
        captureException(error);
        // Optional
        // will be called if the promise passed causes an error
      },
      step: function (step: any) {
        // Optional
        // will be called for each step involved in the checkout process
      },
      success: function (hostedPageId: any) {
        // Optional
        // will be called when a successful checkout happens.
      },
      close: function () {
        // Optional
        // will be called when the user closes the checkout modal box
      },
    });
  }

  return { triggerPortal };
}
