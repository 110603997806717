import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getFile(axios: AxiosInstance, url: string) {
  const { data } = await axios.get(config.apiBaseUrl + url, {
    responseType: 'arraybuffer',
  });
  return data;
}

export default function useFileQuery(url: string) {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(getKey(url), () => getFile(axios, url), {
    staleTime: DURATION.Minute * 5,
    enabled: !!url,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getKey(url: string): QueryKey {
  return ['File', url];
}

useFileQuery.getKey = getKey;
