import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { useAxios } from 'context/AxiosContextProvider';
import useServiceEntryConversationQuery from 'hooks/queries/service/useServiceEntryConversationQuery';
import { concat, get, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BiQrScan } from 'react-icons/bi';
import { IoIosWarning } from 'react-icons/io';
import { MdArrowDownward } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';

import {
  ADDON_KEYS,
  CHARGE_KEYS,
  DataForEndpoint,
  FileObject,
  PartialStateCode,
  ServiceRecord,
  SubscriptionAddon,
} from '../../api/types';
import { Document } from '../../api/types';
import { ServiceRecordIcon } from '../../components/Icons';
import { InfoCard } from '../../components/InfoCard';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import AlertModal from '../../components/modals/AlertModal';
import PageHeading from '../../components/PageHeading';
import { StatusCard } from '../../components/StatusCard';
import config from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import { FONT_WEIGHTS } from '../../global/Fonts';
import {
  dateTimeTransformer,
  dateTimezoneTransformer,
} from '../../helpers/dateTransformer';
import { downloadFile } from '../../helpers/downloadHelper';
import { formatMultiCurrencyIntl } from '../../helpers/formatCurrency';
import {
  getReason,
  getStatus,
  SendRequestProps,
  useIsWorkshopBlocked,
} from '../../helpers/general';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import transformer from '../../helpers/transformer';
import UnlockFeature from '../../helpers/unlockFeature';
import useDeleteServiceRecordMutation from '../../hooks/private/mutations/useDeleteServiceRecordMutation';
import useReopenServiceRecordMutation from '../../hooks/private/mutations/useReopenServiceRecordMutation';
import useServiceRecordDeletionRequestMutation from '../../hooks/private/mutations/useServiceRecordDeletionRequestMutation';
import useServiceRecordQuery from '../../hooks/queries/service/useServiceRecordQuery';
import useAddonsQuery from '../../hooks/queries/useAddonsQuery';
import useFileQuery from '../../hooks/queries/useFileQuery';
import useFormSchemaQuery from '../../hooks/queries/useGetFormSchemaQuery';
import {
  Subscription,
  WorkshopData,
} from '../../hooks/queries/workshop/useWorkshopQuery';
import { FlexCard } from '../../layout/Card';
import { ContentCard } from '../../layout/ContentCard';
import CancelModal from '../vehicle/ServiceProcessCard/CancelModal';
import { BasicFormKeys, FORM_TYPES } from './BasicDataStep';
import ConversationCard from './ConversationCard';
import ConvertModal from './ConvertModal';
import QRScannerForm from './QRScannerForm';
import ServiceRecordData from './ServiceRecordData';

export const getQRSection = (
  displayError: boolean,
  record: ServiceRecord | undefined,
  workshop: WorkshopData | undefined,
  addons: SubscriptionAddon[],
  sendRequest: (data: any) => Promise<any>,
  t: any,
) => {
  // @ts-ignores
  const subscription: Subscription = workshop?.subscription;
  const currency = workshop?.currency;
  const innerString = isNil(record?.qr_code_identifier) ? 'not_assigned' : 'assigned';
  const activeAddons = get(subscription, 'active_addons', {});
  const hasQRConnectPackage = get(activeAddons, ADDON_KEYS.QR_CONNECT, false);
  const qrCodeApplied = record?.qr_code_identifier;

  return (
    <Stack
      marginTop={'40px'}
      direction={{ base: 'column', xl: 'row' }}
      spacing={4}
      justify="space-between"
      data-test-id="qr-card"
    >
      <>
        {t(`status:service_record.completed.subcontent.qr.${innerString}`)}
        {hasQRConnectPackage ? (
          <QRScannerForm
            sendRequest={sendRequest}
            data={record?.qr_code_identifier}
            qrCodeApplied={qrCodeApplied}
          />
        ) : (
          <Box m={4}>
            <Center>
              <Icon as={BiQrScan} boxSize="10" />
            </Center>
            <Center>
              <UnlockFeature
                currency={currency}
                workshop={workshop}
                addonId={ADDON_KEYS.QR_CONNECT}
                addons={addons}
              />
            </Center>
          </Box>
        )}
      </>
    </Stack>
  );
};

const ServiceRecordDetailsPage: React.FC<{}> = () => {
  const { statusCodes, workshop, user } = useUserContext();
  const currency = workshop?.currency;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  let { id = '' } = useParams();
  const serviceRecordId = Number(id);
  const deleteServiceRecordMutation = useDeleteServiceRecordMutation(serviceRecordId);
  const [url, setUrl] = useState<string>('');
  const fileQuery = useFileQuery(url);
  const addonsQuery = useAddonsQuery();
  const isWorkshopBlocked = useIsWorkshopBlocked();
  const [downloadDocument, setDownloadDocument] = useState<FileObject | undefined>(
    undefined,
  );
  const [click, setClick] = useState<boolean>(true);
  const axios = useAxios();
  const country = workshop?.country;
  const units = workshop?.units;
  const language = workshop?.language?.toLowerCase();
  const localeParams = `${language}-${country}`;

  useEffect(() => {
    setClick((prev) => {
      if (!prev) {
        downloadFile(downloadDocument, fileQuery.data);
      }
      return !prev;
    });

    setClick(false);
  }, [fileQuery.data, click]);

  const serviceRecordQuery = useServiceRecordQuery(serviceRecordId);
  const serviceEntryConversation = useServiceEntryConversationQuery(
    //@ts-ignore
    serviceRecordQuery.data?.conversation?.id,
  );

  const formSchemaQuery = useFormSchemaQuery(
    serviceRecordQuery.data?.dsb_form_version_id as number,
  );
  const [displayError, setDisplayError] = useState(false);
  const queryClient = useQueryClient();

  const serviceRecordDeletionRequestMutation =
    useServiceRecordDeletionRequestMutation(serviceRecordId);
  const reopenServiceRecordMutation = useReopenServiceRecordMutation(serviceRecordId);

  if (isNaN(serviceRecordId)) {
    navigate('/404', { replace: true });
    return null;
  }

  const displayDocumentsDownload =
    serviceRecordQuery.data?.vehicle?.manufacturer?.name ===
      'Hyundai Motor Manufacturing' ||
    serviceRecordQuery.data?.vehicle?.manufacturer?.name === 'Honda';
  const manufacturerSupportsDsb = serviceRecordQuery.data?.vehicle?.has_dsb;
  const invoiceDocumentId = serviceRecordQuery.data?.data?.invoice_id;
  const invoiceDownloadFile =
    serviceRecordQuery.data?.service_activity?.service?.documents.find(
      (document: any) => document?.file_id === invoiceDocumentId,
    );
  // @ts-ignore
  const invoiceDownloadFileId = invoiceDownloadFile?.id;
  // @ts-ignore
  const invoiceDownloadFileObject = invoiceDownloadFile?.file;

  const inspectionPlanDocumentId = serviceRecordQuery.data?.data?.inspection_plan_id;
  const inspectionPlanDownloadFile =
    serviceRecordQuery.data?.service_activity?.service?.documents.find(
      (document: any) => {
        return document.file_id === inspectionPlanDocumentId;
      },
    );
  // @ts-ignore
  const inspectionPlanDownloadFileId = inspectionPlanDownloadFile?.id;
  // @ts-ignore
  const inspectionPlanDownloadFileObject = inspectionPlanDownloadFile?.file;

  // @ts-ignore
  const serviceRecordCodes: PartialStateCode<string> = statusCodes?.service_record || {};
  const isIdsb = serviceRecordQuery.data?.form === FORM_TYPES.PLUS_DSB;
  const isServiceRecordCompleted =
    serviceRecordQuery.data?.status === getStatus(serviceRecordCodes, 'completed');
  const qrCodeIsAlphanumeric = /^[0-9A-Z]+$/;
  const refresh = () => window.location.reload();
  const sendRequest = async ({
    url,
    method = 'put',
    dataForEndpoint = {} as object | DataForEndpoint,
    disableInvalidate = false,
  }: SendRequestProps) => {
    if (
      'qr_code_identifier' in dataForEndpoint &&
      typeof dataForEndpoint['qr_code_identifier'] === 'string' &&
      !qrCodeIsAlphanumeric.test(dataForEndpoint['qr_code_identifier'])
    ) {
      return NiceModal.show(AlertModal, {
        closeOnOverlayClick: false,
        children: (
          <Box>
            <Box height={330} width={'full'}>
              <Center>
                <VStack>
                  <Box>
                    <Icon as={IoIosWarning} boxSize="20" color={'red.500'} mt={10} />
                  </Box>

                  <Text align={'center'} mb={10}>
                    <Trans i18nKey="components:scanner_modal_form.input_field.error_message.only_alphanumeric" />
                  </Text>
                  <Button mt={10} variant={'primary'} onClick={refresh}>
                    Close
                  </Button>
                </VStack>
              </Center>
            </Box>
          </Box>
        ),
        content: {
          header: (
            <Box>
              <Center>
                <Box>
                  <Icon as={BiQrScan} color={'accent'} boxSize="10" mr={5} />
                </Box>
                <Box>
                  <Text mb={2}>
                    <Trans i18nKey="components:scanner_modal_form.title" />
                  </Text>
                </Box>
              </Center>
            </Box>
          ),
          footer: {
            buttons: {
              hide: true,
              cancelCaption: t('common:ok'),
            },
          },
        },
      });
    }

    const axiosCall =
      method !== 'delete' && method !== 'delete_with_body'
        ? //@ts-ignore
          axios[method](url, dataForEndpoint)
        : method === 'delete_with_body'
          ? //@ts-ignore
            axios['delete'](url, { data: dataForEndpoint })
          : axios[method](url);
    return await axiosCall
      .catch((err: { response: { status: number } }) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err.response);
      })
      .then(({ data }: any) => {
        if (disableInvalidate) {
          return data;
        } else {
          queryClient.invalidateQueries(useServiceRecordQuery.getKey(serviceRecordId));
        }
      });
  };

  const generalData: PartialStateCode<string> = transformer(
    serviceRecordQuery.data,
    concat(BasicFormKeys, ['additional_notes']),
  );
  const reasons = statusCodes?.service_record_reasons || {};
  let subContent = null;

  if (
    serviceRecordQuery.data?.status === getStatus(serviceRecordCodes, 'clarification')
  ) {
    subContent = (
      <ConversationCard
        setFile={(document: Document) => {
          setUrl(`/document/${document?.id}/download`);
          setDownloadDocument(document?.file);
        }}
        recordId={serviceRecordQuery.data?.service_activity?.service?.id}
        conversation={serviceEntryConversation.data || {}}
        statusCodes={serviceRecordCodes}
        currentStatus={serviceRecordQuery.data?.status}
        sendRequest={sendRequest}
        serviceRecordId={serviceRecordId}
        conversationId={serviceRecordQuery.data?.conversation?.id}
      />
    );
  }
  if (isServiceRecordCompleted) {
    subContent = getQRSection(
      displayError,
      serviceRecordQuery.data,
      workshop,
      addonsQuery.data,
      (data) =>
        sendRequest({
          url: `${config.apiBaseUrl}/service-record/${serviceRecordQuery.data?.id}/associate-qr-identifier`,
          dataForEndpoint: data,
        }).catch((err) => {
          setDisplayError(true);
          const { message, data = {} } = typeof err == 'string' ? JSON.parse(err) : err;
          const responseFromQrCodeSubmission = data?.message;
          if (
            responseFromQrCodeSubmission === 'qr code identifier ist bereits vergeben.' ||
            responseFromQrCodeSubmission ===
              'this service record already has a qr assigned' ||
            responseFromQrCodeSubmission.includes(
              'qr code identifier muss ein String sein',
            )
          ) {
            setDisplayError(true);
            return 'qrCodeAlreadyUsed';
          }

          if (
            responseFromQrCodeSubmission !== 'qr code identifier ist bereits vergeben.'
          ) {
            return toast({
              title: responseFromQrCodeSubmission || message,
              status: 'error',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          }
        }),
      t,
    );
  }
  const isNotHyundai =
    serviceRecordQuery.data?.vehicle?.manufacturer?.make?.slug !== 'hyundai';
  // @ts-ignore
  const charge: number = get(
    workshop?.subscription?.plan?.charges,
    `${CHARGE_KEYS.DELETE_SERVICE_RECORD}.price`,
    0,
  );

  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (formSchemaQuery.isLoading || serviceRecordQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Stack
        justify="space-between"
        direction={{ base: 'column', xl: 'row' }}
        spacing={4}
      >
        <PageHeading
          isLoading={serviceRecordQuery.isFetching}
          title={t('pages:service_record.detail.title', {
            vin: serviceRecordQuery.data?.vehicle.vin,
            date: dateTimezoneTransformer(serviceRecordQuery.data?.date, user?.timezone),
          })}
        />
        <Button p={2} onClick={() => navigate(-1)} variant={'outline'} flexShrink={0}>
          {t('pages:service_record.detail.back')}
        </Button>
      </Stack>

      <StatusCard
        isLoading={serviceRecordQuery.isFetching}
        resourceKey={'service_record'}
        statusCodes={serviceRecordCodes}
        currentStatus={serviceRecordQuery.data?.status}
        currentStatusReason={serviceRecordQuery.data?.status_reason}
        subContent={subContent}
        reasons={reasons}
        customButton={
          isServiceRecordCompleted ? (
            <Button
              data-test-id="download-service-record"
              leftIcon={<MdArrowDownward />}
              variant="primary"
              size="sm"
              my="2"
              onClick={() => {
                setUrl(`/service-record/${serviceRecordQuery.data?.id}/download`);
                setDownloadDocument(serviceRecordQuery.data?.file);
                setClick(true);
              }}
            >
              {t('common:download')}
            </Button>
          ) : serviceRecordQuery.data?.status ===
              getStatus(serviceRecordCodes, 'canceled') &&
            serviceRecordQuery.data?.status_reason ===
              getReason(reasons, serviceRecordQuery.data?.status, 'no_dsb') &&
            addonsQuery.isFetched ? (
            <ConvertModal
              // @ts-ignore
              subscription={workshop?.subscription}
              addons={addonsQuery.data}
              costControlDisabled={workshop?.disable_cost_control}
              handleModal={() =>
                sendRequest({
                  url: `${config.apiBaseUrl}/service-record/${serviceRecordId}/convert`,
                })
              }
            />
          ) : null
        }
      />

      {Boolean(serviceRecordQuery.data?.legacy) && (
        <InfoCard>
          <HStack spacing={4}>
            <Icon boxSize="12" color={'orange'} as={IoIosWarning} />
            <Box>
              <Text fontWeight={FONT_WEIGHTS.bold} fontSize={'xl'}>
                {t('pages:service_record.detail.legacy.header')}
              </Text>
              <Text>{t('pages:service_record.detail.legacy.description')}</Text>
            </Box>
          </HStack>
        </InfoCard>
      )}

      <ContentCard
        isLoading={serviceRecordQuery.isFetching}
        icon={<ServiceRecordIcon boxSize={10} />}
        minH="fit-content"
        header={t('pages:service_record.detail.card.header')}
        contentDescription={t('pages:service_record.detail.card.description')}
        data-test-id="service-record-detail-card"
        footer={
          isIdsb &&
          isServiceRecordCompleted && (
            <FlexCard p={4} alignItems={{ base: 'center', lg: 'flex-end' }}>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                spacing={4}
                alignItems={'center'}
              >
                <Text maxWidth={'70ch'}>
                  {t('pages:service_record.detail.idsb_update_delete')}
                </Text>

                <Button
                  variant="outline"
                  w={{ base: 'full', md: 'auto' }}
                  minW="10rem"
                  flexShrink={0}
                  fontSize="md"
                  as={NavLink}
                  to={'/app/w/support'}
                >
                  {t('components:get_in_touch_card.please_contact_support')}
                </Button>
              </Stack>
            </FlexCard>
          )
        }
      >
        <Stack>
          {Object.keys(generalData).map((key: string) => {
            return (
              <HStack key={key}>
                <Text fontWeight="bold">{t(`forms:${key}.label`)}:</Text>
                <Text fontSize="sm" textAlign="left">
                  {generalData[key] === generalData?.date
                    ? dateTimeTransformer(generalData?.date)
                    : key === 'mileage'
                      ? `${generalData[key].toLocaleString(localeParams)} ${
                          units === 'metric' ? 'km' : 'mi'
                        }`
                      : generalData[key]}
                </Text>
              </HStack>
            );
          })}
          <Grid gap={4}>
            <ServiceRecordData
              record={get(serviceRecordQuery.data, 'data', {})}
              schema={formSchemaQuery.data}
              timezone={user?.timezone}
            />
          </Grid>
        </Stack>
        {displayDocumentsDownload && serviceRecordQuery.data?.data?.invoice_id ? (
          <Flex>
            <HStack alignSelf={'left'} mb={2}>
              <Text fontWeight="bold"> {t('forms:download_invoice.label')}:</Text>
              <Button
                ml={0}
                as={'u'}
                color={'#3F7DC1'}
                fontSize={14}
                variant={'link'}
                onClick={() => {
                  setUrl(`/document/${invoiceDownloadFileId}/download`);
                  setDownloadDocument(invoiceDownloadFileObject);
                }}
              >
                {t('forms:download_invoice.download')}
              </Button>
            </HStack>
          </Flex>
        ) : null}

        {displayDocumentsDownload && serviceRecordQuery.data?.data?.inspection_plan_id ? (
          <Flex>
            <HStack alignSelf={'left'} mb={2}>
              <Text fontWeight="bold">{t('forms:download_inspection_plan.label')}:</Text>
              <Button
                as={'u'}
                variant={'link'}
                fontSize={14}
                color={'#3F7DC1'}
                onClick={() => {
                  setUrl(`/document/${inspectionPlanDownloadFileId}}/download`);
                  setDownloadDocument(inspectionPlanDownloadFileObject);
                }}
              >
                {t('forms:download_inspection_plan.download')}
              </Button>
            </HStack>
          </Flex>
        ) : null}

        <Flex justifyContent={'right'} py="4" px={{ base: '4', md: '6' }}>
          {serviceRecordQuery.data?.status === getStatus(serviceRecordCodes, 'created') ||
            (serviceRecordQuery.data?.status ===
              getStatus(serviceRecordCodes, 'submitted') && (
              <CancelModal
                type={t('pages:service_record.label')}
                handleCancel={() => {
                  deleteServiceRecordMutation.mutate();
                  navigate('/app/w/vehicle?vin=' + serviceRecordQuery.data?.vehicle?.vin);
                }}
              />
            ))}
          {serviceRecordQuery.data?.status ===
            getStatus(serviceRecordCodes, 'created') && (
            <Button
              mx={2}
              variant="outline"
              color="error"
              borderColor="error"
              onClick={() =>
                sendRequest({
                  url: `${config.apiBaseUrl}/service-record/${serviceRecordId}`,
                  dataForEndpoint: {
                    ...serviceRecordQuery.data,
                    status: getStatus(serviceRecordCodes, 'canceled'),
                  },
                })
              }
            >
              {t('common:cancel_order')}
            </Button>
          )}
          {serviceRecordQuery.data?.status === getStatus(serviceRecordCodes, 'created') &&
            isNotHyundai && (
              <Button
                variant="primary"
                as={'a'}
                href={`/app/w/service-record/${serviceRecordQuery.data?.id}/update`}
              >
                {t('common:edit')}
              </Button>
            )}
          {serviceRecordQuery.data?.status ===
            getStatus(serviceRecordCodes, 'submitted') &&
            isNotHyundai && (
              <Button
                mx={2}
                variant="primary"
                data-test-id="service-record-update-button"
                onClick={() =>
                  NiceModal.show(AlertModal, {
                    onSubmit: () => {
                      reopenServiceRecordMutation.mutate();
                      navigate(`/app/w/service-record/${id}/update`);
                    },

                    content: {
                      header: t('pages:service_record.modals.update.title'),
                      footer: {
                        buttons: {
                          cancelCaption: t('common:cancel'),
                          actionCaption: t('common:edit'),
                        },
                      },
                    },
                    children: (
                      <>
                        <Text>{t('pages:service_record.modals.update.text')}</Text>
                      </>
                    ),
                  })
                }
              >
                {t('common:edit')}
              </Button>
            )}
        </Flex>
      </ContentCard>
      {serviceRecordQuery.data?.status === 700 && manufacturerSupportsDsb ? (
        <Box>
          <Heading size={'xs'} mb={'20px'}>
            {t('pages:service_record.request_deletion.header')}
          </Heading>
          <ContentCard bg={'red.200'} data-test-id="request-sr-deletion">
            <HStack justifyContent={'space-between'}>
              <Box>
                <Text>
                  <Trans i18nKey="pages:service_record.request_deletion.message" />
                </Text>
              </Box>
              <Box>
                <Button
                  bg={'red.600'}
                  // @ts-ignore
                  _hover={'red.600'}
                  variant="primary"
                  onClick={() => {
                    NiceModal.show(AlertModal, {
                      onSubmit: () => serviceRecordDeletionRequestMutation.mutate(),
                      children: (
                        <Text>
                          <Trans
                            i18nKey="pages:service_record.request_deletion.modal.message"
                            values={{
                              delete_service_record: formatMultiCurrencyIntl(
                                charge,
                                currency,
                              ),
                            }}
                          />
                        </Text>
                      ),
                      content: {
                        header: t('pages:service_record.request_deletion.modal.header'),
                        footer: {
                          buttons: {
                            cancelCaption: t('common:cancel'),
                            actionCaption: t('common:delete'),
                          },
                        },
                      },
                    });
                  }}
                >
                  {t('pages:service_record.request_deletion.button')}
                </Button>
              </Box>
            </HStack>
          </ContentCard>
        </Box>
      ) : null}
    </>
  );
};
export default ServiceRecordDetailsPage;
