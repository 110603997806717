export default {
  appEnv: import.meta.env.VITE_APP_ENV || 'local',
  appTitle: import.meta.env.VITE_APP_TITLE || 'oe service Customer Portal',
  authDomain: import.meta.env.VITE_AUTH0_DOMAIN || '',
  authConnection: import.meta.env.VITE_AUTH0_CONNECTION || '',
  authClientId: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
  authClientSecret: import.meta.env.VITE_AUTH0_CLIENT_SECRET || '',
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
  baseUrl: import.meta.env.VITE_BASE_URL || window.location.origin,
  logoutReturnTo: import.meta.env.VITE_LOGOUT_RETURN_TO || import.meta.env.VITE_BASE_URL,
  publicWebsiteUrl: import.meta.env.VITE_PUBLIC_WEBSITE_URL || 'https://www.oeservice.eu',
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL || 'https://int.api.oeservice.eu/v1',
  spfApiBaseUrl:
    import.meta.env.VITE_SPF_API_BASE_URL || 'https://int.api.oeservice.eu/spf/v1',
  buildBranch: import.meta.env.VITE_BRANCH || false,
  buildCommit: import.meta.env.VITE_COMMIT_REF || '-',
  buildTag: import.meta.env.VITE_TAG || false,
  buildTime: import.meta.env.VITE_BUILD_TIME || '-',
  chargeBeeSiteId: import.meta.env.VITE_CHARGEBEE_SITE || '-',
  gtmId: import.meta.env.VITE_GTM_ID || false,
  posthog_enabled: import.meta.env.POSTHOG_ENABLED || false,
  posthog_api_host_url: { api_host: 'https://eu.posthog.com' },
  posthog_api_key: import.meta.env.VITE_POSTHOG_API_KEY || '',
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN || '-',
    release: import.meta.env.VITE_SENTRY_RELEASE || false,
  },
};
