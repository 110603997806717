import { Button, Container, Flex, Icon, Stack, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays } from 'date-fns';
import * as React from 'react';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getI18n } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { LooseObject, Vehicle } from '../../api/types';
import dateTransformer from '../../helpers/dateTransformer';
import { getVehicleTypeOptions } from '../../helpers/general';
import {
  dropdownGrouped,
  numberInput,
  typingDateInput,
} from '../../helpers/makeFormFields';

export interface IBasicDataForm {
  vehicle_type_id: number;
  mileage: number;
  initial_registration_date: string;
}

const BasicDataStep = (props: {
  saveInState(x: any): void;
  setStep(y: number): void;
  data: object;
  vehicle: Vehicle | LooseObject;
  sendRequestVehicle(z: object): Promise<any>;
  sendRequestInspectionPlan(k: object): void;
  setDataForStepTwo(n: object): void;
  timezone: string;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  const schema = yup
    .object({
      vehicle_type_id: yup
        .number()
        .label(getI18n().t('forms:vehicle_type_id.label'))
        .typeError(getI18n().t('forms:vehicle_type_id.type_error'))
        .required(),
      mileage: yup
        .number()
        .label(getI18n().t('forms:mileage.label'))
        .typeError(getI18n().t('forms:mileage.type_error'))
        .required(),
      initial_registration_date: yup
        .date()
        .label(getI18n().t('forms:initial_registration_date.label'))
        .max(addDays(new Date(), 1), getI18n().t('forms:initial_registration_date.max'))
        .typeError(getI18n().t('forms:date.type_error'))
        .required(getI18n().t('forms:initial_registration_date.required')),
    })
    .required();
  const {
    saveInState,
    setStep,
    data,
    vehicle = {},
    sendRequestInspectionPlan,
    sendRequestVehicle,
    setDataForStepTwo,
    timezone,
  } = props;
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, submitCount },
    setError,
  } = useForm<IBasicDataForm>({
    defaultValues: { ...data, ...vehicle },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  useEffect(() => {
    reset({ ...data, ...vehicle });
  }, [data, vehicle]);

  const { mutate, isLoading } = useMutation<any, Error, IBasicDataForm>(async (data) => {
    const newData = {
      vehicle_type_id: data?.vehicle_type_id,
      initial_registration_date: dateTransformer(
        data?.initial_registration_date,
        timezone,
      ),
    };

    return sendRequestVehicle(newData).then(() =>
      // @ts-ignore
      sendRequestInspectionPlan(data).catch(() => {
        toast({
          title: t('errors:general'),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      }),
    );
  });

  const onSubmit: SubmitHandler<IBasicDataForm> = (formData) => {
    mutate(formData, {
      onSuccess: ({ data }) => {
        saveInState({ ...formData });
        setDataForStepTwo(data);

        const { error } = data;

        if (!error) {
          setStep(1);
        } else {
          navigate('/app/w/warning', {
            state: {
              message: error,
              title: t('forms:ip.step_one.warning.header'),

              redirectUrl: `/app/w/vehicle?vin=${vehicle?.vin}`,
            },
          });
        }
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        (Object.keys(errors) as Array<keyof IBasicDataForm>).forEach((key) => {
          setError(key, {
            type: 'server',
            message: errors[key]!.join('. '),
          });
        });
      },
    });
  };

  const vehicleTypes = vehicle?.vehicle_types || [];

  return (
    <Container
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-test-id="inspection-basic-form"
      maxWidth={'unset'}
      m={0}
    >
      <Stack spacing="5">
        <Stack direction={['column']} w={'50%'}>
          {dropdownGrouped({
            name: 'vehicle_type_id',
            label: t('forms:vehicle_type_id.label'),
            description: t('forms:vehicle_type_id.info'),
            control,
            register,
            errors,
            showAsTooltip: true,
            schema: schema,
            options: getVehicleTypeOptions({
              vehicleTypes,
              t,
            }),
          })}
          {numberInput({
            name: 'mileage',
            label: t('forms:mileage.label'),
            register,
            errors,
            schema,
          })}
          {typingDateInput({
            name: 'initial_registration_date',
            label: t('forms:initial_registration_date.label'),
            placeholder: t('forms:initial_registration_date.placeholder'),
            maxDate: new Date(),
            control,
            register,
            schema,
            errors,
          })}
        </Stack>
      </Stack>

      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
        <Button
          type="submit"
          variant="primary"
          data-test-id="inspection-basic-submit-button"
          disabled={(submitCount! > 0 && !isValid) || isLoading}
        >
          {t('common:next')}
          <Icon as={FiArrowRight} boxSize="4" />
        </Button>
      </Flex>
    </Container>
  );
};

export default BasicDataStep;
