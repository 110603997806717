import FileDownload from 'js-file-download';

import { FileObject } from '../api/types';

export function downloadFile(file: FileObject | undefined, document: any) {
  if (!file || !document) {
    return false;
  }
  const nameEnd = file?.original_name.split('.').pop();
  const doesNameHaveExtension = nameEnd
    ? ['pdf', 'png', 'jpeg', 'jpg'].includes(nameEnd)
    : false;

  FileDownload(
    new Blob([document], {
      type: file?.mime_type,
    }),
    doesNameHaveExtension
      ? file?.original_name
      : file?.original_name + '.' + file?.extension,
  );
  return true;
}
