import { Box, Divider, Stack } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import { get } from 'lodash';
import * as React from 'react';

import { PartialStateCode } from '../api/types';
import { getLightStatusColor } from '../helpers/getColourSchemeBasedOnStatus';
import { StatusTag } from './StatusTag';

export const StatusCard = (props: {
  resourceKey: string;
  isLoading: boolean;
  statusCodes: PartialStateCode<number>;
  reasons?: PartialStateCode<number>;
  currentStatus?: number;
  currentStatusReason?: number;
  customButton?: any;
  subContent?: any;
}) => {
  const {
    resourceKey,
    statusCodes,
    currentStatus = 0,
    currentStatusReason,
    reasons,
    subContent,
    customButton,
    isLoading,
  } = props;
  return (
    <Stack
      direction={'column'}
      w={'100%'}
      bgColor={getLightStatusColor(get(statusCodes, currentStatus, 'default'))}
      p={6}
      borderRadius="lg"
      pos="relative"
      data-test-id="status-card"
    >
      {isLoading ? (
        <Skeleton height="20px" width="full" />
      ) : (
        <>
          <Stack
            justifyContent="space-between"
            spacing={4}
            direction={{ base: 'column', lg: 'row' }}
            alignItems={'center'}
          >
            <StatusTag
              resourceKey={resourceKey}
              statusCodes={statusCodes}
              currentStatus={currentStatus}
              currentStatusReason={currentStatusReason}
              reasons={reasons}
            />
            {customButton}
          </Stack>
          {subContent && (
            <>
              <Divider borderColor="grey" />
              <Box>{subContent}</Box>
            </>
          )}
        </>
      )}
    </Stack>
  );
};
