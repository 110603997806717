import { BundleISP, OEDocu, OEPlusDSB, QRConnect } from '../components/illustrations';
import { InspectionPlan } from '../features/inspectionPlan/SelectInspectionStep';
import { Subscription, WorkshopData } from '../hooks/queries/workshop/useWorkshopQuery';

export enum BILLING_CYCLE {
  YEARLY = 'year',
  MONTHLY = 'month',
}

export const enum CHARGE_KEYS {
  UPDATE_WORKSHOP_DATA = 'update_workshop_data',
  SERVICE_HISTORY_REQUEST = 'service_history_request',
  SERVICE_RECORD = 'service_record',
  UPDATE_SERVICE_RECORD = 'update_service_record',
  DELETE_SERVICE_RECORD = 'delete_service_record',
  FASTLANE = 'fastlane',
  SETUP_FEE = 'setup_fee',
}

export const enum ADDON_KEYS {
  INSPECTION_PLANS_ADDON = 'inspection_plans',
  PLUS_DSB = 'oe_plus_dsb',
  QR_CONNECT = 'qr_connect',
  OE_DOCS = 'oe_docs',
}

export const ADDON_ICONS = {
  [ADDON_KEYS.INSPECTION_PLANS_ADDON]: BundleISP,
  [ADDON_KEYS.OE_DOCS]: OEDocu,
  [ADDON_KEYS.QR_CONNECT]: QRConnect,
  [ADDON_KEYS.PLUS_DSB]: OEPlusDSB,
};

export enum STATUS {
  CREATED = 'created',
  SUBMITTED = 'submitted',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  CLARIFICATION = 'clarification',
  CANCELED = 'canceled',
  DELETED = 'deleted',
  REQUESTED_DELETION = 'requested_deletion',
}

export interface Charge {
  id: string | CHARGE_KEYS;
  price: number;
}

export interface SubscriptionAddon {
  id: string;
  name: string;
  trial: boolean;
  trial_available: boolean;
  trial_ends?: string;
  description: string;
  price: {
    [BILLING_CYCLE.MONTHLY]: Price;
    [BILLING_CYCLE.YEARLY]: Price;
  };
}

export interface ActivatedAddon {
  trial_available: boolean;
  trial_ends?: string;
}

export interface Discount {
  percentage?: number;
  fixed_amount: number;
  amount: number;
  period: number;
  period_unit: string;
  type: string;
}

export interface Price {
  amount: number;
  discount?: Discount;
}
export interface SubscriptionPlan {
  id: number;
  slug: string;
  charges?: { [key: string]: Charge };
  is_pay_per_use: number;
  monthly_shr_quota: number;
  monthly_sr_quota: number;
  name: string;
  order: number;
  pre_selected: boolean;
  yearly_savings: number;
  price: {
    [BILLING_CYCLE.MONTHLY]: Price;
    [BILLING_CYCLE.YEARLY]: Price;
  };
}

export interface Promotion {
  name: string;
  slug: string;
  country: string;
  text: string;
  start: string;
  end: string;
  location: [string];
}

export interface StatusCodes {
  workshop: PartialStateCode<number>;
  workshop_verification_request: PartialStateCode<number>;
  workshop_verification_request_reason: PartialStateCode<number>;
  workshop_update_request: PartialStateCode<number>;
  workshop_update_request_reasons: PartialStateCode<number>;
  service_history_request: PartialStateCode<number>;
  service_history_request_reasons: PartialStateCode<number>;
  service_record: PartialStateCode<number>;
  service_record_reasons: PartialStateCode<number>;
}

export type PartialStateCode<T> = {
  // @ts-ignore
  [key in T]: any;
};

export type VehicleType = {
  data: { [key: string]: any };
  current_page: number;
  id: string;
  squish_vin: string;
  model: string;
  fullname: string;
  made_from: string;
  made_until: string;
  engine_code: string;
  capacity: number;
  output: number;
  fuel_type: string;
  haynespro_car_type_id: number;
  haynespro_image: string;
};

export type ServiceActivity = {
  id: string;
  uuid: string;
  type: string;
  date: string;
  vehicle_id: number;
  workshop_id: number;
  service_record_id: number;
  service_history_request_id: number;
  service: Service;
  inspection_plan: InspectionPlan;
  service_record: ServiceRecord;
  service_history_request: ServiceHistory;
  meta_data: {
    email?: string;
    documents?: Array<{
      id: number;
      name: string;
    }>;
  };
};

export type PaginatedResults<T> = {
  next_page_url: string;
  last_page: number;
  current_page: number;
  data: Array<T>;
};

export type DsbFormVersion = {
  id: number;
  dsb_form_id: number;
  version: string;
  published_at: string;
};

export type Vehicle = {
  notify_when_ready: boolean;
  id: string;
  vin: string;
  has_dsb: boolean;
  form_identifier: string;
  manufacturer: {
    slug: string;
    name: string;
    make: {
      name: string;
      slug: string;
      portal: { verification_needed: string };
      portal_id: number;
    };
  };
  service_activities: Array<ServiceActivity>;
  vehicle_type: VehicleType | undefined;
  vehicle_types: Array<VehicleType>;
  initial_registration_date: string;
  max_age_in_days: number;
  vehicle_types_available: boolean;
  features: {
    vehicle_identification: string;
    service_history_request: string;
    service_record: string;
    inspection_plan: string;
    uploaded_documents: string;
    sent_documents: string;
    service_record_fastlane: string;
  };
  completed_services?: Array<Service> | [];
  active_service: Service | {};
  form_version: DsbFormVersion;
  account: Account | null;
};

export type Accounts = Array<{
  account_created_at: string;
  can_access_dsb: boolean;
  created_at: string;
  id: number;
  makes: Array<object>;
  estimate: string;
  portal_id: number;
  account: Array<Account>;
  status: string;
  updated_at: string;
}>;

export interface Account {
  status: number;
  notify_when_ready: boolean;
  dsb_form_id: number;
  can_access_dsb: boolean;
  id: number;
  max_age_in_days: number;
  name: string;
  portal_id: number;
  slug: string;
}

export interface Announcement {
  translation_key: string;
  start: string;
  end: string;
  country: Array<string>;
}

export type Config = {
  countries: string[];
  languages: { [country: string]: string[] };
  telephone_prefixes: LooseObject;
  zipcode_length: LooseObject;
  terms_and_conditions_url: string;
  data_privacy_url: string;
  dpa_url: string;
  support_telephone: string;
  support_email: string;
};

export interface CostOverview {
  id: string;
  date: Date;
  total?: number;
}

export type FileObject = {
  name: string;
  path: string;
  uuid: string;
  disk: string;
  mime_type: string;
  extension: string;
  size: number;
  original_name: string;
};

export type GroupField = {
  icon: string;
  fields: Array<{ type: string }>;
};

export interface Group {
  general: GroupField;
  filter: GroupField;
  brake_system: GroupField;
  liquids: GroupField;
  oil_and_gearbox: GroupField;
  additional_work: GroupField;
}

export interface LooseObject {
  [key: string]: any;
}

export interface WorkshopContextInterface {
  workshop: WorkshopData;
  subscription?: Subscription;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  login: () => void;
  logout: () => Promise<void>;
  error?: Error | null;
  config: Config | LooseObject;
  token: string | undefined;
  statusCodes: StatusCodes;
  user: User | undefined;
}

export type Methods = 'head' | 'options' | 'put' | 'post' | 'patch' | 'delete' | 'get';

export type TourMetaData = {
  seen_starter_tour: boolean;
  seen_vehicle_general: boolean;
  seen_service_record: boolean;
  seen_service_history_request: boolean;
  seen_inspection_plan: boolean;
  seen_send_documents: boolean;
};
export type User = {
  id: number;
  auth0_id: string;
  firstname: string;
  lastname: string;
  email: string;
  type: string;
  workshop_id: number;
  show_welcome_message: boolean;
  created_at: any;
  updated_at: any;
  deleted_at: any;
  auth: object;
  timezone: string;
  tour_meta_data: TourMetaData | null;
};

export type Document = {
  id: number;
  created_at: string;
  updated_at: string;
  type: string;
  comment: string;
  service_id: number;
  service_record_id: number;
  service_history_request_id: number;
  workshop_verification_id: number;
  workshop_update_request_id: number;
};

export type Message = {
  text: string;
  created_at: string;
  author: string;
  user_id: number;
  workshop_user_id: number;
  document?: Document;
};

export type Conversation = {
  id: number;
  key: string;
  user_id: number;
  messages: Array<Message>;
};

export interface ServiceRecord {
  dsb_form_version_id: number;
  service_activity: ServiceActivity;
  max_age_in_days: number;
  id: number;
  date: string; //date
  vin?: string;
  uuid?: string;
  form: string;
  status: number;
  mileage: number;
  fastlane: boolean;
  user_readable_id: string;
  status_reason?: number;
  partner_id?: number;
  file_id?: number;
  file: FileObject;
  data: object & { invoice_id: number; inspection_plan_id: number };
  qr_code_identifier: string | null;
  additional_notes?: string;
  order_reference: string;
  vehicle: Vehicle;
  idsb: boolean;
  documents: Array<Document>;
  conversation: Conversation;
  legacy: boolean;
}

export interface ServiceHistory {
  id: number;
  status: number;
  status_reason: number;
  user_readable_id: string;
  vehicle_id?: number;
  workshop_id: number;
  file_id?: number;
  file: FileObject;
  vehicle: Vehicle;
  consent_charge?: boolean;
}

export type Service = {
  id: number;
  workshop_id: number;
  vehicle: Vehicle;
  vehicle_id: number;
  created_at: string;
  completed_at: string;
  documents: Array<Document>;
  service_activities: Array<ServiceActivity>;
  features: {
    vehicle_identification: string;
    service_history_request: string;
    inspection_plan: string;
    service_record: string;
    uploaded_documents: string;
    sent_documents: string;
  };
};

export interface UpdateBillingEmailForm {
  billing_email: string;
}

export interface Invoices {
  id: string;
  date: Date;
  total?: number;
  url: string;
}

export interface Distributor {
  id: number;
  name: string;
  logo_url: string;
  prepaid_plan: {
    id: number;
    slug: string;
    order: number;
    monthly_sr_quota: number;
    monthly_shr_quota: number;
    is_pay_per_use: number;
    created_at: Date;
    updated_at: Date;
    price: Price;
    charges: { [key: string]: Charge };
    yearly_savings: number;
    pre_selected: boolean;
    available_addons: SubscriptionAddon[];
  };
}

export interface DataForEndpoint {
  qr_code_identifier: string;
}

export interface ConversationMessageShow {
  id: number;
  created_at: string;
  updated_at: string;
  text: string;
  translated_text: string;
  document: Document;
  author: string;
  workshop_user_id: number;
  user_id: number;
}

export interface ConversationShow {
  id: number;
  created_at: string;
  updated_at: string;
  key: string;
  messages: ConversationMessageShow[];
}

export interface SystemPartner {
  id: number;
  name: string;
  logo_url: string;
}
