import {
  Box,
  Flex,
  Grid,
  Heading,
  Icon,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { createDropdownStaticOptionsWithMutator, validateVat } from 'helpers/general';
import { get } from 'lodash';
import { postcodeValidator } from 'postcode-validator';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FiArrowRight } from 'react-icons/fi';
// @ts-ignore
import useGeoLocation from 'react-ipgeolocation';
import { useMutation } from 'react-query';
import { useAsyncDebounce } from 'react-table';
import * as yup from 'yup';

import { Methods, PartialStateCode } from '../../../api/types';
import { PortalButton } from '../../../components/buttons/PortalButton';
import CheckBoxDescription from '../../../components/labels/CheckBoxDescription';
import AlertModal from '../../../components/modals/AlertModal';
import { FONT_WEIGHTS } from '../../../global/Fonts';
import { formatTelephone, telephonePrefixesOptions } from '../../../helpers/localization';
import { dropdown, simpleCheckBox, simpleInput } from '../../../helpers/makeFormFields';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';

const FORMFIELD_CONFIG = {
  spacing: 2,
};

export interface ISignupForm {
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  name: string;
  vat_number: string | undefined | null;
  country: string;
  language: string;
  city: string;
  zipcode: string;
  address: string;
  consent_termsandconditions: any;
  consent_dataprivacy: any;
  consent_newsletter: any;
  telephone_prefix?: string;
}

const SignupStep = (props: {
  saveInState(x: any): void;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  data: Partial<WorkshopData>;
  setStep(y: number): void;
  method?: Methods;
  url: string;
  localizationProps: {
    countries: string[];
    languages: { [country: string]: string[] };
    zipcodeLength: PartialStateCode<string>;
    vatFormatting: PartialStateCode<string>;
    telephonePrefixes: PartialStateCode<string>;
  };
}) => {
  const posthog = usePostHog();
  const abTestSignupFormNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' ||
    posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';
  const [sendPostHogEvent, setSendPostHogEvent] = useState(true);

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const location = useGeoLocation();

  const {
    setUserEmail,
    saveInState,
    data,
    setStep,
    method = 'post',
    url,
    localizationProps: {
      countries = [],
      languages = {},
      vatFormatting = {},
      telephonePrefixes = {},
    },
  } = props;

  const countryDropdownOptions = createDropdownStaticOptionsWithMutator({
    records: countries,
    mutator: (val) =>
      i18n.exists(`location:countries.${val.toUpperCase()}`)
        ? t(`location:countries.${val.toUpperCase()}`)
        : val.toUpperCase(),
    t,
  });

  const getLanguageDropdownOptions = (country: string) => {
    if (!country) {
      return [];
    }
    return createDropdownStaticOptionsWithMutator({
      records: languages[country],
      mutator: (val) => t(`location:languages.${val.toUpperCase()}`),
      t,
    });
  };

  const schema = yup
    .object({
      firstname: yup.string().required(t('forms:firstname.error_message')),
      lastname: yup.string().required(t('forms:lastname.error_message')),
      email: yup
        .string()
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .required(t('forms:email.error_message')),
      telephone: yup.string().required(t('forms:telephone.error_message')),
      telephone_prefix: yup.string().label(t('forms:telephone.label')).required(),
      name: yup.string().required(t('forms:name.error_message')),
      vat_number: yup.string().nullable().notRequired(),
      country: yup
        .string()
        .oneOf(countries, t('errors:countries.valid'))
        .required(t('forms:country.error_message')),
      language: yup.string().required(t('forms:language.error_message')),
      city: yup.string().required(t('forms:city.error_message')),
      zipcode: yup
        .string()
        .test('zipcode', t('forms:zipcode.invalid'), (val = '', context) => {
          if (val && context) {
            return postcodeValidator(
              val,
              context?.parent?.country === 'XI' ? 'GB' : context?.parent?.country,
            );
          } else return false;
        }),
      address: yup.string().required(t('forms:address.error_message')),
      consent_termsandconditions: yup
        .bool()
        .label(t('forms:consent_termsandconditions.label'))
        .required()
        .oneOf([true], t('common:required')),
      consent_dataprivacy: yup
        .boolean()
        .label(t('forms:consent_dataprivacy.label'))
        .required()
        .oneOf([true], t('common:required')),
      consent_newsletter: yup.boolean(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setFocus,
    setValue,
    clearErrors,
    formState: { isValid, submitCount, errors, isValidating },
    setError,
    getValues,
  } = useForm<ISignupForm>({
    defaultValues: {
      country: data?.country ?? location?.country,
      language: undefined,
      name: '',
      vat_number: undefined,
      address: '',
      city: '',
      zipcode: undefined,
      firstname: '',
      lastname: '',
      email: '',
      telephone_prefix: '',
      telephone: '',
      consent_dataprivacy: undefined,
      consent_newsletter: false,
      consent_termsandconditions: undefined,
      distributor_id: null,
      registration_form_key: undefined,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const country = watch('country');
  const selectedLanguage = watch('language');
  const workshopVat = watch('vat_number');

  useEffect(() => {
    reset({
      ...data,
      country: data?.country ?? location?.country,
    });
  }, [data, countries]);

  useEffect(() => {
    if (country) {
      setValue('telephone_prefix', telephonePrefixes[country]);

      const currenLanguages: string[] = get(languages, country);
      if (currenLanguages) {
        setValue('language', currenLanguages[0]);
      }
    }
  }, [country]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field: any, a: any) => {
      // @ts-ignore
      // @ts-ignore
      return errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const { mutate, isLoading } = useMutation<any, Error, ISignupForm>(async (data) => {
    const requestData = { ...data, telephone: data?.telephone_prefix + data?.telephone };
    delete requestData?.telephone_prefix;

    return await axios[method](`${url}`, requestData).catch((err: any) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
      return Promise.reject(err);
    });
  });
  const onSubmit: SubmitHandler<ISignupForm> = (formData) => {
    setUserEmail(formData.email);
    mutate(
      {
        ...formData,
        vat_number: workshopVat ? workshopVat : null,
        country: country === 'XI' ? 'GB' : country,
      },
      {
        onSuccess: (response = {}) => {
          posthog.capture('email_verification_pageview');
          saveInState({ ...formData, ...response?.data });
          //if email is verified, skip that step
          if (response?.data.email_verified) {
            setStep(2);
          } else {
            setStep(1);
          }
        },
        onError: (error: any) => {
          const { errors } = error.response.data;
          (Object.keys(errors) as Array<keyof ISignupForm>).forEach((key) => {
            setError(key, {
              type: 'server',
              message: errors[key]!.join('. '),
            });
          });
        },
      },
    );
  };
  const onChangeVat = useAsyncDebounce((val) => {
    // @ts-ignore
    if (country === 'CH' || country === 'LI') {
      if (val.length > 20) {
        setError('vat_number', {
          type: 'server',
          message: t('forms:vat_number.too_long'),
        });
      } else {
        clearErrors('vat_number');
      }
    } else {
      validateVat(val, country).then((data: boolean) => {
        // other solution is to build a custom flag, and fake the error messages on the vat input field
        if (!data) {
          setError('vat_number', {
            type: 'server',
            message: t('forms:vat_number.invalid'),
          });
          setTimeout(() => {
            setFocus('vat_number');
          }, 100);
        } else {
          clearErrors('vat_number');
        }
      });
    }
  }, 3000);

  const [vatNumber, setVatnumber] = useState('');

  useEffect(() => {
    clearErrors('language');
    clearErrors('telephone_prefix');
  }, [country]);

  watch((data) => {
    if (data.language !== i18n.language) {
      i18n.changeLanguage(data.language);
    }
  });

  if (sendPostHogEvent) {
    posthog.capture('signup_form_pageview');
    setSendPostHogEvent(false);
  }

  const formWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        data-test-id="signup-form"
        px={abTestSignupFormNewDesign ? 0 : 8}
        mx={abTestSignupFormNewDesign ? 0 : 12}
        width={abTestSignupFormNewDesign ? formWidth : 'full'}
      >
        <Stack spacing="5">
          {!abTestSignupFormNewDesign && (
            <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold}>
              {t('workshop:registration.stepper.one.info.company.label')}
            </Text>
          )}

          {abTestSignupFormNewDesign && (
            <>
              <Heading size="xs">{t('workshop:registration.label')}</Heading>
              <Text color="muted" fontSize="sm" pb={'5'}>
                {t('workshop:registration.stepper.one.description')}
              </Text>
            </>
          )}

          <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
            {abTestSignupFormNewDesign && (
              <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold} mb={'5'}>
                {t('workshop:registration.stepper.one.info.company.label')}
              </Text>
            )}
            <Stack
              spacing="6"
              direction={{ base: 'column', md: 'row' }}
              py={FORMFIELD_CONFIG.spacing}
            >
              {dropdown({
                name: 'country',
                label: t('forms:country.label'),
                control,
                errors,
                register,
                options: countryDropdownOptions,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                hideDropdownNoValueOption: true,
              })}
              {dropdown({
                name: 'language',
                label: t('forms:language.label'),
                control,
                errors,
                register,
                options: getLanguageDropdownOptions(country),
                hideDropdownNoValueOption: true,
                schema,
                customClass: 'pii',
                disabled: getLanguageDropdownOptions(country).length < 2,
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                placeholder: abTestSignupFormNewDesign
                  ? t('forms:language.label')
                  : undefined,
                placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
              })}
            </Stack>
            <Stack
              spacing="6"
              direction={{ base: 'column', md: 'row' }}
              py={FORMFIELD_CONFIG.spacing}
            >
              {simpleInput({
                name: 'name',
                label: t('forms:name.label'),
                placeholder: t('forms:name.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
              })}

              {simpleInput({
                name: 'vat_number',
                label: t('forms:vat_number.label'),
                placeholder: t('forms:vat_number.placeholder'),
                register,
                description: t('forms:vat_number.description'),
                showAsTooltip: true,
                errors,
                disabled: !country,
                customClass: 'pii',
                schema,
                leftAddon: country !== 'CH' ? country : 'CHE',
                customHook: (val) => {
                  setVatnumber(val);
                  const formatter = get(vatFormatting, country);
                  if (formatter) {
                    setValue('vat_number', formatter(val));
                  }
                  onChangeVat(val);
                },
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
              })}
            </Stack>
            <Stack
              spacing="6"
              direction={{ base: 'column', md: 'row' }}
              py={FORMFIELD_CONFIG.spacing}
            >
              {simpleInput({
                name: 'address',
                label: t('forms:address.label'),
                placeholder: t('forms:address.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                //width: inputWidthBig,
              })}
              {simpleInput({
                name: 'city',
                label: t('forms:city.label'),
                placeholder: t('forms:city.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                //width: inputWidthSmall,
              })}
              {simpleInput({
                name: 'zipcode',
                label: t('forms:zipcode.label'),
                placeholder: t('forms:zipcode.placeholder'),
                register,
                errors,
                customHook: (val) => setValue('zipcode', val.replace(/ /g, '')),
                schema,
                disabled: !country,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                //width: inputWidthSmall,
              })}
            </Stack>
          </Box>

          {!abTestSignupFormNewDesign && (
            <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold} mb={'5'}>
              {t('workshop:registration.stepper.one.info.contact.label')}
            </Text>
          )}
          <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
            {abTestSignupFormNewDesign && (
              <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold} mb={'5'}>
                {t('workshop:registration.stepper.one.info.contact.label')}
              </Text>
            )}
            <Stack
              spacing="6"
              direction={{ base: 'column', md: 'row' }}
              py={FORMFIELD_CONFIG.spacing}
            >
              {simpleInput({
                name: 'firstname',
                label: t('forms:firstname.label'),
                placeholder: t('forms:firstname.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
              })}
              {simpleInput({
                name: 'lastname',
                label: t('forms:lastname.label'),
                placeholder: t('forms:lastname.placeholder'),
                register,
                errors,
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
              })}
            </Stack>

            <Grid
              gap="6"
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              py={FORMFIELD_CONFIG.spacing}
            >
              {simpleInput({
                name: 'email',
                label: t('forms:email.label'),
                placeholder: t('forms:email.placeholder'),
                register,
                errors,
                customHook: (val) => setValue('email', val.replace(/ /g, '')),
                schema,
                customClass: 'pii',
                boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
              })}

              <Grid
                templateColumns={'7rem 1fr'}
                gap={2}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box mt={2}>
                  <Tooltip label={`${t('forms:telephone.info')}`}>
                    <Text fontSize="xs" width="max">{`${t(
                      'forms:telephone.label',
                    )} *`}</Text>
                  </Tooltip>
                  {dropdown({
                    name: 'telephone_prefix',
                    control,
                    errors,
                    register,
                    schema,
                    options: telephonePrefixesOptions(telephonePrefixes, t),
                    boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                    bgColor: abTestSignupFormNewDesign ? 'white' : undefined,
                    placeHolderColor: abTestSignupFormNewDesign ? 'gray.400' : undefined,
                    placeholder: t('forms:telephone_prefix.placeholder'),
                    hideDropdownNoValueOption: true,
                    borderRadius: '8',
                  })}
                </Box>

                <Box mt={'1'}>
                  {/* rendering not visble text for alignment with prefix dropdown */}
                  <Text fontSize="sm" color={'transparent'}>
                    Inv
                  </Text>

                  {simpleInput({
                    name: 'telephone',
                    placeholder: t('forms:telephone.placeholder'),
                    customHook: (val) =>
                      setValue(
                        'telephone',
                        formatTelephone(val, getValues('telephone_prefix')),
                      ),
                    register,
                    errors,
                    schema,
                    customClass: 'pii',
                    boxShadow: abTestSignupFormNewDesign ? 'md' : undefined,
                  })}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {!abTestSignupFormNewDesign && (
            <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold} mb={'5'}>
              {t('forms:consent_termsandconditions.label')}
            </Text>
          )}

          <Box pl={abTestSignupFormNewDesign ? {} : { base: 0, lg: 4 }}>
            {abTestSignupFormNewDesign && (
              <Text fontSize="lg" fontWeight={FONT_WEIGHTS.bold} mb={'5'}>
                {t('forms:consent_termsandconditions.label')}
              </Text>
            )}
            {simpleCheckBox({
              disabled: !selectedLanguage,
              name: 'consent_termsandconditions',
              label: t('forms:consent_termsandconditions.label'),
              description: (
                <CheckBoxDescription
                  country={country}
                  selectedLanguage={selectedLanguage}
                  translationKey={'forms:consent_termsandconditions.info'}
                />
              ),
              register,
              errors,
              schema,
              borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
              borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
            })}

            {simpleCheckBox({
              disabled: !selectedLanguage,
              name: 'consent_dataprivacy',
              label: t('forms:consent_dataprivacy.label'),
              description: (
                <CheckBoxDescription
                  country={country}
                  selectedLanguage={selectedLanguage}
                  translationKey={'forms:consent_dataprivacy.info'}
                />
              ),
              register,
              errors,
              schema,
              borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
              borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
            })}
            {simpleCheckBox({
              name: 'consent_newsletter',
              label: t('forms:consent_newsletter.label'),
              description: t('forms:consent_newsletter.info'),
              register,
              errors,
              schema,
              borderColor: abTestSignupFormNewDesign ? 'gray.300' : undefined,
              borderRadius: abTestSignupFormNewDesign ? 'xs' : undefined,
            })}
          </Box>
        </Stack>

        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
          {!workshopVat && !isValidating && isValid ? (
            <PortalButton
              data-test-id="signup-next-button-no-vat"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
              onClick={() => {
                NiceModal.show(AlertModal, {
                  children: (
                    <Trans i18nKey={'workshop:registration.without_vat.modal.body'} />
                  ),
                  onSubmit: () => onSubmit(getValues()),
                  content: {
                    header: t('workshop:registration.without_vat.modal.title'),
                    footer: {
                      buttons: {
                        cancelCaption: t(
                          'workshop:registration.without_vat.modal.buttons.cancel',
                        ),
                        actionCaption: t('common:next'),
                      },
                    },
                  },
                });
              }}
            >
              {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
            </PortalButton>
          ) : (
            <PortalButton
              type="submit"
              data-test-id="signup-next-button"
              disabled={(submitCount! > 0 && !isValid) || isLoading}
              isLoading={isLoading}
            >
              {t('common:next')} <Icon as={FiArrowRight} boxSize="4" />
            </PortalButton>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default SignupStep;
