import * as Sentry from '@sentry/browser';
import useTokenQuery from 'hooks/queries/useTokenQuery';
import { QueryCache, QueryClient } from 'react-query';

import { DURATION } from '../constants';

const queryClient: any = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: DURATION.Hour,
      staleTime: DURATION.Minute * 5,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (error.response?.status >= 400 && error.response?.status < 500) {
        Sentry.captureException(error);
      }
      if (error?.response?.status === 401) {
        queryClient.invalidateQueries(useTokenQuery.getKey());
      }
    },
  }),
});

export { queryClient };
