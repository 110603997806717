import {
  Box,
  BoxProps,
  Button,
  CloseButton,
  Container,
  Flex,
  Icon,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';

interface IBannerProps extends BoxProps {
  fontWeight?: string;
  url?: string;
  hasIcon?: boolean | undefined;
  icon?: undefined | any;
  iconColor?: string;
  hideCloseButton?: boolean;
  hideActionButton?: boolean;
  actionButtonText?: string;
  bannerTextHeadline?: string;
  bannerText?: string;
  hasI18nHeadline?: boolean;
}

export const Banner = (props: IBannerProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    fontWeight = 'medium',
    bgColor = 'bg-accent',
    color = 'inherit',
    hasIcon = false,
    icon = FiInfo,
    hideCloseButton = true,
    hideActionButton = false,
    actionButtonText,
    bannerTextHeadline,
    bannerText,
    url,
    iconColor = 'darkred',
    hasI18nHeadline = false,
    ...rest
  } = props;

  return (
    <Box as="section" {...rest} bg={bgColor}>
      <Container py={{ base: '4', md: '2.5' }} position="relative" maxW="unset">
        {!hideCloseButton && (
          <CloseButton display={{ sm: 'none' }} position="absolute" right="2" top="2" />
        )}
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="center"
          spacing={{ base: '3', md: '2' }}
        >
          <Stack spacing="4" direction={{ base: 'column', md: 'row' }} align={'center'}>
            <Flex alignItems="center" justify="center">
              {!isMobile && hasIcon && (
                <Square size="10" borderRadius="md" mr={5}>
                  <Icon color={iconColor} as={icon} boxSize="10" />
                </Square>
              )}

              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '0.5', md: '1.5' }}
                pe={{ base: '4', sm: '0' }}
                color={color}
              >
                {hasI18nHeadline ? (
                  <Trans i18nKey={bannerTextHeadline} />
                ) : (
                  <Text fontWeight={fontWeight}>{bannerTextHeadline}</Text>
                )}

                <Text whiteSpace={'pre-wrap'} pl={isMobile ? 5 : 'inherit'}>
                  {bannerText}
                </Text>
              </Stack>
            </Flex>

            {!hideActionButton && (
              <Button
                as={'a'}
                href={url}
                bg="rgb(63, 125, 193)"
                variant="primary"
                flex="left"
                size="sm"
                color="on-accent-muted"
                minWidth={180}
              >
                {actionButtonText}
              </Button>
            )}
          </Stack>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: '3', sm: '2' }}
            align={{ base: 'stretch', sm: 'center' }}
          >
            {!hideCloseButton && (
              <CloseButton display={{ base: 'none', sm: 'inline-flex' }} />
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
