import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Vehicle } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getVehicles(props: {
  axios: AxiosInstance;
  itemsPerPage?: number;
  page?: number;
}): Promise<Array<Vehicle>> {
  const { axios, itemsPerPage, page } = props;
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/vehicle-identifications`,
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  );
  return data;
}

export default function useIdentifiedVehiclesQuery(page?: number, itemsPerPage?: number) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(
    getKey(page, itemsPerPage),
    () => getVehicles({ axios, page, itemsPerPage }),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'error'],
      retry: 6,
      staleTime: DURATION.Minute * 5,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error),
          t,
        });
      },
    },
  );
}

function getKey(page?: number, itemsPerPage?: number): QueryKey {
  return ['Identified Vehicles', page, itemsPerPage];
}

useIdentifiedVehiclesQuery.getKey = getKey;
