import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { StatusCodes } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import useTokenQuery from './useTokenQuery';
async function getStatus(axios: AxiosInstance): Promise<StatusCodes> {
  const { data } = await axios.get(`${config.apiBaseUrl}/utilities/status`);
  return data;
}

export default function useStatusQuery() {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tokenQuery = useTokenQuery();
  return useQuery(getKey(), () => getStatus(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    staleTime: DURATION.Minute * 5,
    cacheTime: DURATION.Hour,
    retry: 6,
    refetchOnWindowFocus: false,
    enabled: !!tokenQuery.data,
    onError: (error: any) => {
      manageErrorResponse({ toastInstance: toast, error: JSON.stringify(error), t });
      if (error.response.status >= 500) {
        navigate('/app/error', { replace: true });
      }
    },
  });
}

function getKey(): QueryKey {
  return ['Status'];
}

useStatusQuery.getKey = getKey;
