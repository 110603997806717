import { Box, Image, useBreakpointValue } from '@chakra-ui/react';
import * as React from 'react';

export function CustomerLogoBar() {
  const logoWidth = useBreakpointValue({
    base: '20px',
    sm: '55px',
    md: '80px',
    lg: '120px',
    xl: '120px',
    '2xl': '120px',
  });
  const firstLogoWidth = useBreakpointValue({
    base: '20px',
    sm: '40px',
    md: '60px',
    lg: '90px',
    xl: '90px',
    '2xl': '90px',
  });

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const logoUrls = [
    '/images/customerLogos/birner_logo.svg',
    '/images/customerLogos/coparts_logo.svg',
    '/images/customerLogos/foerch_logo.svg',
    '/images/customerLogos/gas_logo.svg',
    '/images/customerLogos/haynespro_logo.svg',
    '/images/customerLogos/hg_logo.svg',
    '/images/customerLogos/neimcke_logo.svg',
    '/images/customerLogos/launch_logo.svg',
  ];

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      pt={isMobile ? '10' : '-30'}
    >
      {!isMobile &&
        logoUrls.map((logoUrl, index) => {
          return (
            <Image
              key={index}
              src={`${logoUrl}`}
              alt={`${logoUrl}`}
              boxSize={index === 0 ? firstLogoWidth : logoWidth}
              mr={3}
            />
          );
        })}
    </Box>
  );
}
export default CustomerLogoBar;
