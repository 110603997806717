import { Button, Grid, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '../../../layout/Card';

const VerificationPendingCard: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Grid
      w={['full']}
      h={'full'}
      placeItems={'center'}
      position={'absolute'}
      inset={0}
      px={{ base: 4, lg: 8 }}
    >
      <Card p="20" textAlign="center">
        <Heading as="h6" size="sm" p={2}>
          {t('pages:workshop_verification_request.pending.header')}
        </Heading>
        <Text color="muted" fontSize="sm" p={2}>
          {t('pages:workshop_verification_request.pending.message')}
        </Text>

        <Button
          colorScheme="blue"
          as={'a'}
          href={'/app/w/dashboard'}
          variant="solid"
          size="sm"
          my="2"
        >
          {t('pages:workshop_verification_request.pending.action')}
        </Button>
      </Card>
    </Grid>
  );
};

export default VerificationPendingCard;
