import {
  Box,
  Button,
  Grid,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { get, isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BILLING_CYCLE, CHARGE_KEYS, SubscriptionPlan } from '../../../../api/types';
import { CornerRibbon } from '../../../../components/CornerRibbon';
import PriceInCard from '../../../../components/PriceInCard';
import { FONT_WEIGHTS } from '../../../../global/Fonts';
import { formatMultiCurrencyIntl } from '../../../../helpers/formatCurrency';
import useWorkshopAxiosContextQuery from '../../../../hooks/queries/workshop/useWorkshopAxiosContextQuery';

export interface PlanProps {
  abTestNewDesign?: boolean;
  plan: SubscriptionPlan;
  onChange: (selectedPlanId: string) => void;
  billingCycle: BILLING_CYCLE;
  hideSelectButton?: boolean;
  hideSetupFee: boolean;
  isSelected?: boolean;
  isRecommended?: boolean;
  isOwn?: boolean;
  isActive?: boolean;
  isHovered?: boolean;
  isCreate?: boolean;
  customButtonText?: string;
  recommendedPlanId: number;
}

// States: default, selected, activated, hover - how to map with Radio Props
export const Plan = (props: PlanProps) => {
  const { t } = useTranslation();
  const {
    plan: {
      id,
      slug,
      name,
      is_pay_per_use,
      pre_selected,
      monthly_shr_quota,
      monthly_sr_quota,
      charges = {},
      price,
    },
    billingCycle,
    hideSetupFee,
    isOwn = false,
    hideSelectButton = false,
    customButtonText = false,
    onChange,
    isSelected = false,
    isRecommended = false,
    isActive = false,
    isHovered = false,
    isCreate = false,
    recommendedPlanId,
    plan,
    abTestNewDesign,
  } = props;

  const stateStyles = {
    isActive: {
      backgroundColor: 'gray.300',
      color: 'accent',
    },

    isRecommended: {
      border: '4px',
      backgroundColor: 'white',
      color: 'accent',
    },
    isSelected: {
      backgroundColor: 'accent',
      color: 'white',
    },
    isHovered: {
      backgroundColor: abTestNewDesign ? 'blue.400' : 'gray.200',
      color: 'accent',
      border: plan?.id === recommendedPlanId ? '4px' : '0px',
    },
    isDisabled: {
      backgroundColor: 'gray.300',
      color: 'accent',
    },
    default: {
      color: 'accent',
      backgroundColor: 'white',
      border: '4px',
      borderColor: 'white',
    },
  };

  const { data: workshop } = useWorkshopAxiosContextQuery();
  const currency = workshop?.currency;
  const currentPrice = price[billingCycle];
  const setup_fee = charges?.setup_fee?.price;
  const getStateStyles = (stateKey: string) => {
    return get(stateStyles, stateKey, stateStyles.default);
  };
  const componentStyles = isActive
    ? getStateStyles('isActive')
    : isSelected
      ? getStateStyles('isSelected')
      : isHovered
        ? getStateStyles('isHovered')
        : isRecommended
          ? getStateStyles('isRecommended')
          : getStateStyles('default');

  //Hovering: If you want to remove the zoom in effect on hover remove border and borderColor from stateStyles.default
  const isOnPromotion = isActive ? false : !isEmpty(currentPrice?.discount);

  const displayableFeaturesIds = [
    {
      slug: CHARGE_KEYS.SERVICE_RECORD,
      quota: monthly_sr_quota,
      amount: charges[CHARGE_KEYS.SERVICE_RECORD]?.price,
    },
    {
      slug: CHARGE_KEYS.SERVICE_HISTORY_REQUEST,
      quota: monthly_shr_quota,
      amount: charges[CHARGE_KEYS.SERVICE_HISTORY_REQUEST]?.price,
    },
  ];
  return (
    <Box position={'relative'} h={'full'}>
      {isOnPromotion && !is_pay_per_use && !isOwn ? (
        <CornerRibbon>{t('promotions:general.label')}</CornerRibbon>
      ) : null}

      <Grid
        data-test-id={'box_plan_' + id}
        padding={{ base: 4, lg: 4 }}
        h={'full'}
        w={'full'}
        {...{ ...componentStyles, border: '0px' }}
        textAlign={'left'}
      >
        {name && (
          <Heading lineHeight={1.2} size={'sm'} fontWeight={FONT_WEIGHTS.bold} as={'h3'}>
            {name}
          </Heading>
        )}
        <VStack mt={10} spacing={6} alignItems={'flex-start'}>
          <PriceInCard
            t={t}
            value={currentPrice?.amount}
            billingCycle={billingCycle}
            discount={!is_pay_per_use && !isOwn ? currentPrice?.discount : undefined}
          />

          <Box>
            <List spacing={0}>
              {!hideSetupFee && (
                <ListItem key={'serviceFee'}>
                  <Stack
                    spacing={2}
                    justifyContent={'flex-start'}
                    textAlign={'left'}
                    flex={0}
                    paddingBlock={3}
                    fontWeight={FONT_WEIGHTS.bold}
                  >
                    {setup_fee ? (
                      <Text fontSize={'sm'} lineHeight={1} flex={1}>
                        {formatMultiCurrencyIntl(setup_fee, currency)}{' '}
                        {t('pages:subscription.charges.setup_fee')}
                      </Text>
                    ) : (
                      <Text fontSize={'sm'} lineHeight={1} flex={1}>
                        {t('pages:subscription.charges.no_setup_fee')}
                      </Text>
                    )}
                  </Stack>
                </ListItem>
              )}
              {displayableFeaturesIds.map(
                (
                  charge: { slug: string; quota: number; amount: number },
                  index: number,
                ) => {
                  return (
                    <ListItem key={index}>
                      <Stack
                        spacing={1}
                        justifyContent={'flex-start'}
                        textAlign={'left'}
                        flex={0}
                        paddingBlock={3}
                      >
                        <Text fontSize={'sm'} lineHeight={1}>
                          {`${charge?.quota} ${t(
                            `pages:subscription.features.${charge.slug}`,
                          )}`}
                        </Text>
                        {index === 0 ? (
                          <Text fontSize={'sm'} color={'default'} lineHeight={1}>
                            {`${t(
                              'pages:subscription.features.foreach_additional_sr',
                            )} ${formatMultiCurrencyIntl(charge?.amount, currency)}`}
                          </Text>
                        ) : null}
                        {index === 1 ? (
                          <Text fontSize={'sm'} color={'default'} lineHeight={1}>
                            {`${t(
                              'pages:subscription.features.foreach_additional_shr',
                            )} ${formatMultiCurrencyIntl(charge?.amount, currency)}`}
                          </Text>
                        ) : null}
                      </Stack>
                    </ListItem>
                  );
                },
              )}
            </List>
          </Box>
        </VStack>
        {!hideSelectButton && (
          <Button
            variant={isActive || isSelected ? 'outline' : 'primary'}
            color={isActive || isSelected ? '' : 'white'}
            borderColor={isActive || isSelected ? 'white' : ''}
            backgroundColor={isActive || isSelected ? 'transparent' : ''}
            alignSelf={'center'}
            w={'full'}
            onClick={() => onChange(slug)}
          >
            {customButtonText ?? t('pages:subscription.create.plans.select')}
          </Button>
        )}
      </Grid>
    </Box>
  );
};
