import { Collapse, Container, Grid, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useIsWorkshopBlocked } from 'helpers/general';
import { isNil } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosInformationCircle, IoIosWarning } from 'react-icons/io';
import { Outlet, useParams } from 'react-router-dom';

import { Banner } from '../components/Banner';
import config from '../config';
import { STATUS_REJECTED, useUserContext } from '../context/UserContextProvider';
import StarterTour from '../features/dashboard/StarterTour';
import { Environments, isTestingEnvironment } from '../helpers/environments';
import { getIsDesktop } from '../helpers/layout';
import useUpdateUserTourMetaDataMutation from '../hooks/private/mutations/useUpdateUserTourMetaDataMutation';
import useAccountsQuery from '../hooks/queries/useAccountsQuery';
import useAnnouncementQuery from '../hooks/queries/useAnnouncementQuery';
import { DebuggingBanner } from './DebuggingBanner';
import { Navbar } from './navigation/Navbar';
import { Sidebar } from './navigation/Sidebar';

type MainLayoutProps = {
  children?: React.ReactNode;
};

export const MainLayout: React.FC<MainLayoutProps> = () => {
  const { t } = useTranslation();
  const { workshop, user } = useUserContext();
  const updateUserTourMetaDataMutation = useUpdateUserTourMetaDataMutation();
  const announcementQuery = useAnnouncementQuery();
  const announcement = announcementQuery.data || [];
  const youngestVerification = workshop?.verifications[0];

  let shouldRenderBanner = false;
  let bgColor = 'blue';
  let bannerText = '';
  let icon;
  let iconColor;
  const accountsQuery = useAccountsQuery();
  const urlParams = useParams();
  const notRegisteredMakesStatusArray = [];
  accountsQuery.data?.map((account) => {
    if (!account?.can_access_dsb) {
      return notRegisteredMakesStatusArray.push(account.status);
    }
  });
  const allMakesRegistered = notRegisteredMakesStatusArray.length === 0;

  let hideActionButton = !!(
    urlParams['*']?.includes('account/verification') ||
    urlParams['*']?.includes('account-registration-status')
  );

  const isWorkshopBlocked = useIsWorkshopBlocked();

  let hideBanner = urlParams['*']?.includes('account/subscription/cost-overview');

  if (workshop?.is_verified && !allMakesRegistered) {
    shouldRenderBanner = true;
    bgColor = 'orange.200';
    bannerText = t(
      'pages:subscription.workshop_verification_request.form.banner.make_account_not_registered',
    );
    icon = IoIosInformationCircle;
    iconColor = 'orange';
  }

  // workshop is not verified, just signed up
  if (
    !workshop?.is_verified &&
    !workshop?.is_verification_pending &&
    workshop?.verifications.length === 0 &&
    workshop?.update_requests.length === 0
  ) {
    shouldRenderBanner = true;
    bgColor = 'red.200';
    bannerText = t('pages:subscription.workshop_verification_request.form.banner.verify');
    icon = IoIosWarning;
    iconColor = 'darkred';
    // hideActionButton = false;
  }

  if (workshop?.is_verification_pending) {
    bannerText = t(
      'pages:subscription.workshop_verification_request.form.banner.pending',
    );
    shouldRenderBanner = true;
    bgColor = 'orange.200';
    hideActionButton = true;
  }

  // workshop is verified, but there is a verification pending that is NOT referencing a DUR (aka. verification.workshop_update_request_id = NULL)
  if (
    !workshop?.is_verified &&
    !workshop?.is_verification_pending &&
    workshop?.verifications?.[0]?.workshop_update_request_id === null
  ) {
    shouldRenderBanner = true;
    bgColor = 'red.200';
    bannerText = t(
      'pages:subscription.workshop_verification_request.form.banner.kindly_verify',
    );
    icon = IoIosWarning;
    iconColor = 'darkred';
    hideActionButton = false;
  }

  // workshop is verified, there is a pending DUR , and the latest verification is in status created and it references the pending DUR
  if (
    !workshop?.is_verified &&
    workshop?.update_requests?.[0]?.id ===
      workshop?.verifications?.[0]?.workshop_update_request_id &&
    workshop?.verifications?.[0]?.status === 0
  ) {
    bgColor = 'red.200';
    icon = IoIosWarning;
    iconColor = 'darkred';
    hideActionButton = !!(
      urlParams['*']?.includes('account/verification') ||
      urlParams['*']?.includes('account-registration-status')
    );
    bannerText = t(
      'pages:subscription.workshop_verification_request.form.banner.reverify',
    );
  }

  //workshop is verified, there is a pending DUR, the latest verification is in status rejected and references the pending DUR
  if (
    !workshop?.is_verified &&
    workshop?.update_requests?.[0]?.id ===
      workshop?.verifications?.[0]?.workshop_update_request_id &&
    workshop?.verifications?.[0]?.status === 900
  ) {
    bannerText = t(
      'pages:subscription.workshop_verification_request.form.banner.rejected',
    );
    bgColor = 'red.200';
    icon = IoIosWarning;
    iconColor = 'darkred';
    hideActionButton = true;
    shouldRenderBanner = true;
  }

  const hasSeenStarterTour = user?.tour_meta_data?.seen_starter_tour;

  const tourMetaData = {
    tour_meta_data: {
      ...user?.tour_meta_data,
      seen_starter_tour: true,
    },
  };
  const [startTour, setStartTour] = useState(false);
  setTimeout(() => {
    setStartTour(true);
  }, 2000);

  const hasFailedPayment = workshop?.has_failed_payments;

  return (
    <>
      <Grid
        templateColumns={{
          base: 'var(--chakra-sizes-full)',
          lg: 'var(--chakra-sizes-xs) 1fr',
        }}
        templateRows={{
          base: 'auto 1fr',
          lg: 'auto',
        }}
        as="section"
        bg="bg-canvas"
        overflowY="hidden"
        h="100vh"
      >
        {getIsDesktop() ? <Sidebar /> : <Navbar />}

        <Container
          px={0}
          flex="1"
          maxW="unset"
          textAlign="left"
          centerContent={false}
          pos={'relative'}
          overflow={'auto'}
        >
          {(isTestingEnvironment() &&
            config.appEnv !== Environments.STAGE &&
            config.appEnv !== Environments.QA) ||
          user?.type === 'support' ? (
            <DebuggingBanner type={user?.type} />
          ) : null}

          <Collapse
            in={hasFailedPayment && !hideBanner && !isWorkshopBlocked}
            animateOpacity
          >
            <Banner
              bgColor="red.200"
              actionButtonText={t('sidebar:account.costs')}
              bannerText={t('workshop:failed_payment.label')}
              url={'/app/w/account/subscription/cost-overview'}
              hasIcon={true}
              icon={icon}
              iconColor="red.500"
            />
          </Collapse>

          <Collapse in={shouldRenderBanner} animateOpacity>
            <Banner
              data-test-id="verification-banner"
              bgColor={bgColor}
              hideActionButton={hideActionButton}
              actionButtonText={
                workshop?.is_verified && !allMakesRegistered
                  ? t(
                      'pages:subscription.workshop_verification_request.form.banner.button.view_make_status',
                    )
                  : youngestVerification?.status === STATUS_REJECTED
                    ? t(
                        'pages:subscription.workshop_verification_request.form.banner.button.restart',
                      )
                    : t(
                        'pages:subscription.workshop_verification_request.form.banner.button.start',
                      )
              }
              bannerText={bannerText}
              url={
                workshop?.is_verified && !allMakesRegistered
                  ? '/app/w/account-registration-status'
                  : '/app/w/account/verification'
              }
              hasIcon={true}
              icon={icon}
              iconColor={iconColor}
            />
          </Collapse>
          {!hasSeenStarterTour ? (
            <StarterTour
              shouldStartTour={isNil(hasSeenStarterTour) && startTour}
              onComplete={() => {
                return updateUserTourMetaDataMutation.mutate(tourMetaData);
              }}
              onExit={() => {
                return updateUserTourMetaDataMutation.mutate(tourMetaData);
              }}
            />
          ) : null}
          {announcement.length > 0 && announcement[0]?.text !== 'hallowelt' ? (
            <Collapse in={true} animateOpacity>
              <Banner
                color={'white'}
                bgColor={'black.600'}
                hideActionButton={true}
                // @ts-ignore
                bannerText={announcement[0].text}
                hasIcon={true}
                icon={IoIosWarning}
                iconColor={'white'}
              />
            </Collapse>
          ) : null}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Container
              pt={{ base: '8', lg: '8' }}
              pb="16"
              maxW="container.xxl"
              centerContent={false}
              w={'full'}
              maxH={''}
              m={0}
            >
              <Stack spacing={{ base: '8', lg: '6' }}>
                <Outlet />
              </Stack>
            </Container>
          </motion.div>
        </Container>
      </Grid>
    </>
  );
};
