import { Button, Flex, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { get } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardChecklist } from 'react-icons/bs';
import { MdArrowDownward } from 'react-icons/md';

import { ServiceActivity } from '../../../../api/types';
import { Skeleton } from '../../../../components/Skeleton';
import BaseServiceTypeTimelineElement from '../../../../components/StandardTimeline/BaseServiceTypeTimelineElement';
import { useUserContext } from '../../../../context/UserContextProvider';
import { dateTimeTransformer } from '../../../../helpers/dateTransformer';
import { downloadFile } from '../../../../helpers/downloadHelper';
import getColourSchemeBasedOnStatus from '../../../../helpers/getColourSchemeBasedOnStatus';
import useFileQuery from '../../../../hooks/queries/useFileQuery';

export const InspectionPlanActivityCard = (props: {
  activity: ServiceActivity;
  isLoading: boolean;
  noActionsAllowed: boolean;
}) => {
  const { activity, isLoading, noActionsAllowed } = props;

  const inspectionPlan = activity?.inspection_plan;
  const { t } = useTranslation();
  const [url, setUrl] = useState<SetStateAction<any>>(undefined);
  const fileQuery = useFileQuery(url);
  const [downloadDocument, setDownloadDocument] =
    useState<SetStateAction<any>>(undefined);
  const [click, setClick] = useState(false);
  const userContext = useUserContext();
  const units = userContext.workshop?.units;

  useEffect(() => {
    setClick((prev) => {
      if (!prev) {
        downloadFile(downloadDocument, fileQuery.data);
      }
      return !prev;
    });

    setClick(false);
  }, [fileQuery.data, click]);

  const handleDownload = () => {
    setUrl(`/inspection-plan/${inspectionPlan?.id}/download`);
    setDownloadDocument(inspectionPlan.file);
    setClick(true);
  };

  return isLoading ? (
    <Skeleton height="200px" />
  ) : (
    <BaseServiceTypeTimelineElement
      id={`inspection_plan-${inspectionPlan.id}`}
      dataTestId={'inspection_plan_timeline_element'}
      date={dateTimeTransformer(activity?.date)}
      title={t('components:service_process_card.inspection_plan.label')}
      iconBgColor={getColourSchemeBasedOnStatus('completed')}
      icon={<Icon as={BsCardChecklist} />}
    >
      <Text fontSize="sm">
        <strong>{t('forms:mileage.label')}:</strong>{' '}
        {`${inspectionPlan?.mileage} ${units === 'metric' ? 'km' : 'mi'}`}
      </Text>

      <Text as="div" fontSize="sm">
        {t(`components:service_process_card.inspection_plan.timeline.message`)}
        <UnorderedList spacing={2} textAlign="left">
          {get(
            JSON.parse(inspectionPlan?.maintenance_data_strings),
            inspectionPlan?.maintenance_system_id,
            {
              periods: [],
            },
          )?.periods.map((period: any, index: number) => {
            return (
              <ListItem key={index}>
                <Text fontSize="sm">{period[Object.keys(period)[0]]?.name}</Text>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Text>
      <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }} gap={4}>
        {!noActionsAllowed && (
          <Button
            leftIcon={<MdArrowDownward />}
            variant="primary"
            size="sm"
            ml={2}
            data-test-id={`inspection_plan_${inspectionPlan.id}_timeline_download_button`}
            onClick={handleDownload}
          >
            {t('common:download')}
          </Button>
        )}
      </Flex>
    </BaseServiceTypeTimelineElement>
  );
};
