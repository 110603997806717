import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Distributor } from '../../../api/types';
import config from '../../../config';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export interface WorkshopPending {
  id: number;
  email_verified: boolean;
  code?: string;
  email: string;
  workshop_id?: string;
  registration_form_key: string | undefined;
  distributor: Distributor | undefined;
}
async function getWorkshopPending(
  id: number,
  registration_form_key: string | undefined,
): Promise<WorkshopPending> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/${id}/pending`, {
    params: {
      registration_form_key,
    },
  });
  return data;
}

export default function useWorkshopPendingQuery(
  id: number,
  registration_form_key: string | undefined,
) {
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(
    // getKey(id, registration_form_key),
    'WorkshopPending' as QueryKey,
    () => getWorkshopPending(id, registration_form_key),
    {
      enabled: !!id && !!registration_form_key,
      notifyOnChangeProps: ['data', 'isFetching', 'error'],
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error),
          hideRealResponse: true,
          t,
        });
      },
    },
  );
}

// function getKey(id: number, registration_form_key: string | undefined): QueryKey {
//   return ['WorkshopPending', id, registration_form_key];
// }
// useWorkshopPendingQuery.getKey = getKey;

useWorkshopPendingQuery.getKey = () => 'WorkshopPending';
