import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { PromotionBanner } from '../../components/PromotionBanner';
import useServiceRecordByStatusQuery from '../../hooks/queries/service/useServiceRecordByStatusQuery';
import usePromotionsQuery from '../../hooks/queries/usePromotionsQuery';
import IdentifiedVehiclesCard from './IdentifiedVehiclesCard';
import SrInClarificationCard from './SrInClarificationCard';
import VehicleIdentificationFormCard from './VehicleIdentificationFormCard';
import WelcomeCard from './WelcomeCard';
const Dashboard = () => {
  const promotionsQuery = usePromotionsQuery();
  const { t } = useTranslation();
  const serviceRecordByStatusQuery = useServiceRecordByStatusQuery(
    500,
    'submitted_at',
    'asc',
    10,
    1,
  );

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (promotionsQuery.isLoading || serviceRecordByStatusQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageMetatags title={t('workshop:dashboard.meta.title')} />

      {promotionsQuery.data?.map((promotion, index) => {
        if (promotion?.location?.includes('dashboard')) {
          return (
            <PromotionBanner
              key={index}
              promotion={promotion}
              url={'/app/w/account/subscription/plan'}
            />
          );
        }
        return null;
      })}

      <WelcomeCard />

      <VehicleIdentificationFormCard />

      {serviceRecordByStatusQuery.data?.data.length > 0 && <SrInClarificationCard />}

      <IdentifiedVehiclesCard />
    </>
  );
};

export default Dashboard;
