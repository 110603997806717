import { Button, Flex, Icon } from '@chakra-ui/react';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegAddressCard } from 'react-icons/fa';

import { useUserContext } from '../../context/UserContextProvider';
import { dateTimeTransformer } from '../../helpers/dateTransformer';
import { getStatus } from '../../helpers/general';
import useDeleteWorkshopUpdateRequestMutation from '../../hooks/private/mutations/useDeleteWorkshopUpdateRequestMutation';
import { ContentCard } from '../../layout/ContentCard';
import AccountUpdatePendingCard from './update/AccountUpdatePendingCard';
import WorkshopInformation from './WorkshopInformation';

const MyDataWorkShopInfoCard: React.FC = () => {
  const userContext = useUserContext();
  const { t } = useTranslation();
  const workshopUpdateRequestCodes =
    userContext.statusCodes?.workshop_update_request || {};
  const deleteWorkshopUpdateRequestMutation = useDeleteWorkshopUpdateRequestMutation(
    userContext.workshop?.update_requests[0]?.id,
  );

  return (
    <>
      {userContext.workshop?.pending_data_update_request && (
        <AccountUpdatePendingCard
          //@ts-ignore
          date={dateTimeTransformer(userContext.workshop?.update_requests[0]?.created_at)}
          cancel={() => {
            deleteWorkshopUpdateRequestMutation.mutate();
          }}
          state={
            userContext.workshop?.update_requests[0]?.status !==
            getStatus(workshopUpdateRequestCodes, 'open')
              ? 'processing'
              : undefined
          }
          showCancelBttn={userContext.workshop?.pending_data_update_request?.status === 0}
        />
      )}
      <ContentCard
        icon={<Icon as={FaRegAddressCard} boxSize={12} m={1} />}
        header={get(userContext.workshop, 'name', '')}
        data-test-id="workshop-detail-card"
        contentDescription={t('workshop:card.update.subtitle')}
      >
        <WorkshopInformation />
        {!userContext.workshop?.pending_data_update_request && (
          <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            <Button variant="primary" as={'a'} href={`/app/w/account/update`}>
              {t('workshop:card.update.action')}
            </Button>
          </Flex>
        )}
      </ContentCard>
    </>
  );
};

export default MyDataWorkShopInfoCard;
