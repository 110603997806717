import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import * as React from 'react';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import config from '../../../config';
import { pinInput } from '../../../helpers/makeFormFields';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import { WorkshopPending } from '../../../hooks/queries/workshop/useWorkshopPendingQuery';

export interface IEmailVerificationForm {
  code: string;
  registration_form_key: string | undefined;
}

const EmailVerificationStep = (props: {
  saveInState(x: any): void;
  data: Partial<WorkshopPending>;
  setStep(y: number): void;
  registrationKey?: string;
  userEmail: string;
}) => {
  const posthog = usePostHog();
  const { t } = useTranslation();
  const toast = useToast();
  const { saveInState, data, setStep, registrationKey, userEmail } = props;
  const abTestNewDesign =
    posthog.getFeatureFlag('signupForm2') === 'SignupFormLongNewDesign' ||
    posthog.getFeatureFlag('signupForm2') === 'SignupFormSplitNewDesign';

  const schema = yup
    .object({
      code: yup
        .string()
        .label(t('forms:code.label'))
        .required(t('forms:code.error'))
        .typeError(t('forms:code.error')),
      registration_form_key: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setError,
    watch,
  } = useForm<IEmailVerificationForm>({
    defaultValues: {
      registration_form_key: data.registration_form_key,
      code: data.code,
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const currentCode = watch('code');

  useEffect(() => {
    reset({
      registration_form_key: data.registration_form_key,
      code: data.code,
    });
  }, [data]);

  useEffect(() => {
    if (currentCode && !data?.email_verified) {
      handleSubmit(onSubmit)();
    }
  }, [currentCode]);

  const { mutate } = useMutation<any, Error, IEmailVerificationForm>(async (formData) => {
    if (formData?.code.length !== 4) {
      return Promise.reject({});
    }
    const response = await axios
      .put(
        `${config.apiBaseUrl}/workshop/${data?.id || data?.workshop_id}/verify-email`,
        { ...formData, code: Number(formData.code) },
      )
      .catch((err) => {
        const {
          data: { message },
        } = err.response;
        if (message && message === 'the email address already is verified') {
          setStep(2);
        } else {
          manageErrorResponse({ toastInstance: toast, error: err.response, t });
        }
        return Promise.reject(err);
      });

    return response;
  });

  const resendEmail = async () => {
    const key = data?.registration_form_key || registrationKey;
    if (!key) {
      return toast({
        title: t('workshop:registration.error.toast.title'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
    return await axios
      .put(
        `${config.apiBaseUrl}/workshop/${
          data?.id || data?.workshop_id
        }/resend-email-verification-code`,
        { registration_form_key: key },
      )
      .catch((err: { response: { status: number; data: any } }) => {
        const { errors = {} } = err.response.data;
        setError('code', {
          type: 'server',
          message: errors['code']!.join('. '),
        });
        return Promise.reject(err);
      })
      .then(() =>
        toast({
          title: t('workshop:registration.confirmation.toast.title'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        }),
      );
  };

  const onSubmit: SubmitHandler<IEmailVerificationForm> = (data) => {
    mutate(data, {
      onSuccess: () => {
        posthog.capture('set_password_pageview');
        saveInState({ ...data, email_verified: true });
        setStep(2);
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        (Object.keys(errors) as Array<keyof IEmailVerificationForm>).forEach((key) => {
          setError(key, {
            type: 'server',
            message: errors[key]!.join('. '),
          });
        });
      },
    });
  };
  const headlineWrapperWidth = useBreakpointValue({
    base: '100%',
    sm: '90%',
    md: '60%',
    lg: '60%',
    xl: '60%',
    '2xl': '70%',
  });
  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      data-test-id="email-verification-form"
      //@ts-ignore
      autocomplete="new-password"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position={'relative'}
    >
      <VStack width={'full'}>
        {abTestNewDesign && (
          <Box width={headlineWrapperWidth} justifyContent="flex-start">
            <Heading size="xs" mb={'5'}>
              {t('workshop:registration.stepper.two.email_verification')}
            </Heading>
            <Text color="muted" fontSize="sm" mb={'10'}>
              {t('workshop:registration.stepper.two.description')}
            </Text>
          </Box>
        )}
        <Grid
          minWidth={'320'}
          h="full"
          placeItems="center"
          paddingBlock={{ base: 4, sm: 4, md: 4, lg: 8 }}
          gap={8}
          textAlign={'center'}
        >
          <Stack spacing={2}>
            <Heading as="h3" size={{ base: 'xxs', lg: abTestNewDesign ? 'xs' : 'md' }}>
              {t('workshop:registration.confirmation.header')}
            </Heading>
            <Text fontSize="sm" p={2}>
              {t('workshop:registration.confirmation.description', {
                email: userEmail,
              })}
            </Text>
          </Stack>
          <Stack spacing="4">
            <Box padding={{ base: 4, sm: 4, md: 4, lg: 8 }}>
              {pinInput({
                name: 'code',
                register,
                errors,
                schema,
                size: 'xl',
                control,
                label: t('workshop:registration.confirmation.action'),
                borderColor: abTestNewDesign ? '#407cc4' : undefined,
                borderWidth: abTestNewDesign ? '1px' : undefined,
              })}
            </Box>
          </Stack>
          <Box padding={{ base: 4, sm: 4, md: 4, lg: 8 }}>
            <Text color="gray.500" fontSize={abTestNewDesign ? '14' : '16'}>
              {t('workshop:registration.confirmation.warning.one')}{' '}
            </Text>
            <Text color="gray.500" fontSize={abTestNewDesign ? '14' : '16'}>
              {t('workshop:registration.confirmation.warning.two')}
            </Text>
          </Box>
          <Button
            m={4}
            variant="outline"
            onClick={resendEmail}
            data-test-id="identification-scanning-button"
          >
            {t('workshop:registration.confirmation.resend')}{' '}
          </Button>
        </Grid>
      </VStack>
    </Box>
  );
};

export default EmailVerificationStep;
