import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Button, HStack, useColorModeValue, useToast } from '@chakra-ui/react';
import { useAxios } from 'context/AxiosContextProvider';
import { getTextStatusColor } from 'helpers/getColourSchemeBasedOnStatus';
import {
  assign,
  findKey,
  forEach,
  get,
  has,
  intersection,
  isObject,
  merge,
} from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosWarning } from 'react-icons/io';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Methods, STATUS, Vehicle } from '../../api/types';
import { Banner } from '../../components/Banner';
import { LoadingOverlay } from '../../components/LoadingSpinner';
import PageHeading from '../../components/PageHeading';
import config from '../../config';
import { useUserContext } from '../../context/UserContextProvider';
import { dateTransformerNoTimezone } from '../../helpers/dateTransformer';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
import { useStep } from '../../helpers/useStep';
import useServiceActivityQuery from '../../hooks/queries/service/useServiceActivityQuery';
import useFormSchemaQuery from '../../hooks/queries/useGetFormSchemaQuery';
import { BasicDataStep, BasicFormKeys } from './BasicDataStep';
import { MakeSpecificDataStep } from './MakeSpecificDataStep';
import { ReviewDataStep, ReviewFormKeys } from './ReviewDataStep';
import { Stepper } from './Stepper';
export interface IServiceRecordForm {
  date: string;
  mileage: number;
  registration_date: string;
  workshop_reference?: string;
  isUpdate?: boolean;
}

const ServiceRecordForm = (props: {
  initialValues: any;
  formVersionId: number;
  vehicle: Vehicle;
  title: string;
  serviceId?: string | number | null;
  navigateUrl: string;
  apiEndpointUrl: string;
  isUpdate?: boolean;
  isLoadingParent: boolean;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    title,
    formVersionId,
    vehicle,
    navigateUrl,
    apiEndpointUrl,
    isUpdate = false,
    initialValues: initialData,
    isLoadingParent,
  } = props;
  const userContext = useUserContext();
  const formSchemaQuery = useFormSchemaQuery(formVersionId);
  const formSchema = formSchemaQuery.data;
  const axios = useAxios();
  const manufacturerHasDsb = vehicle?.has_dsb;
  const srFastlaneSupported =
    vehicle?.features?.service_record_fastlane === 'available' ? true : false;
  const [currentStep, { setStep }] = useStep({ maxStep: 2, initialStep: 0 });
  const [lastSectionSeen, setLastSectionSeen] = useState(0);
  //if we do not cast it to a new object with a new variable name, the wizardFormData will not update when new data is passed form the parent
  const [stepOneFormData, setStepOneFormData] = useState({});
  const [stepTwoMakeCheckboxData, setStepTwoMakeCheckboxData] = useState({});
  const [additionalNotes, setAdditionalNotes] = useState('');
  const workshopWithoutVat = userContext.workshop?.vat_number === null;
  useEffect(() => {
    if (!isLoadingParent) {
      setStepOneFormData(initialData);
      setStepTwoMakeCheckboxData(get(initialData, 'data', {}));
    }
  }, [isLoadingParent]);

  const [errors, setError] = useState({});
  const sendRequest = async ({
    method = 'post',
    dataForEndpoint = {},
  }: {
    method?: Methods;
    dataForEndpoint?: object;
  }) => {
    return await axios[method](config.apiBaseUrl + apiEndpointUrl, dataForEndpoint).catch(
      (err: { response: { status: number } }) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
        return Promise.reject(err);
      },
    );
  };
  const isMazdaAndCountryDe =
    userContext.workshop?.country === 'DE' && vehicle?.manufacturer?.slug === 'mazda';

  const getRequestMethod = () => (isUpdate ? 'put' : 'post');
  const { mutate, isLoading } = useMutation<any, Error, IServiceRecordForm>(
    async (data) => {
      const dataForEndpoint: { data: any; [key: string]: any } = {
        ...stepOneFormData,
        ...data,
        dsb_form_version_id: vehicle?.form_version?.id,
        data: stepTwoMakeCheckboxData,
        //@ts-ignore
        date: dateTransformerNoTimezone(stepOneFormData?.date),
        //@ts-ignore
        registration_date: dateTransformerNoTimezone(stepOneFormData?.registration_date),
        submit: isUpdate,
      };

      let hasFiles = findKey(dataForEndpoint.data, 'file');
      if (hasFiles) {
        const formData = new FormData();
        // @ts-ignore
        //formData.append('dsb_form_version_id', vehicle?.form_version?.id);
        forEach(Object.keys(dataForEndpoint), (dataKey: string) => {
          const field = dataForEndpoint[dataKey];
          if (dataKey === 'data') {
            forEach(Object.keys(field), (makeSpecificFieldKey: string) => {
              const makeSpecificField = field[makeSpecificFieldKey];
              if (isObject(makeSpecificField) && has(makeSpecificField, 'file')) {
                formData.append(
                  `${dataKey}[${makeSpecificFieldKey}]`,
                  // @ts-ignore
                  makeSpecificField.file as File,
                );
              } else {
                formData.append(`${dataKey}[${makeSpecificFieldKey}]`, makeSpecificField);
              }
            });
          } else {
            let value = field;
            if (typeof value == 'object') {
              value = dateTransformerNoTimezone(value);
            }
            if (typeof value == 'boolean') {
              value = value ? 1 : 0;
            }
            formData.append(dataKey, value);
          }
        });
        return sendRequest({ method: getRequestMethod(), dataForEndpoint: formData });
      }

      return sendRequest({
        method: getRequestMethod(),
        dataForEndpoint: {
          ...dataForEndpoint,
          dsb_form_version_id: vehicle?.form_version?.id,
        },
      });
    },
  );
  const onSubmit: SubmitHandler<IServiceRecordForm> = (data) => {
    mutate(data, {
      onSuccess: ({ data }) => {
        const historyBackTimes = 50;
        const createdServiceEntryId = data?.id;
        queryClient.invalidateQueries(useServiceActivityQuery.getKey());
        for (let i = 0; i < historyBackTimes; i++) {
          history.pushState({}, '', '/app/w/vehicle?vin=' + data.vehicle.vin);
        }
        navigate(navigateUrl + data.vehicle.vin, {
          replace: true,
          state: { id: createdServiceEntryId },
        });
      },
      onError: (error: any) => {
        const { errors } = error.response.data;

        Object.keys(errors).forEach((key) => {
          let arrayKey = key;

          //alter the array key for step 2
          if (!BasicFormKeys.includes(key) && !ReviewFormKeys.includes(key)) {
            arrayKey = 'data.' + key;
          }

          setError({
            ...errors,
            [arrayKey]: {
              type: 'server',
              message: errors[key]!.join('. '),
            },
          });
        });
        //check error in first step
        const overlapStep1 = intersection(Object.keys(errors), BasicFormKeys);
        //check error in last step
        const overlapStep3 = intersection(Object.keys(errors), ReviewFormKeys);

        //if first step
        if (overlapStep1.length > 0) {
          setStep(0);
          return;
        }
        //if not in last step
        if (overlapStep3.length === 0) {
          setStep(1);
        }
      },
    });
  };
  const steps = [
    {
      title: 1,
      stepHeader: t('pages:service_record.form.stepper.one.header'),
      stepDescription: t('pages:service_record.form.stepper.one.description'),
      content: (
        <BasicDataStep
          vin={vehicle?.vin}
          srFastlaneAvailable={srFastlaneSupported}
          saveInState={(stateData) =>
            setStepOneFormData(merge(stepOneFormData, stateData))
          }
          backendErrors={errors}
          formType={initialData?.form}
          stepOneFormData={stepOneFormData}
          setStep={setStep}
          isUpdate={isUpdate}
        />
      ),
    },
    {
      title: 2,
      stepHeader: t('pages:service_record.form.stepper.two.header'),
      stepDescription: t('pages:service_record.form.stepper.two.description'),
      content: (
        <MakeSpecificDataStep
          saveInState={(stateData) => {
            const updatedData = assign(get(stepOneFormData, 'fields', {}), stateData);
            const updatedDataWithoutUndefinedValues = {} as { [key: string]: any };
            Object.keys(updatedData).filter((key: string) => {
              if (updatedData[key] !== undefined) {
                updatedDataWithoutUndefinedValues[key] = updatedData[key];
              }
            });
            setStepTwoMakeCheckboxData(updatedDataWithoutUndefinedValues);
          }}
          vehicle={vehicle}
          lastSectionSeen={lastSectionSeen}
          setLastSectionSeen={setLastSectionSeen}
          backendErrors={errors}
          formSchema={formSchema}
          stepTwoMakeCheckboxData={stepTwoMakeCheckboxData}
          setStep={setStep}
        />
      ),
    },
    {
      title: 3,
      stepHeader: t('pages:service_record.form.stepper.three.header'),
      stepDescription: t('pages:service_record.form.stepper.three.description'),
      content: (
        <ReviewDataStep
          additionalNotes={additionalNotes}
          setAdditionalNotes={setAdditionalNotes}
          setStepOneFormData={setStepOneFormData}
          //new
          stepOneFormData={stepOneFormData}
          stepTwoMakeCheckboxData={stepTwoMakeCheckboxData}
          setStep={setStep}
          onSubmit={onSubmit}
          formSchema={formSchema}
        />
      ),
    },
  ];

  const boxShadow = useColorModeValue('lg', 'lg-dark');
  return (
    <>
      <HStack justify="space-between">
        <PageHeading title={title} isLoading={isLoadingParent} />
        <Button
          p={2}
          onClick={() => navigate(`/app/w/vehicle?vin=${vehicle?.vin}`)}
          variant={'outline'}
          data-test-id="service-record-form-back-button"
          flexShrink={0}
        >
          {t('pages:service_record.detail.back')}
        </Button>
      </HStack>

      <Stepper
        isLoading={isLoadingParent}
        steps={steps}
        currentStep={currentStep}
        warning={
          (!manufacturerHasDsb &&
            !vehicle?.manufacturer?.make?.portal &&
            !isLoadingParent) ||
          (isMazdaAndCountryDe && !isLoadingParent) ? (
            <Banner
              mb={isMazdaAndCountryDe ? 10 : 0}
              bgColor={'blue.700'}
              color={getTextStatusColor(STATUS.CLARIFICATION)}
              hideActionButton={true}
              hasI18nHeadline={true}
              bannerTextHeadline={
                isMazdaAndCountryDe
                  ? 'service_record:not_supported.mazda'
                  : workshopWithoutVat
                    ? 'workshop:registration.without_vat.service_process_card.modal.has_oeplus_dsb_addon.body'
                    : 'forms:sr.idsb.warning'
              }
              hasIcon={true}
              icon={isMazdaAndCountryDe ? IoIosWarning : InfoOutlineIcon}
              iconColor={'white'}
              boxShadow={boxShadow}
              borderRadius="lg"
            />
          ) : null
        }
        content={steps[currentStep].content}
      />
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default ServiceRecordForm;
