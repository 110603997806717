import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import GetInTouchCard from 'components/GetInTouchCard';
import { isAfter, isBefore } from 'date-fns';
import useChargeBee from 'hooks/queries/useChargeBee';
import PageMetatags from 'layout/PageMetatags';
import { PublicPageLayout } from 'layout/PublicPageLayout';
import { delay } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { getI18n } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/all';
import * as yup from 'yup';

import { BILLING_CYCLE } from '../../../../api/types';
import { useUserContext } from '../../../../context/UserContextProvider';
import { ContentCard } from '../../../../layout/ContentCard';
import { AccountUpdateableUserData } from '../../../profile/update/AccountUpdateForm';
const schema = yup
  .object({
    plan_id: yup.number().required(),
    coupon_id: yup.string().nullable(),
    billing_cycle: yup.string().required(),
  })
  .required();

export const PrepaidWorkshopWelcomePage = () => {
  const userContext = useUserContext();
  const { t } = useTranslation();
  const { triggerPayment } = useChargeBee();
  const onSubmit: SubmitHandler<AccountUpdateableUserData> = (data) => {
    setDisableClick(true);
    triggerPayment(data);
  };

  const distributorName = userContext.workshop?.distributor?.name;
  const prepaidPlanId = userContext.workshop?.distributor?.prepaid_plan_id;
  const {
    handleSubmit,
    formState: {},
  } = useForm<AccountUpdateableUserData>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      billing_cycle: BILLING_CYCLE.MONTHLY,
      plan_id: prepaidPlanId,
    },
  });
  const [disableClick, setDisableClick] = useState(false);

  useEffect(() => {
    if (disableClick) {
      delay(() => {
        setDisableClick(false);
      }, 3000);
    }
  }, [disableClick]);

  const monthlySrQuota =
    userContext.workshop?.distributor?.additional_monthly_sr_quota?.additional;

  const monthlyShrQuota =
    userContext.workshop?.distributor?.additional_monthly_shr_quota?.additional;

  const freeSrQuotaPeriod =
    isBefore(
      new Date(),
      new Date(
        userContext.workshop?.distributor?.additional_monthly_sr_quota?.end as string,
      ),
    ) &&
    isAfter(
      new Date(),
      new Date(
        userContext.workshop?.distributor?.additional_monthly_sr_quota?.start as string,
      ),
    );

  const freeShrQuotaPeriod =
    isBefore(
      new Date(),
      new Date(
        userContext.workshop?.distributor?.additional_monthly_shr_quota?.end as string,
      ),
    ) &&
    isAfter(
      new Date(),
      new Date(
        userContext.workshop?.distributor?.additional_monthly_shr_quota?.start as string,
      ),
    );

  return (
    <PublicPageLayout
      //@ts-ignore
      distributor={userContext.workshop?.distributor}
      showCobrandedLogo={userContext.workshop?.distributor?.prepaid_plan !== null}
    >
      <PageMetatags title={t('pages:subscription.meta.title')}>
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </PageMetatags>
      <Container
        as={'form'}
        onSubmit={handleSubmit(onSubmit)}
        data-test-id="subscription-plan-card"
      >
        <Grid gap={{ base: 6, lg: 10 }}>
          <ContentCard>
            <Heading size="xs" style={{ textAlign: 'center' }} mb={10}>
              {t('workshop:welcome.header')}
            </Heading>
            <VStack spacing={6} alignItems={'start'} data-test-id="subscription-summary">
              <Trans
                i18nKey={`pages:pre_paid_welcome_page.top_sentence`}
                values={{ distributor: distributorName }}
              />

              <List>
                <ListItem>
                  <Stack
                    mt={5}
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-start'}
                    textAlign={'left'}
                    flex={0}
                    paddingBlock={3}
                    alignItems={'flex-start'}
                  >
                    <VStack alignItems="flex-start">
                      <Box>
                        {monthlySrQuota && freeSrQuotaPeriod && (
                          <HStack justifyContent="center">
                            <ListIcon
                              mt={1}
                              as={MdCheckCircle}
                              boxSize={6}
                              color={'accent'}
                            />
                            <Stack spacing={1}>
                              <Text>
                                {getI18n().t(
                                  `pages:pre_paid_welcome_page.bullet_points.service_record.bullet_point`,
                                  { count: monthlySrQuota },
                                )}
                              </Text>
                            </Stack>
                          </HStack>
                        )}
                      </Box>
                      {monthlyShrQuota && freeShrQuotaPeriod && (
                        <HStack justifyContent="center">
                          <ListIcon
                            mt={1}
                            as={MdCheckCircle}
                            boxSize={6}
                            color={'accent'}
                          />
                          <Stack spacing={1}>
                            <Text>
                              {getI18n().t(
                                `pages:pre_paid_welcome_page.bullet_points.service_history_request.bullet_point`,
                                { count: monthlySrQuota },
                              )}
                            </Text>
                          </Stack>
                        </HStack>
                      )}
                      <HStack justifyContent="center">
                        <ListIcon
                          mt={-1}
                          as={MdCheckCircle}
                          boxSize={6}
                          color={'accent'}
                        />
                        <Stack spacing={1}>
                          <Text>
                            {t('pages:pre_paid_welcome_page.bullet_points.setup_fee')}
                          </Text>
                        </Stack>
                      </HStack>
                    </VStack>
                  </Stack>
                </ListItem>
              </List>

              <VStack spacing={10}>
                <Text>{t('pages:pre_paid_welcome_page.bottom_sentence')}</Text>

                <Box alignSelf={'self-start'}></Box>
              </VStack>
            </VStack>
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button variant="primary" size={'lg'} type="submit">
                {t('pages:subscription.submit')}
              </Button>
            </Box>
          </ContentCard>
          <GetInTouchCard />
        </Grid>
      </Container>
    </PublicPageLayout>
  );
};
export default PrepaidWorkshopWelcomePage;
