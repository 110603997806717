import { Grid } from '@chakra-ui/react';
import PageMetatags from 'layout/PageMetatags';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PollingCard from '../../polling/PollingCard';

export const UpdateSubscriptionPollingPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const originalUpdatedAt = urlParams.get('original_updated_at');

  return (
    <>
      <PageMetatags title={t('pages:polling.meta.title')} />
      <Grid w="full" h="full" placeItems="center">
        <PollingCard
          noCard={true}
          canGoBack={true}
          success={() => {
            navigate('/app/w/account/subscription');
          }}
          pollingCondition={(data) => {
            return data?.subscription?.updated_at !== originalUpdatedAt;
          }}
        />
      </Grid>
    </>
  );
};

export default UpdateSubscriptionPollingPage;
