import './VerticalTimeline.css';
import './custom.css';

import { useToken } from '@chakra-ui/react';
import * as React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineProps,
} from 'react-vertical-timeline-component';

export interface TimelineProps {
  children?: React.ReactNode;
  lineColor?: string;
  layout?: VerticalTimelineProps['layout'];
}

export const StandardTimeline: React.FC<TimelineProps> = ({
  lineColor,
  layout = '1-column-left',
  children,
}) => {
  return (
    <section className="vertical-timeline__wrapper">
      <VerticalTimeline
        lineColor={lineColor || useToken('colors', 'gray.300')}
        layout={layout}
      >
        {children}
      </VerticalTimeline>
    </section>
  );
};

export default StandardTimeline;
