import { Center, Circle, Icon, SquareProps, Text } from '@chakra-ui/react';
import { FONT_WEIGHTS, FONTS } from 'global/Fonts';
import React from 'react';
import { HiCheck } from 'react-icons/hi';

import { stepperColors } from './Step';

interface RadioCircleProps extends SquareProps {
  isCompleted: boolean;
  isActive: boolean;
  caption: string | number;
  abDesignTest?: boolean;
}

export const StepCircle = (props: RadioCircleProps) => {
  const { isCompleted, isActive, caption = 1, abDesignTest, ...otherProps } = props;
  return (
    <Circle
      size="8"
      bg={
        isCompleted
          ? stepperColors.activeText
          : isActive
            ? stepperColors.active
            : abDesignTest
              ? stepperColors.abTestInactive
              : stepperColors.inactive
      }
      borderWidth="1px"
      borderColor={
        isCompleted
          ? stepperColors.active
          : isActive
            ? stepperColors.active
            : abDesignTest
              ? stepperColors.abTestInactive
              : stepperColors.inactive
      }
      {...otherProps}
    >
      {isCompleted ? (
        <Icon as={HiCheck} color={stepperColors.active} boxSize="5" />
      ) : (
        <Center
          boxSize="full"
          color={isActive ? stepperColors.activeText : stepperColors.inactiveText}
          fontFamily={FONTS.soehneBreit}
        >
          <Text w={'full'} textAlign={'center'} fontWeight={FONT_WEIGHTS.bold}>
            {caption}
          </Text>
        </Center>
      )}
    </Circle>
  );
};
