import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import config from 'config';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function update(axios: AxiosInstance, serviceId: number): Promise<object> {
  const { data } = await axios.put(`${config.apiBaseUrl}/service/${serviceId}/complete`);
  return data;
}

export default function useCompleteServiceMutation(serviceId: number) {
  const axios = useAxios();
  const toast = useToast();
  const t = useTranslation();

  return useMutation(() => update(axios, serviceId), {
    onError: (err: any) => {
      manageErrorResponse({ toastInstance: toast, error: err.response, t });
    },
  });
}
