import { Box, BoxProps } from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import cuid from 'cuid';
import * as React from 'react';
import { useState } from 'react';

import { ServiceActivity, StatusCodes, SubscriptionAddon } from '../../../../api/types';
import { StandardTimeline } from '../../../../components/StandardTimeline';
import { WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';
import { getQRSection } from '../../../serviceRecord/ServiceRecordDetailsPage';
import { InspectionPlanActivityCard } from './InspectionPlanActivityCard';
import { SendVehicleDocumentsActivityCard } from './SendVehicleDocumentsActivityCard';
import { ServiceHistoryRequestActivityCard } from './ServiceHistoryRequestActivityCard';
import { ServiceRecordActivityCard } from './ServiceRecordActivityCard';

export interface IServiceActivityTimeLineProps extends BoxProps {
  vin: string;
  serviceActivities: Array<ServiceActivity>;
  noActionsAllowed: boolean;
  statusCodes: StatusCodes;
  addons: SubscriptionAddon[];
  isLoading: boolean;
  sendRequest(x: any): Promise<any>;
  workshop: WorkshopData | undefined;
  t: any;
}

export const ServiceActivityTimeline = (props: IServiceActivityTimeLineProps) => {
  const {
    vin,
    serviceActivities,
    statusCodes,
    isLoading,
    addons,
    sendRequest,
    noActionsAllowed,
    workshop,
    t,
    ...boxProps
  } = props;
  const [displayError, setDisplayError] = useState(false);
  return (
    <Box {...boxProps}>
      {isLoading ? (
        <Skeleton height="200px" />
      ) : (
        Object.keys(serviceActivities).length > 0 && (
          <StandardTimeline>
            {Object.values(serviceActivities).map((serviceActivity: ServiceActivity) => {
              switch (serviceActivity?.type) {
                case 'service_record':
                  return (
                    <ServiceRecordActivityCard
                      vin={vin}
                      key={`ServiceRecordActivityCard-${cuid()}`}
                      activity={serviceActivity}
                      noActionsAllowed={noActionsAllowed}
                      statusCodes={statusCodes}
                      workshop={workshop}
                      qrContent={getQRSection(
                        displayError,
                        serviceActivity?.service_record,
                        // @ts-ignore
                        workshop,
                        addons,
                        (data) =>
                          sendRequest({
                            method: 'put',
                            url: `service-record/${serviceActivity?.service_record?.id}/associate-qr-identifier`,
                            dataForEndpoint: data,
                          }).catch((err) => {
                            const { data = {} } =
                              typeof err == 'string' ? JSON.parse(err) : err;
                            const responseFromQrCodeSubmission = data?.message;
                            if (
                              responseFromQrCodeSubmission ===
                              'qr code identifier ist bereits vergeben.'
                            ) {
                              setDisplayError(true);
                              return 'qrCodeAlreadyUsed';
                            }

                            /*       if (
                              responseFromQrCodeSubmission !==
                              'qr code identifier ist bereits vergeben.'
                            ) {
                              return toast({
                                title: responseFromQrCodeSubmission || message,
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                                position: 'top',
                              });
                            }*/
                          }),
                        t,
                      )}
                      isLoading={isLoading}
                    />
                  );
                case 'service_history_request':
                  return (
                    <ServiceHistoryRequestActivityCard
                      key={`ServiceHistoryRequestActivityCard-${cuid()}`}
                      activity={serviceActivity}
                      noActionsAllowed={noActionsAllowed}
                      statusCodes={statusCodes}
                      workshop={workshop}
                      isLoading={isLoading}
                      vin={vin}
                    />
                  );
                case 'sent_documents':
                  return (
                    <SendVehicleDocumentsActivityCard
                      key={`SendVehicleDocumentsActivityCard-${cuid()}`}
                      isLoading={isLoading}
                      activity={serviceActivity}
                    />
                  );
                case 'inspection_plan':
                  return (
                    <InspectionPlanActivityCard
                      key={`InspectionPlanActivityCard-${cuid()}`}
                      activity={serviceActivity}
                      isLoading={isLoading}
                      noActionsAllowed={noActionsAllowed}
                    />
                  );
                default:
                  return <></>;
              }
            })}
          </StandardTimeline>
        )
      )}
    </Box>
  );
};
