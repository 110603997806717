import NiceModal from '@ebay/nice-modal-react';
import { setUser } from '@sentry/browser';
import { setTags } from '@sentry/react';
import SubscriptionSummaryPage from 'features/account-setup/subscription/create/SubscriptionSummaryPage';
import i18n from 'i18n-config';
import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { LoadingSpinner } from '../components/LoadingSpinner';
import NavigateTo404 from '../components/NavigateTo404';
import { AxiosContextProvider } from '../context/AxiosContextProvider';
import {
  UserContextProvider,
  useUserContext,
  WORKSHOP_ACCOUNT_SETUP,
  WORKSHOP_ACTIVE,
  WORKSHOP_DEACTIVATED,
  WORKSHOP_OLD_EXISTING_CUSTOMER,
  WORKSHOP_PREREGISTERED,
  WORKSHOP_REGISTERED,
} from '../context/UserContextProvider';
import { isTestingEnvironment } from '../helpers/environments';
import ErrorPage from '../layout/errors/ErrorPage';
import WarningPage from '../layout/errors/WarningPage';
import { LogoutPage } from '../layout/LogoutPage';
import { MainLayout } from '../layout/MainLayout';
import WelcomeExistingCustomerPage from './../features/account-setup/existing-customer/WelcomeExistingCustomerPage';
import CreateSubscriptionPollingPage from './../features/account-setup/polling/CreateSubscriptionPollingPage';
import AccountVerificationPage from './../features/account-setup/verification/AccountVerificationPage';
import Dashboard from './../features/dashboard/Dashboard';
import InspectionPlanPage from './../features/inspectionPlan/InspectionPlanPage';
import MyData from './../features/profile/MyData';
import ActivitiesOverview from './../features/serviceActivities/ActivitiesOverview';
import ServiceHistoryPage from './../features/serviceHistory/ServiceHistoryPage';
import CreateServiceRecordPage from './../features/serviceRecord/CreateServiceRecordPage';
import ServiceRecordDetailsPage from './../features/serviceRecord/ServiceRecordDetailsPage';
import UpdateServiceRecordPage from './../features/serviceRecord/UpdateServiceRecordPage';
import VehicleIdentificationPage from './../features/vehicle/VehicleIdentificationPage';
import WelcomeDeactivatedCustomerPage from './account-setup/deactivated-customer/WelcomeDeactivatedCustomerPage';
import OnboardingPage from './account-setup/registration/OnboardingPage';
import CompletedPage from './account-setup/registration/RegistrationCompletedPage';
import CostOverviewPage from './account-setup/subscription/costs/CostOverviewPage';
import GetAddonsPage from './account-setup/subscription/create/GetAddonsPage';
import PrepaidWorkshopWelcomePage from './account-setup/subscription/create/PrepaidWorkshopWelcomePage';
import SelectSubscriptionPlanPage from './account-setup/subscription/create/SelectSubscriptionPlanPage';
import ChangePlanPage from './account-setup/subscription/update/ChangePlanPage';
import ManageSubscriptionPage from './account-setup/subscription/update/ManageSubscriptionPage';
import UpdateSubscriptionPollingPage from './account-setup/subscription/update/UpdateSubscriptionPollingPage';
import VerificationPage from './account-setup/verification/VerificationPage';
import HelpSupportPage from './HelpSupportPage';
import FallbackErrorPage from './profile/FallbackErrorPage';
import AccountUpdateDataPage from './profile/update/AccountUpdateDataPage';
import AccountRegistrationStatusPage from './serviceRecord/AccountRegistrationStatusPage';

const getElement = (
  isLoaded: boolean,
  condition: boolean,
  element: any,
  fallback = '/app',
) => {
  if (isLoaded) return <LoadingSpinner />;
  else if (condition) return element;
  else return <Navigate to={fallback} />;
};

function AuthenticatedRoutes() {
  const userContext = useUserContext();
  const { t } = useTranslation();

  const workshopLanguageLowerCase = userContext.workshop?.language.toLowerCase();

  useEffect(() => {
    if (!isTestingEnvironment() || userContext.user?.type !== 'support') {
      i18n.changeLanguage(workshopLanguageLowerCase);
    }
  }, [workshopLanguageLowerCase]);

  const lockedWorkshop = userContext.workshop?.locked;
  if (lockedWorkshop) {
    return <WelcomeDeactivatedCustomerPage />;
  }

  if (userContext.error) {
    return (
      <ErrorPage
        error={"couldn't fetch workshop - " + userContext.error}
        errorContext={{
          extra: { workshop: userContext.workshop, error: userContext.error },
        }}
      />
    );
  }

  const workshopLanguageUpperCase = userContext.workshop?.language?.toUpperCase();
  // Set Sentry user Data
  setUser({ id: `${userContext.workshop?.id}`, email: userContext.workshop?.email });
  setTags({
    locale: workshopLanguageUpperCase,
    country: userContext.workshop?.country,
  });

  let entryPoint;
  switch (userContext.workshop?.status) {
    case WORKSHOP_PREREGISTERED:
      entryPoint = (
        <Navigate
          to={`/signup/${userContext.workshop?.id}/${userContext.workshop?.registration_form_key}`}
        />
      );
      break;
    case WORKSHOP_OLD_EXISTING_CUSTOMER:
      entryPoint = <Navigate to="/app/welcome" />;
      break;
    case WORKSHOP_ACCOUNT_SETUP:
      if (
        userContext.workshop?.distributor?.prepaid_plan &&
        isEmpty(userContext.workshop?.subscription || {})
      ) {
        entryPoint = <Navigate to="/app/setup/pre-paid/welcome" />;
      } else if (isEmpty(userContext.workshop?.subscription || {})) {
        entryPoint = <Navigate to="/app/setup/subscription" />;
      } else {
        entryPoint = (
          <Navigate
            to="/app/warning"
            state={{
              message: t('pages:support.needed.message'),
              title: t('pages:support.needed.title'),
              redirectUrl: `/app/support`,
              buttonText: t('pages:support.contact.label'),
            }}
          />
        );
      }
      break;
    case WORKSHOP_REGISTERED:
      entryPoint = <Navigate to="/app/setup/subscription" />;
      break;
    case WORKSHOP_ACTIVE:
      entryPoint = <Navigate to="/app/w" />;
      break;
    case WORKSHOP_DEACTIVATED:
      entryPoint = <Navigate to="/app/welcome/deactivated" />;
      break;
    default:
      entryPoint = (
        <ErrorPage
          error={'unhandled workshop status - ' + userContext.workshop?.status}
          errorContext={{ extra: { workshop: userContext.workshop } }}
        />
      );
      break;
  }
  return (
    <Routes>
      <Route path="/" element={entryPoint} />
      <Route index element={entryPoint} />
      <Route path="support" element={<HelpSupportPage />} />
      <Route path="warning" element={<WarningPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route
        path="welcome/deactivated"
        element={getElement(
          isEmpty(userContext.workshop),
          userContext.workshop?.status === WORKSHOP_DEACTIVATED,
          <WelcomeDeactivatedCustomerPage />,
        )}
      />
      <Route
        path="welcome"
        element={getElement(
          isEmpty(userContext.workshop),
          userContext.workshop?.status === WORKSHOP_OLD_EXISTING_CUSTOMER,
          <WelcomeExistingCustomerPage />,
        )}
      />
      <Route
        path="setup/subscription"
        element={getElement(
          isEmpty(userContext.workshop),
          isEmpty(userContext.workshop?.subscription || {}) ||
            userContext.workshop?.status === WORKSHOP_DEACTIVATED,
          <SelectSubscriptionPlanPage />,
        )}
      />
      <Route
        path="setup/subscription-summary/:planId/:billingCycle"
        element={getElement(
          isEmpty(userContext.workshop),
          isEmpty(userContext.workshop?.subscription || {}) ||
            userContext.workshop?.status === WORKSHOP_DEACTIVATED,
          <SubscriptionSummaryPage />,
        )}
      />
      <Route
        path="setup/pre-paid/welcome"
        element={getElement(
          isEmpty(userContext.workshop),
          !!(
            userContext.workshop?.distributor?.prepaid_plan_id &&
            isEmpty(userContext.workshop?.subscription || {})
          ),
          <PrepaidWorkshopWelcomePage />,
        )}
      />
      <Route
        path="setup/polling"
        element={getElement(
          isEmpty(userContext.workshop),
          userContext.workshop?.status !== WORKSHOP_ACTIVE,
          <CreateSubscriptionPollingPage />,
          '/app/setup/completed',
        )}
      />
      <Route path="setup/onboarding" element={<OnboardingPage />} />
      <Route path="setup/completed" element={<CompletedPage />} />
      <Route
        path="setup/addons"
        element={getElement(
          isEmpty(userContext.workshop),
          !get(userContext.workshop?.subscription, 'plan')?.is_pay_per_use,
          <GetAddonsPage />,
          '/app/setup/verification',
        )}
      />
      <Route
        path="setup/verification"
        element={getElement(
          isEmpty(userContext.workshop),
          !userContext.workshop?.is_verification_pending &&
            !userContext.workshop?.is_verified,
          <VerificationPage />,
          '/app/w/dashboard',
        )}
      />
      <Route
        path="w"
        element={getElement(
          isEmpty(userContext.workshop),
          userContext.workshop?.status === WORKSHOP_ACTIVE,
          <MainLayout />,
        )}
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={getElement(
            isEmpty(userContext.workshop),
            userContext.workshop?.status === WORKSHOP_ACTIVE,
            <Dashboard />,
          )}
        />
        <Route path="service-activities" element={<ActivitiesOverview />} />

        <Route path="error" element={<FallbackErrorPage />} />
        <Route
          path="account/verification"
          element={getElement(
            isEmpty(userContext.workshop),
            !userContext.workshop?.is_verified,
            <AccountVerificationPage />,
            '/app/w/dashboard',
          )}
        />
        <Route
          path="account/update"
          element={getElement(
            isEmpty(userContext.workshop),
            // @ts-ignores
            userContext.workshop?.pending_data_update_request === null,
            <AccountUpdateDataPage />,
            '/app/w/account/data',
          )}
        />
        <Route path="account/subscription" element={<ManageSubscriptionPage />} />
        <Route path="account/subscription/plan" element={<ChangePlanPage />} />
        <Route
          path="account/subscription/polling"
          element={<UpdateSubscriptionPollingPage />}
        />
        <Route path="account/subscription/cost-overview" element={<CostOverviewPage />} />
        <Route
          path="account-registration-status"
          element={<AccountRegistrationStatusPage />}
        />
        <Route path="vehicle" element={<VehicleIdentificationPage />} />
        <Route path="service-record/create" element={<CreateServiceRecordPage />} />
        <Route path="service-record/:id/update" element={<UpdateServiceRecordPage />} />
        <Route path="service-record/:id" element={<ServiceRecordDetailsPage />} />
        <Route path="request-service-record-history" element={<ServiceHistoryPage />} />
        <Route
          path="request-service-record-history/:id"
          element={<ServiceHistoryPage />}
        />
        <Route path="request-inspection-plan" element={<InspectionPlanPage />} />
        <Route path="service-activities" element={<ActivitiesOverview />} />
        <Route path="support" element={<HelpSupportPage />} />
        <Route path="warning" element={<WarningPage />} />
        <Route path="account/data" element={<MyData />} />
      </Route>
      <Route path="*" element={<NavigateTo404 />} />
    </Routes>
  );
}

export default function AuthenticatedRouter() {
  return (
    <AxiosContextProvider>
      <UserContextProvider>
        <NiceModal.Provider>
          <AuthenticatedRoutes />
        </NiceModal.Provider>
      </UserContextProvider>
    </AxiosContextProvider>
  );
}
