import { setUser } from '@sentry/browser';
import React from 'react';
import { useQueryClient } from 'react-query';

import { Config, StatusCodes, User } from '../api/types';
import { LoadingSpinner } from '../components/LoadingSpinner';
import useConfigQuery from '../hooks/queries/useConfigQuery';
import useStatusQuery from '../hooks/queries/useStatusQuery';
import useTokenQuery from '../hooks/queries/useTokenQuery';
import useWorkshopQuery, {
  Subscription,
  WorkshopData,
} from '../hooks/queries/workshop/useWorkshopQuery';
import useWorkshopUserQuery from '../hooks/queries/workshop/useWorkshopUserQuery';
import ErrorPage from '../layout/errors/ErrorPage';

export interface UserContextInterface {
  login: () => void;
  logout: () => Promise<void>;
  user: User;
  workshop: WorkshopData;
  subscription: Subscription;
  config: Config;
  statusCodes: StatusCodes;
  error?: Error | null;
}

const UserContext = React.createContext<UserContextInterface>({
  user: {},
  workshop: {},
  subscription: {},
  statusCodes: {},
  config: {},
  error: null,
} as UserContextInterface);

export const WORKSHOP_PREREGISTERED = 0;
export const WORKSHOP_OLD_EXISTING_CUSTOMER = 50;
export const WORKSHOP_REGISTERED = 100;
export const WORKSHOP_ACCOUNT_SETUP = 200;
export const WORKSHOP_ACTIVE = 300;
export const WORKSHOP_DEACTIVATED = 900;
export const STATUS_CREATED = 0;
export const STATUS_COMPLETED = 700;
export const STATUS_NEEDS_CLARIFICATION = 500;
export const STATUS_REJECTED = 900;

export const enum WORKSHOP_STATUS_NUMBERS {
  WORKSHOP_PREREGISTERED = 0,
  WORKSHOP_OLD_EXISTING_CUSTOMER = 50,
  WORKSHOP_REGISTERED = 100,
  WORKSHOP_ACCOUNT_SETUP = 200,
  WORKSHOP_ACTIVE = 300,
  WORKSHOP_DEACTIVATED = 900,
}
const UserContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const queryClient = useQueryClient();
  const userQuery = useWorkshopUserQuery();
  const workshopQuery = useWorkshopQuery();
  const configQuery = useConfigQuery();
  const statusQuery = useStatusQuery();

  const tokenQuery = useTokenQuery();

  const login = () => {
    queryClient.invalidateQueries(useWorkshopQuery.getKey());
    queryClient.invalidateQueries(useConfigQuery.getKey());
    queryClient.invalidateQueries(useTokenQuery.getKey());
  };

  const logout = async () => {
    queryClient.setQueryData(useWorkshopQuery.getKey(), null);
    queryClient.setQueryData(useTokenQuery.getKey(), null);
    queryClient.setQueryData(useConfigQuery.getKey(), null);
    setUser(null);
  };

  if (
    tokenQuery.isLoading ||
    userQuery.isLoading ||
    workshopQuery.isLoading ||
    configQuery.isLoading ||
    statusQuery.isLoading
  ) {
    return <LoadingSpinner />;
  } else if (
    userQuery.isError ||
    workshopQuery.isError ||
    configQuery.isError ||
    statusQuery.isError
  ) {
    return <ErrorPage error={'Timeout while authenticating user'} />;
  }

  let value: UserContextInterface = {
    login,
    logout,
    user: userQuery.data as User,
    workshop: workshopQuery.data as WorkshopData,
    error: workshopQuery.error as unknown as Error,
    subscription: workshopQuery.data?.subscription as Subscription,
    statusCodes: statusQuery.data as StatusCodes,
    config: configQuery.data as Config,
  };

  return <UserContext.Provider value={value} {...props} />;
};

const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('Trying to access userContext outside of UserContextProvider');
  }
  return context;
};

export { UserContextProvider, useUserContext };
